import { extendObservable, observable, action } from "mobx";
import { v4 as uuid } from "uuid";

import { lineLength } from "../../core/geometryUtils";

/**
 * Represents a pipe object
 * @param {*} param0 - pipe json
 * @param {*} settingsState - state that contain a labels for UI
 * @returns pipe object
 * contains
 * id - pipe identifier
 * start - start pipe point identifier
 * end - end pipe point idetifier
 * disabled
 */
const pipeFactory = (
  { id, type = "pipe", start, end, disabled = false, color },
  settingsState
) => {
  //inject object variables
  const line = observable({
    id: id || "pipe-" + uuid(),
    start,
    end,
    type,
    disabled,
  });

  // getters
  extendObservable(line, {
    get isValid() {
      return this.startPoint != null && this.endPoint != null;
    },
    get isStartLine() {
      return line.isValid
        ? (this.startPoint && this.startPoint.isStartPoint) ||
            (this.endPoint && this.endPoint.isStartPoint)
        : undefined;
    },
    get color() {
      return line.isStartLine && color ? color : undefined;
    },
    /**
     *  pipe is selectable for new pipe when
     * 1. is not disabled.
     * 2. selected point differs from the start and end points of current pipe
     * 3. does not violate the rule
     * "pipeline with dripline connection point cannot be connected to a pipeline with a sprinkler"
     * "tubing cannot be connected to a pipeline with a sprinkler or dripline point"
     */
    get selectableByPipe() {
      // rule 1
      if (this.disabled) return false;

      // rule 2
      if (
        (this.startPoint && this.startPoint.selected) ||
        (this.endPoint && this.endPoint.selected)
      ) {
        return false;
      }

      let point =
        this.startPoint && !this.startPoint.isStartPoint
          ? this.startPoint
          : this.endPoint && !this.endPoint.isStartPoint
          ? this.endPoint
          : undefined;

      // rule 3
      const p = point != null ? point.selectedPoint : undefined;
      if (p != null) {
        //selected
        const sHasDripline = p.linesHasDriplinePoint;
        const sHasSprinkler = p.linesHasSprinklerPoint;
        const sIsTubing = p.isTubing;
        const sHasRzws = p.linesHasRzwsPoint;
        const sHasRaisedBed = p.linesHasRaisedBedPoint;
        const sIsStartPoint = p.pointType === "start-point";
        const sHasStartPoint = p.linesHasStartPoint;

        //current
        const cHasDripline = point.linesHasDriplinePoint;
        const cHasSprinkler = point.linesHasSprinklerPoint;
        const cIsTubing = point.isTubing;
        const cHasRzws = point.linesHasRzwsPoint;
        const cHasRaisedBed = point.linesHasRaisedBedPoint;
        const cIsStartPoint = point.pointType === "start-point";
        const cHasStartPoint = point.linesHasStartPoint;

        if (
          (sHasDripline &&
            (cHasSprinkler || cHasRzws || cHasRaisedBed || cIsTubing)) ||
          (sHasSprinkler &&
            (cHasDripline || cHasRzws || cHasRaisedBed || cIsTubing)) ||
          (sHasRzws &&
            (cHasDripline || cHasSprinkler || cHasRaisedBed || cIsTubing)) ||
          (sHasRaisedBed &&
            (cHasDripline || cHasSprinkler || cHasRzws || cIsTubing)) ||
          (sIsTubing &&
            (cHasDripline || cHasSprinkler || cHasRaisedBed || cHasRzws))
        ) {
          return false;
        }

        // for multiple valves
        if (
          (cIsStartPoint &&
            (sIsStartPoint || (sHasStartPoint && !sIsTubing))) ||
          (sIsStartPoint && (cIsStartPoint || (cHasStartPoint && !cIsTubing)))
        ) {
          return false;
        }
      }

      return true;
    },
    //check that pipeline contains sprinkler point
    get hasSprinklerPoint() {
      return (
        (this.startPoint && this.startPoint.linesHasSprinklerPoint) ||
        (this.endPoint && this.endPoint.linesHasSprinklerPoint)
      );
    },
    //check that pipeline contains dripline connection point
    get hasDriplinePoint() {
      return (
        (this.startPoint && this.startPoint.linesHasDriplinePoint) ||
        (this.endPoint && this.endPoint.linesHasDriplinePoint)
      );
    },
    get hasRzwsPoint() {
      return (
        (this.startPoint && this.startPoint.linesHasRzwsPoint) ||
        (this.endPoint && this.endPoint.linesHasRzwsPoint)
      );
    },
    get hasRaisedBedPoint() {
      return (
        (this.startPoint && this.startPoint.linesHasRaisedBedPoint) ||
        (this.endPoint && this.endPoint.linesHasRaisedBedPoint)
      );
    },
    get hasStartPoint() {
      return (
        (this.startPoint && this.startPoint.linesHasStartPoint) ||
        (this.endPoint && this.endPoint.linesHasStartPoint)
      );
    },
    //generate SVG path
    get path() {
      if (!line.isValid) return undefined;
      return (
        "M " +
        this.startPoint.x +
        " " +
        this.startPoint.y +
        " L " +
        this.endPoint.x +
        " " +
        this.endPoint.y
      );
    },
    //length in px
    get length() {
      return this.startPoint && this.endPoint
        ? lineLength(this.startPoint, this.endPoint, null)
        : 0;
    },
    get pipelines() {
      const point =
        this.endPoint && !this.endPoint.isStartPoint
          ? this.endPoint
          : this.startPoint;

      return point ? point.pipelines : undefined;
    },
    get deleteConfirmText() {
      const lables = settingsState.texts.tools;
      return lables.pipeline.delete;
    },
    get toJSON() {
      return {
        id: this.id,
        type: this.type,
        start: this.start,
        end: this.end,
        disabled: this.disabled,
        color: this.color,
        cirquitColor: color,
      };
    },
  });

  // actions
  extendObservable(line, {
    shiftPoint: action((t, dx, dy) => {
      switch (t) {
        case "start":
          line.startPoint.move(line.startPoint.x + dx, line.startPoint.y + dy);
          break;
        case "end":
          line.endPoint.move(line.endPoint.x + dx, line.endPoint.y + dy);
          break;
        default:
          break;
      }
    }),
    onDisable: action((dsbld = !line.disabled) => {
      line.disabled = dsbld;
    }),
  });

  return line;
};

export default pipeFactory;
