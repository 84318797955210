function airCompressor(settings, plan) {
  const compressorElement = plan.systemElements.find(
      e => e.systemType === "air-compressor"
  )
  const { bomList, elements } = settings
  const { airCompressor: airCompressorList } = elements

  if (compressorElement == null) return []

  const airCompressor = airCompressorList[0]

  if (airCompressor == null) {
    throw new Error(`AirCompressor not found`)
  }

  const bomitems = [
    {
      bomId: airCompressor.bomId,
      qualityList: bomList[airCompressor.bomId],
      quantity: 1
    }
  ]

  return bomitems
}

export { airCompressor }
