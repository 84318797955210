import {Loader} from "./components/Loader";
import {useEffect} from "react";
import useStores from "./hooks/useStores";
import {ToastContainer} from "react-toastify";
import {Routers} from "./router";

import "./styles/scss/app.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/css/style.css";
import 'react-toastify/dist/ReactToastify.css';

import {observer} from "mobx-react";

function App() {

  const { uiState } = useStores();

    useEffect(function(){
        setTimeout(async function(){
            if(!uiState.user.control){
                await uiState.user.fetch();
            }
        })
    },[])
  return (
      <>
        {
          !uiState.user.fetched ? (
              <Loader />
          ) : (
              <Routers />
          )
        }

        <ToastContainer />
      </>
  );
}
export default observer(App);
