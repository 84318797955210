import React from "react";
import { observer } from "mobx-react";

import { IRRIGATION_QUANTITY } from "../../core/areaUtils";

import Properties from "./Properties";

import { ReactComponent as NoIrregationSVG } from "./../../assets/no-irrigation.svg";
import { ReactComponent as CanBeIrregatedSVG } from "./../../assets/can-be-irrigated.svg";
import { ReactComponent as ShouldBeIrregatedSVG } from "./../../assets/should-be-irrigated.svg";
import { ReactComponent as DriplineSVG } from "./../../assets/stripline.svg";
import { ReactComponent as CrossAbilitySVG } from "./../../assets/cross-ability.svg";
import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";

const IRRIGATION_QUANTITY_IMAGE = {
  no: NoIrregationSVG,
  can: CanBeIrregatedSVG,
  dripline: DriplineSVG,
  should: ShouldBeIrregatedSVG,
};

const irrigationImage = (quantity) => {
  const Component = IRRIGATION_QUANTITY_IMAGE[quantity];
  if (Component == null) {
    console.error(`Invalid irrigation quantity: ${quantity}`);
    return null;
  }

  return <Component />;
};

function PropertiesItem({
  icon,
  title,
  hasLine = false,
  active = false,
  disabled = false,
  onClick,
}) {
  return (
    <React.Fragment>
      <div
        onClick={!disabled ? onClick : null}
        className={`properties-item ${hasLine ? "properties-no_margin" : ""} ${
          active ? "active" : ""
        } ${disabled ? "disabled" : ""}`}
        onMouseOver={(e) => {
          if (!disabled)
            e.target.closest(".properties-item").classList.add("active");
        }}
        onMouseOut={(e) => {
          if (!active) {
            e.target.closest(".properties-item").classList.remove("active");
          }
        }}
      >
        <i>{icon}</i>
        <span>{title}</span>
      </div>
      {hasLine ? <div className="properties-line"></div> : ""}
    </React.Fragment>
  );
}

function AreaProperties({
  quantity,
  description,
  crossability,
  canBeNonCrossible,
  setIrrigationQuantity,
  toggleCrossability,
  changeDescription,
  disabled,
  onDisable,
  onRemove,
  onChange,
  labels,
  planIsEditable,
}) {
  const { formatMessage } = useIntl();

  return (
    <Properties
      planIsEditable={planIsEditable}
      onDisable={onDisable}
      disabled={disabled}
      onRemove={onRemove}
      onChange={onChange}
    >
      {Object.keys(IRRIGATION_QUANTITY).map((key, i, keys) => (
        <PropertiesItem
          key={key}
          active={quantity === key}
          disabled={disabled || !planIsEditable}
          icon={irrigationImage(key)}
          title={labels ? formatMessage({ id: labels[key] }) : key}
          onClick={() => {
            setIrrigationQuantity(key);
            onChange();
          }}
          hasLine={i === keys.length - 1}
        />
      ))}

      {canBeNonCrossible && (
        <PropertiesItem
          active={crossability}
          disabled={disabled || !planIsEditable}
          icon={<CrossAbilitySVG />}
          title={
            labels
              ? formatMessage(
                  crossability
                    ? { id: labels.cancross }
                    : { id: labels.cannotcross }
                )
              : "crossability"
          }
          onClick={() => {
            if (toggleCrossability()) {
              onChange();
            }
          }}
          hasLine={true}
        />
      )}

      <div className="properties-footer">
        <div className="properties-title">
          {labels
            ? formatMessage({ id: labels.description.title })
            : "description"}
        </div>
        <textarea
          disabled={disabled || !planIsEditable}
          value={description}
          className="properties-description"
          placeholder={
            labels
              ? formatMessage({ id: labels.description.placeholder })
              : "placeholder"
          }
          onChange={(e) => {
            if (e && e.target) changeDescription(e.target.value);
          }}
        ></textarea>
      </div>
    </Properties>
  );
}

let AreaPropertiesWithState = () => {
  const { uiState } = useStores();
  const { selectedElement } = uiState;
  return (
    <AreaProperties
      quantity={selectedElement.quantity}
      description={selectedElement.description}
      crossability={selectedElement.crossability}
      canBeNonCrossible={selectedElement.canBeNonCrossible}
      setIrrigationQuantity={selectedElement.setIrrigationQuantity}
      toggleCrossability={selectedElement.toggleCrossability}
      changeDescription={selectedElement.changeDescription}
      planIsEditable={uiState.planIsEditable}
      disabled={selectedElement.disabled}
      onDisable={selectedElement.onDisable}
      onRemove={uiState.onRemoveElement}
      onChange={uiState.reactions ? uiState.reactions.onAreaChange : () => {}}
      labels={
        uiState.settingsState
          ? uiState.settingsState.texts.properties.areas
          : null
      }
    />
  );
};

AreaPropertiesWithState = observer(AreaPropertiesWithState);
export default AreaPropertiesWithState;
