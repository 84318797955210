import {createBrowserRouter, redirect, RouterProvider} from "react-router-dom";
import {login} from "../pages/public/login";

import {Loader} from "../components/Loader";
import {observer} from "mobx-react";

import Dashboard from "../pages/private/Dashboard";
import Users from "../pages/private/Users";
import Plan from "../pages/private/Plan";
import useStores from "../hooks/useStores";
import Product from "../pages/private/Product";
import Settings from "../pages/private/Settings";



export const Routers = observer(() => {
    const { uiState } = useStores();

    const router = createBrowserRouter([
        {
            id: "root",
            path: "/",

            children: [
                {
                    path:'/',
                    index: true,

                    loader:  async () => {
                        if(uiState.user.auth){
                            return redirect('/dashboard')
                        }

                        return redirect('/login')
                    }
                },
                {
                    path:'/login',
                    index: true,
                    Component: login,
                    loader:  async () => {
                        if(!uiState.user.auth){
                            return null;
                        }

                        return redirect('/dashboard')
                    }
                },
                {
                    Component: Dashboard,
                    path:'/dashboard',
                    index: true,
                    loader:  async () => {
                        if(uiState.user.auth){
                            return null;
                        }

                        return redirect('/login')
                    }
                },
                {
                    Component: Users,
                    path:'/users',
                    index: true,
                    loader:  async () => {
                        if(uiState.user.auth){
                            return null;
                        }

                        return redirect('/login')
                    }
                },
                {
                    Component: Settings,
                    path:'/settings',
                    index: true,
                    loader:  async () => {
                        if(uiState.user.auth){
                            return null;
                        }

                        return redirect('/login')
                    }
                },
                {
                    Component: Product,
                    path:'/products',
                    index: true,
                    loader:  async () => {
                        if(uiState.user.auth){
                            return null;
                        }

                        return redirect('/login')
                    }
                },
                {
                    path:'/draw',
                    index: true,
                    Component: Plan,
                    loader:  async () => {
                        if(uiState.user.auth){
                            return null;
                        }

                        return redirect('/login')
                    }
                }
            ]
        }
    ]);

    return (
        <RouterProvider router={router} fallbackElement={ <Loader></Loader>} />
    )
})