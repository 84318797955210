function fertilizerSpreader(settings, plan) {
  const fertilizerElement = plan.systemElements.find(
      e => e.systemType === "fertilizer"
  )
  const { bomList, elements } = settings
  const { fertilizer: fertilizerList } = elements

  if (fertilizerElement == null) return []

  const fertilizer = fertilizerList.find(
      e => e.value === fertilizerElement.fertilizerType
  )

  if (fertilizer == null) {
    throw new Error(`Fertilizer ${fertilizerElement.fertilizerType} not found`)
  }

  const bomitems = [
    {
      bomId: fertilizer.bomId,
      qualityList: bomList[fertilizer.bomId],
      quantity: 1
    }
  ]

  return bomitems
}

export { fertilizerSpreader }
