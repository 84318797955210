import React from "react";
import { observer } from "mobx-react";

import { useDraggable } from "react-draggable-hoc";

import { pixelSizeByZoom } from "../../core/planUtils";
import { calculateAngleByPoints } from "../../core/geometryUtils";
import { calculateSvgPointsDirection } from "../../utils/uiUtils";
import useStores from "../../hooks/useStores";

const AreaControlRotate = ({
  x,
  y,
  zoomDelta,
  startAngle,
  changeAngle,
  height,
  onAfter = () => {},
}) => {
  const controlRef = React.useRef();
  const { deltaX, deltaY, isDragged } = useDraggable(controlRef, {
    dragProps: "area-rotate",
  });

  const controlPosition = React.useMemo(
    () => ({
      x: (height / 2 + 30.5) * Math.sin(startAngle * (Math.PI / 180)),
      y: (height / 2 + 30.5) * Math.cos(startAngle * (Math.PI / 180)),
      angle: startAngle,
    }),
    [height, startAngle]
  );

  const prev = React.useRef({ deltaX: 0, deltaY: 0, isDragged: false });
  const beforeDrag = React.useRef(controlPosition);

  React.useEffect(() => {
    if (isDragged && !prev.current.isDragged) {
      beforeDrag.current = controlPosition;
    }

    if (isDragged) {
      const start = beforeDrag.current;
      const points = [
        start,
        { x: 0, y: 0 },
        { x: start.x + deltaX, y: start.y - deltaY },
      ];
      const direction = calculateSvgPointsDirection(points);
      let angle = calculateAngleByPoints(...points);
      if (direction < 0) {
        angle = 360 - angle;
      }

      if (angle !== 0) {
        changeAngle(angle + start.angle);
      }
    }

    if (
      !isDragged &&
      (prev.current.deltaX !== 0 || prev.current.deltaY !== 0)
    ) {
      onAfter();
    }
    prev.current = { deltaX, deltaY, isDragged };
  }, [
    isDragged,
    deltaX,
    deltaY,
    changeAngle,
    startAngle,
    onAfter,
    controlPosition,
  ]);

  return (
    <React.Fragment>
      <g
        className={`area-control-rotate-item ${isDragged ? "active" : ""}`}
        transform={`translate(${x}, ${y})`}
        fill="#fff"
        stroke="#3e4346"
        strokeWidth={pixelSizeByZoom(1, zoomDelta)}
      >
        <g transform={`translate(0, ${-pixelSizeByZoom(30, zoomDelta)})`}>
          <g ref={controlRef}>
            <circle
              className="rotate-circle"
              x="0"
              y="0"
              r={pixelSizeByZoom(10.5, zoomDelta)}
            ></circle>
            <g
              className="rotate-arrow"
              transform={`translate(${-pixelSizeByZoom(
                9,
                zoomDelta
              )}, ${-pixelSizeByZoom(8, zoomDelta)}) scale(${pixelSizeByZoom(
                1,
                zoomDelta
              )})`}
              fill="#3e4346"
              stroke="none"
            >
              <path d="M9.4,14v2a3.75,3.75,0,0,0,1.1-.1l-.3-2A2.485,2.485,0,0,1,9.4,14Z" />
              <path d="M12.6,13l1.1,1.7a5.5,5.5,0,0,0,.9-.7l-1.3-1.5A3.039,3.039,0,0,1,12.6,13Z" />
              <path d="M11,13.8l.6,1.9a4.875,4.875,0,0,0,1.1-.4l-.8-1.8Z" />
              <path d="M13.9,11.9l1.5,1.3c.2-.3.5-.6.7-.9l-1.7-1.1A4.233,4.233,0,0,1,13.9,11.9Z" />
              <path d="M14.8,10.5l1.8.8a3.582,3.582,0,0,0,.4-1.1l-1.9-.6A9.44,9.44,0,0,1,14.8,10.5Z" />
              <path d="M9.4,0A8.076,8.076,0,0,0,1.7,5.6L.9,4,0,4.5,2.2,8.3,5.8,5.5l-.6-.7L3.7,6A5.957,5.957,0,0,1,9.3,2a6.018,6.018,0,0,1,6,6,2.769,2.769,0,0,1-.1.9l2,.3a4.484,4.484,0,0,0,.1-1.1A7.9,7.9,0,0,0,9.4,0Z" />
            </g>
          </g>

          <line
            x1="0"
            y1={pixelSizeByZoom(10, zoomDelta)}
            x2="0"
            y2={pixelSizeByZoom(30 - 8, zoomDelta)}
            stroke="#3e4346"
          />
        </g>
      </g>
    </React.Fragment>
  );
};

let AreaControlRotateWithState = ({ ...props }) => {
  const { uiState } = useStores();
  return (
    <AreaControlRotate
      zoomDelta={
        uiState.zoomState && uiState.zoomState.zoomDelta
          ? uiState.zoomState.zoomDelta
          : 1
      }
      {...props}
    />
  );
};

AreaControlRotateWithState = observer(AreaControlRotateWithState);
export default AreaControlRotateWithState;
