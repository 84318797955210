import { useIntl } from "react-intl";
import ToolButton from "../elements/ToolButton";

import { priceFormat } from "../../core/formatter";

import { ReactComponent as BuySVG } from "./../../assets/buy.svg";
import { ReactComponent as RainCheckSVG } from "./../../assets/rain-shift-check.svg";
import { ReactComponent as PdfDocumentSVG } from "./../../assets/install.svg";
import { ReactComponent as InstallationTimeSVG } from "./../../assets/installation-time.svg";
import { ReactComponent as DuplicateSVG } from "./../../assets/duplicate.svg";
import { ReactComponent as InstallerSVG } from "./../../assets/installer.svg";
import { ReactComponent as ProjectCalculationSVG } from "./../../assets/project-calculation.svg";
import { ReactComponent as RainBirdSVG } from "./../../assets/rain-bird.svg";
import { ReactComponent as WishListSVG } from "./../../assets/wish-list.svg";

const FooterButton = ({
  icon,
  title,
  onClick,
  position = "left",
  disabled = false,
  className = "",
  ...props
}) => {
  const Component =
    typeof onClick === "function"
      ? (props) => (
          <ToolButton disabled={disabled} onClick={onClick} {...props} />
        )
      : (props) => <div {...props} />;

  return (
    <Component
      className={["button-item", position, className]
        .filter((e) => e)
        .join(" ")}
      {...props}
    >
      <i>{icon}</i>
      <span>{title}</span>
    </Component>
  );
};

function BomFooter({
  user,
  planIsEditable,
  projectCalculation,
  requestRainBirdProducts,
  duplicatePlan,
  requestInstallation,
  generatePdf,
  rainCheckButtonLabel,
  planToCheck,
  redirectWishList,
  planToFinalize,
  totalPrice,
  minInstallationTime,
  maxInstallationTime,
  buyIsDisabled,
}) {
  const intl = useIntl();
  const { formatMessage, formatNumber } = intl;

  return (
    <>


      <div className="right-controls">
        <div className="price-total">
          {formatMessage({ id: "texts.steps.bom.popup.totalPrice" })}
          &nbsp;&nbsp;
          <span className="price">{priceFormat(intl, totalPrice)}</span>
        </div>


      </div>
    </>
  );
}

export default BomFooter;
