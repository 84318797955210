import React from "react";

import Popup from "./Popup";
import { ReactComponent as InvalidSVG } from "./../../assets/stop.svg";
import { useIntl } from "react-intl";
import Link from "../elements/Link";

function InvalidPropertiesPopup({ labels, hasActive, changeActive }) {
  const { formatMessage } = useIntl();

  return (
    <Popup
      className="properties-popup"
      hasActive={hasActive}
      position="top-left"
      hasOutherClickClose={false}
      onClose={() => {
        changeActive(false);
      }}
    >
      <div className="properties-popup-content">
        <InvalidSVG />
        <div className="properties-popup-text">
          <div className="popup-title">
            {formatMessage({ id: labels.title })}
          </div>
          <div>{formatMessage({ id: labels.text })}</div>
          {labels.moreURL && labels.moreURL !== "" && (
            <div className="popup-more">
              <Link
                href={formatMessage({ id: labels.moreURL })}
                target="_blank"
                rel="noopener noreferrer"
              >
                {formatMessage({ id: labels.moreLabel })}
              </Link>
            </div>
          )}
        </div>
      </div>
    </Popup>
  );
}

export default InvalidPropertiesPopup;
