function sensorKits(settings, plan) {
  const { bomList } = settings
  const GATEWAY_BOM = "smart-gateway"
  const SENSOR_BOM = "smart-sensor"

  let bomItems = []
  if (plan.sensors.length === 0) return bomItems

  bomItems.push({
    bomId: SENSOR_BOM,
    qualityList: bomList[SENSOR_BOM],
    quantity: plan.sensors.length
  })

  bomItems.push({
    bomId: GATEWAY_BOM,
    qualityList: bomList[GATEWAY_BOM],
    quantity: 1
  })

  return bomItems
}

export { sensorKits }
