import { extendObservable, observable, action } from "mobx";
import { v4 as uuid } from "uuid";
import { pointInArea } from "../../core/areaUtils";

/**
 * Represents a pipe point object
 * @param {*} param0 - pipe point json
 * @param {*} settingsState - state that contain a labels for UI
 * @returns pipe point object
 * contains
 * id - pipe identifier
 * pointType - "l-point" by default, [l-point, t-point, sprinkler-point, dripline-point, start-point]
 * disabled -
 * selected -
 * x,y - point coordinates
 * parentId
 */
const pipePointFactory = (
  {
    id,
    type = "pipeline-point",
    pointType = "l-point",
    disabled = false,
    x,
    y,
    parentId,
    sid,
  },
  settingsState
) => {
  const state = observable({
    x: x,
    y: y,
    pointType: pointType,
    disabled,
  });

  const point = observable({
    id: id || "pipe-point-" + uuid(),
    sid,
    parentId,
    type,
    get disabled() {
      return state.disabled;
    },
    get x() {
      return (state.pointType === "sprinkler-point" ||
        state.pointType === "start-point" ||
        state.pointType === "rzws-point" ||
        state.pointType === "raised-bed-point") &&
        point.parentId != null &&
        this.sprinkler != null
        ? this.sprinkler.x
        : state.x;
    },
    set x(nx) {
      state.x = nx;
    },
    get y() {
      return (state.pointType === "sprinkler-point" ||
        state.pointType === "start-point" ||
        state.pointType === "rzws-point" ||
        state.pointType === "raised-bed-point") &&
        point.parentId != null &&
        this.sprinkler != null
        ? this.sprinkler.y
        : state.y;
    },
    set y(ny) {
      state.y = ny;
    },
    //check and change point type if necessary
    get pointType() {
      let result;

      if (state.pointType === "l-point" && point.linesCount > 2) {
        result = "t-point";
      } else if (
        (state.pointType === "t-point" && point.linesCount <= 2) ||
        (state.pointType === "dripline-point" &&
          (!this.inDriplineArea || point.linesCount > 1))
      ) {
        result = "l-point";
      } else if (
        state.pointType === "water-tap-point" &&
        point.linesCount > 1
      ) {
        result = "l-point";
      } else {
        result = state.pointType;
      }

      return result;
    },
    set pointType(t) {
      state.pointType = t;
    },
    lazyFixPointType() {
      // check private state
      if (state.pointType === "dripline-point") {
        if (!point.inDriplineArea) {
          point.pointType = "l-point";
        }
      } else if (
        point.canBeDripline &&
        point.numberDriplinePointsInArea === 0
      ) {
        point.pointType = "dripline-point";
      }
    },
    //return pipes count with current point
    get linesCount() {
      return this.lines ? this.lines.length : 0;
    },
    get isStartPoint() {
      return this.pointType === "start-point" || this.pointType === "valve-box";
    },
    get isWaterSupplyPoint() {
      return this.pointType === "water-supply";
    },
    get isWaterMeterPoint() {
      return this.pointType === "water-meter";
    },
    get isWaterFilterPoint() {
      return this.pointType === "water-filter";
    },
    get isCombiBoxPoint() {
      return this.pointType === "combi-box";
    },
    get isFertilizerPoint() {
      return this.pointType === "fertilizer";
    },
    get isAirCompressorPoint() {
      return this.pointType === "air-compressor";
    },
    get isSystemElementPoint() {
      return (
        this.isStartPoint ||
        this.isWaterSupplyPoint ||
        this.isWaterTapPoint ||
        this.isWaterMeterPoint ||
        this.isWaterFilterPoint ||
        this.isCombiBoxPoint ||
        this.isFertilizerPoint ||
        this.isAirCompressorPoint
      );
    },
    get isRzwsPoint() {
      return this.pointType === "rzws-point";
    },
    get isRaisedBedPoint() {
      return this.pointType === "raised-bed-point";
    },
    get isWaterTapPoint() {
      return this.pointType === "water-tap-point";
    },
    get isSprinklerPoint() {
      return this.pointType === "sprinkler-point";
    },
    get isDriplinePoint() {
      return this.pointType === "dripline-point";
    },
    get isConnectionPoint() {
      return (
        this.isSprinklerPoint ||
        this.isStartPoint ||
        this.isWaterSupplyPoint ||
        this.isWaterTapPoint ||
        this.isRzwsPoint ||
        this.isRaisedBedPoint ||
        this.isWaterFilterPoint ||
        this.isWaterMeterPoint ||
        this.isCombiBoxPoint ||
        this.isFertilizerPoint ||
        this.isAirCompressorPoint
      );
    },
    //check that the point is inside the dripline area
    get inDriplineArea() {
      return this.driplineArea != null;
    },
    get driplineArea() {
      return this.areas.find((area) => {
        return area.isDriplineArea && pointInArea(point, area);
      });
    },
    //check that pipeline contains sprinkler point
    get linesHasSprinklerPoint() {
      if (this.isStartPoint) return false;

      let result = this.isSprinklerPoint;
      if (!result && this.pipelines) {
        result = this.pipelines.find((p) => p.hasSprinklerPoint) != null;
      }
      return result;
    },
    //check that pipeline contains dripline connection point
    get linesHasDriplinePoint() {
      if (this.isStartPoint) return false;

      let result = this.isDriplinePoint;
      if (!result && this.pipelines) {
        result = this.pipelines.find((p) => p.hasDriplinePoint) != null;
      }
      return result;
    },
    //check that pipeline contains dripline connection point
    get linesHasWaterTapPoint() {
      if (this.isStartPoint) return false;

      let result = this.isWaterTapPoint;
      if (!result && this.pipelines) {
        result = this.pipelines.find((p) => p.hasWaterTapPoint) != null;
      }
      return result;
    },
    get linesHasRzwsPoint() {
      if (this.isStartPoint) return false;

      let result = this.isRzwsPoint;
      if (!result && this.pipelines) {
        result = this.pipelines.find((p) => p.hasRzwsPoint) != null;
      }
      return result;
    },
    //check that pipeline contains dripline connection point
    get linesHasRaisedBedPoint() {
      if (this.isStartPoint) return false;

      let result = this.isRaisedBedPoint;
      if (!result && this.pipelines) {
        result = this.pipelines.find((p) => p.hasRaisedBedPoint) != null;
      }
      return result;
    },
    get isTubing() {
      if (this.isStartPoint) return false;

      let result = this.isWaterTapPoint;
      if (!result && this.pipelines) {
        result = this.pipelines.find((p) => p.isTubingPipe) != null;
      }
      return result;
    },
    get linesHasStartPoint() {
      if (this.isStartPoint) return true;

      return this.pipelines.find((p) => p.hasStartPoint) != null;
    },
    /**
     * point can be dripline when
     * 1. is inside the dripline area
     * 2. is not connection point
     * 3. has only one line
     * 4. pipeline is not contains the sprinklers
     * 5. pipeline is not contains th other dripline connection points
     */
    get canBeDripline() {
      return (
        this.inDriplineArea &&
        !this.isConnectionPoint &&
        this.linesCount <= 1 &&
        !this.linesHasSprinklerPoint &&
        !this.linesHasDriplinePoint &&
        !this.linesHasWaterTapPoint &&
        !this.linesHasRzwsPoint &&
        !this.linesHasRaisedBedPoint
      );
    },
  });

  // getters
  extendObservable(point, {
    /**
     * pipe point is selectable for new pipe when
     * 1. is not disabled
     * 2. selected point is not adjacent
     * 3. not filled limit by type
     * 4. does not violate the rule
     * "pipeline with dripline connection point cannot be connected to a pipeline with a sprinkler"
     * "pipeline with watertappoint can be connect only to tubing or other pipeline with watertappoint"
     */
    get selectableByPipe() {
      // rule 1
      if (this.disabled) return false;

      // rule 2
      const linesCount = this.lines ? this.lines.length : 0;
      let neighborSelected = false;
      if (linesCount > 0) {
        neighborSelected = this.lines.reduce(
          (sum, next) =>
            sum ||
            //FIXME
            (next.start !== this.id &&
              next.startPoint &&
              next.startPoint.selected) ||
            (next.end !== this.id && next.endPoint && next.endPoint.selected),
          //|| !next.selectableByPipe,
          false
        );
      }
      if (neighborSelected) {
        return false;
      }

      // rule 3
      let result = true;
      switch (pointType) {
        case "start-point":
          result = true;
          break;
        case "sprinkler-point":
          result = linesCount < 2;
          break;
        case "water-tap-point":
          result = linesCount < 1;
          break;
        case "l-point":
          result = linesCount < 3;
          break;
        case "t-point":
          result = linesCount < 3;
          break;
        case "rzws-point":
          result = linesCount < 2;
          break;
        case "raised-bed-point":
          result = linesCount < 2;
          break;
        case "water-meter":
          result = linesCount < 2;
          break;
        case "water-filter":
          result = linesCount < 2;
          break;
        case "combi-box":
          result = linesCount < 2;
          break;
        case "fertilizer":
          result = linesCount < 2;
          break;
        //case "air-compressor":
        //result = linesCount < 2;
        default:
          break;
      }
      if (!result) {
        return false;
      }

      // rule 4
      const p = this.selectedPoint;
      if (p != null) {
        //selected
        const sHasDripline = p.linesHasDriplinePoint;
        const sHasSprinkler = p.linesHasSprinklerPoint;
        const sIsTubing = p.isTubing;
        const sHasRzws = p.linesHasRzwsPoint;
        const sHasRaisedBed = p.linesHasRaisedBedPoint;
        const sIsStartPoint = p.pointType === "start-point";
        const sHasStartPoint = p.linesHasStartPoint;
        // const sIsWaterSource = p.pointType === "water-supply";
        // const sHasWaterSource = p.linesHasWaterSource;

        //current
        const cHasDripline = point.linesHasDriplinePoint;
        const cHasSprinkler = point.linesHasSprinklerPoint;
        const cIsTubing = point.isTubing;
        const cHasRzws = point.linesHasRzwsPoint;
        const cHasRaisedBed = point.linesHasRaisedBedPoint;
        const cIsStartPoint = point.pointType === "start-point";
        const cHasStartPoint = point.linesHasStartPoint;
        // const cIsWaterSource = point.pointType === "water-supply";
        // const cHasWaterSource = point.linesHasWaterSource;

        if (
          (sHasDripline &&
            (cHasSprinkler || cHasRzws || cHasRaisedBed || cIsTubing)) ||
          (sHasSprinkler &&
            (cHasDripline || cHasRzws || cHasRaisedBed || cIsTubing)) ||
          (sHasRzws &&
            (cHasDripline || cHasSprinkler || cHasRaisedBed || cIsTubing)) ||
          (sHasRaisedBed &&
            (cHasDripline || cHasSprinkler || cHasRzws || cIsTubing)) ||
          (sIsTubing &&
            (cHasDripline || cHasSprinkler || cHasRaisedBed || cHasRzws))
        ) {
          return false;
        }

        if (
          id !== p.id &&
          pointType === "water-tap-point" &&
          p.pointType === pointType
        ) {
          return false;
        }

        // for multiple valves
        if (
          (cIsStartPoint &&
            (sIsStartPoint || (sHasStartPoint && !sIsTubing))) ||
          (sIsStartPoint && (cIsStartPoint || (cHasStartPoint && !cIsTubing)))
          // ||
          // ((cIsWaterSource || cHasWaterSource) &&
          //   cHasStartPoint &&
          //   (sIsStartPoint || sHasStartPoint)) ||
          // ((sIsWaterSource || sHasWaterSource) &&
          //   sHasStartPoint &&
          //   (cIsStartPoint || cHasStartPoint))
        ) {
          return false;
        }
      }

      return result;
    },
    get deleteConfirmText() {
      const lables = settingsState.texts.tools;
      return lables.pipelinePoint.delete;
    },
    get toJSON() {
      return {
        id: this.id,
        type: this.type,
        pointType: this.pointType,
        disabled: this.disabled,
        x: this.x,
        y: this.y,
        parentId: this.parentId,
      };
    },
  });

  // actions
  extendObservable(point, {
    move: action((nx, ny) => {
      if (!point.isConnectionPoint) {
        state.x = nx;
        state.y = ny;
      }
    }),
    onDisable: action((dsbld = !point.disabled) => {
      if (!point.isConnectionPoint) {
        state.disabled = dsbld;
      }
    }),
    changeDripline: action(() => {
      point.pointType =
        point.pointType === "l-point" ? "dripline-point" : "l-point";
    }),
  });

  return point;
};

export default pipePointFactory;
