import React from "react";
import DefaultSVGFilters from "../DefaultSVGFilters";

export const Background = ({
  viewbox,
  width,
  height,
  background,
  backgroundSize,
  grayscale = false,
  orientation = 1,
}) => {
  if (!(background && backgroundSize)) return null;

  const w = orientation < 5 ? backgroundSize.w : backgroundSize.h;
  const h = orientation < 5 ? backgroundSize.h : backgroundSize.w;

  let transformStr = null;
  switch (orientation) {
    //1 = Horizontal (normal)
    //2 = Mirror horizontal
    case 2:
      transformStr = `translate(${w}, 0) scale(-1 1)`;
      break;
    //3 = Rotate 180
    case 3:
      transformStr = `rotate(180, ${w / 2}, ${h / 2})`;
      break;
    //4 = Mirror vertical
    case 4:
      transformStr = `translate(0, ${h}) scale(1 -1)`;
      break;
    //5 = Mirror horizontal and rotate 270 CW
    case 5:
      transformStr = `scale(-1 1) rotate(-270)`;
      break;
    //6 = Rotate 90 CW
    case 6:
      transformStr = `rotate(90, 0, 0) translate(0, ${-h})`;
      break;
    //7 = Mirror horizontal and rotate 90 CW
    case 7:
      transformStr = `translate(${w}, 0) scale(-1 1) rotate(-90, ${w}, 0) translate(0, ${-h})`;
      break;
    //8 = Rotate 270 CW
    case 8:
      transformStr = `rotate(270, 0, 0) translate(${-w}, 0)`;
      break;
    default:
      break;
  }

  const imgData =
    grayscale && background?.grayscale != null
      ? background.grayscale
      : background.src;

  return (
    <svg
      id="bgrnd"
      viewBox={`${viewbox.x} ${viewbox.y} ${viewbox.w} ${viewbox.h}`}
      width={width}
      height={height}
    >
      <DefaultSVGFilters />
      <image
        href={imgData}
        filter={grayscale ? "url(#grayscale)" : ""}
        width={w}
        height={h}
        transform={transformStr}
        x="0"
        y="0"
      />
    </svg>
  );
};

export const Overlay = ({
  viewbox,
  width,
  height,
  opacity,
  grayscale = false,
}) => (
  <svg viewBox={`${viewbox.x} ${viewbox.y} ${viewbox.w} ${viewbox.h}`}>
    <DefaultSVGFilters />
    <rect
      x="0"
      y="0"
      width={width}
      height={height}
      fill="#ffffff"
      fillOpacity={opacity == null ? 0 : opacity}
      filter={grayscale ? "url(#grayscale)" : ""}
    />
  </svg>
);

export const Grid = ({
                       viewbox,
                       width,
                       height,
                       grid,
                       imageScale = 1,
                       grayscale = false,
                     }) => {
  const gridSize = grid && grid.size ? grid.size * imageScale * 0.2 : 0;
  //const gridSize = grid && grid.size ? grid.size * imageScale : 0;
  const x = gridSize ? gridSize + (viewbox.x % gridSize) : 0;
  const y = gridSize ? gridSize + (viewbox.y % gridSize) : 0;
  const gridSize5m = gridSize*5;
  return (
      <svg
          viewBox={`0 0 ${viewbox.w} ${viewbox.h}`}
          width={width}
          height={height}
          filter={grayscale ? "grayscale(1)" : ""}
      >
        <defs>
          <pattern
              id="grid"
              width={gridSize}
              height={gridSize}
              patternUnits="userSpaceOnUse"
          >
            <path
                d={`M ${gridSize} 0 L 0 0 0 ${gridSize}`}
                fill="none"
                stroke="#000"
                //opacity buradan ayarlanabilir slider'a eklenecek
                opacity="0.3"
                strokeWidth={grid.weight * imageScale}
            />
          </pattern>
          <pattern
              id="grid5m"
              width={gridSize5m}
              height={gridSize5m}
              patternUnits="userSpaceOnUse"
          >
            <path
                d={`M ${gridSize5m} 0 L 0 0 0 ${gridSize5m}`}
                fill="none"
                stroke="#000"
                //opacity buradan ayarlanabilir slider'a eklenecek
                opacity="0.6"
                strokeWidth={grid.weight * imageScale}
            />
          </pattern>
        </defs>
        <DefaultSVGFilters />
        <rect
            width={width + x}
            height={height + y}
            fill="url(#grid)"

            transform={`translate(${-x}, ${-y})`}
            filter={grayscale ? "url(#grayscale)" : ""}
        />
        <rect
            width={width + x}
            height={height + y}
            fill="url(#grid5m)"
            transform={`translate(${-x}, ${-y})`}
            filter={grayscale ? "url(#grayscale)" : ""}
        />
      </svg>
  );
};

export const ElementsWrapper = ({
  width,
  height,
  viewbox,
  children,
  style,
}) => {
  return (
    <svg
      viewBox={`${viewbox.x} ${viewbox.y} ${viewbox.w} ${viewbox.h}`}
      width={width}
      height={height}
      style={style}
      className={"system-elements-svg"}
    >
      {children}
    </svg>
  );
};

const SimpleComponents = () => <></>;

export default SimpleComponents;
