import { action, extendObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";

import { numberFormat } from "../../core/formatter";

/**
 * Represents a rzws object
 * @param {*} param0 - rzws json
 * @param {*} settingsState - state that contain a labels for UI
 * @param {*} param2 - additional UI params
 * @returns rzws object
 */
const rzwsFactory = (
  { id, x, y, disabled = false, waterdrop } = {},
  settingsState
) => {
  const state = observable({
    x,
    y,
    waterdrop,
  });

  //inject variables
  const rzws = observable({
    get type() {
      return "rzws";
    },
    id: id || "rzws-" + uuid(),
    disabled,
    get waterdropInBar() {
      return state.waterdrop != null
        ? numberFormat(state.waterdrop / 100000, 1) * 1
        : null;
    },
    get waterpressureInBar() {
      if (this.waterdropInBar == null) return null;

      if (this.waterdropInBar < 0) return null;

      return Math.ceil(Math.max(3.5 - this.waterdropInBar, 0) * 10) / 10;
    },
    get x() {
      return state.x;
    },
    set x(x) {
      state.x = x;
    },
    get y() {
      return state.y;
    },
    set y(y) {
      state.y = y;
    },
    get name() {
      return settingsState.elements.rzws.name;
    },
  });

  // getters
  extendObservable(rzws, {
    get title() {
      return rzws.name;
    },
    get hasWaterpressureWarn() {
      return (
        rzws.waterpressureInBar != null && rzws.waterpressureInBar * 10 < 28
      );
    },
    get deleteConfirmText() {
      return settingsState.texts.tools.rzws.delete;
    },
  });

  // actions
  extendObservable(rzws, {
    drag: action((x, y) => {
      state.x = x;
      state.y = y;
    }),
    onDisable: action(() => {
      rzws.disabled = !rzws.disabled;
    }),
    changeWaterDrop: action((waterdrop) => {
      state.waterdrop = waterdrop;
    }),
    //calculate by water consumption and angle
    get waterflow() {
      const result = settingsState.elements.rzws.waterConsumption;
      return result ?? 0;
    },
    get extremePoints() {
      const { x, y } = rzws;

      return [{ x, y }];
    },
    get toJSON() {
      const { id, name, title, type, x, y, disabled, waterflow, waterdrop } =
        this;

      return {
        id,
        name,
        title,
        type,
        x,
        y,
        disabled,
        waterflow,
        waterdrop,
      };
    },
  });

  return rzws;
};

export default rzwsFactory;
