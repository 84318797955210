import React, {useEffect, useState} from 'react';
import {Layout} from "../../components/Layout";
import {observer} from "mobx-react";
import { Button, Container, Pagination,  Table} from "react-bootstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {Link} from "react-router-dom";
import {Sidebar} from "../../components/Sidebar";
import axios from "axios";
import {config} from "../../config";
import {toast} from "react-toastify";


const Dashboard = observer(() => {
    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);
    const [page,setPage] = useState(1);
    const intl = useIntl();


    useEffect(() => {
        getList();
    }, [page]);
    const [ loading, setLoading] = useState(false)
    const getList = async () => {
        try{
            const { data:{ total, list: lists } } = await axios.get(config.apiUrl+'/plan/list/'+page,{
                headers:{
                    'x-access-token': localStorage.getItem('authKey'),
                    'x-access-path':'list-user'
                }
            });
            setTotal(total);
            setList(lists)
        }catch (e) {
            let message = 'global.error';
            if(e){
                if(e.response){
                    if(e.response.status === 400){
                        if(e.response.data){
                            message = e.response.data.message[0].msg
                        }
                    }
                }
            }
            toast.error(intl.formatMessage({
                id:message,
                defaultMessage:'An error occurred please try again'
            }))
        }
    }

    const deleteAction = async (id) => {

        setLoading(true)
        try{
            await axios.post(config.apiUrl+'/plan/delete/', {
                id
            },{
                headers:{
                    'x-access-token': localStorage.getItem('authKey'),
                    'x-access-path':'delete-plan'
                }
            });

            toast.success(intl.formatMessage({
                id: 'plan.delete_success',
                defaultMessage:'Plan deleted successfully'
            }))
            getList();
        }catch (e) {
            let message = 'global.error';
            if(e){
                if(e.response){
                    if(e.response.status === 400){
                        if(e.response.data){
                            message = e.response.data.message[0].msg
                        }
                    }
                }
            }
            toast.error(intl.formatMessage({
                id:message,
                defaultMessage:'An error occurred please try again'
            }))
        }

        setLoading(false)
    }





    const renderPageItem = (number) => (
        <Pagination.Item key={number} active={number === page} onClick={() => setPage(number)}>
            {number}
        </Pagination.Item>
    );

    const Pages = () => {
        let items = [];
        let totalPages = Math.round(total / 10);

        if (totalPages <= 5) {
            for (let number = 1; number <= totalPages; number++) {
                items.push(renderPageItem(number));
            }
        } else {
            if (page > 3) {
                items.push(renderPageItem(1));
                items.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }

            const startPage = Math.max(1, page - 2);
            const endPage = Math.min(totalPages, page + 2);

            for (let number = startPage; number <= endPage; number++) {
                items.push(renderPageItem(number));
            }

            if (page < totalPages - 2) {
                items.push(<Pagination.Ellipsis key="end-ellipsis" />);
                items.push(renderPageItem(totalPages));
            }
        }

        return <Pagination>{items}</Pagination>;
    };

    return (

        <Layout title={'Dashboard'}>

            <div className="geex-content__section geex-content__transaction">
                <div className="geex-content__section__header">
                    <div className="geex-content__section__header__title-part">
                        <h4 className="geex-content__section__header__title">Project List</h4>
                    </div>

                    <div className="geex-content__section__header__content-part">
                        <div className="geex-content__faq__accordion__item primary-bg">
                            <Link
                                id="addUserBtn"
                                className="geex-btn geex-btn--transparent text-center"
                                style={{backgroundColor: 'none', color: 'white'}}
                                to={'/draw'}
                            >
                                New Project
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="geex-content__section mt-4">
                <div className="geex-content__section__content">
                    <Table responsive className={'user-table'}>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th><FormattedMessage id="dashboard.name" defaultMessage="Project Name"/></th>
                            <th><FormattedMessage id="dashboard.create_date" defaultMessage="Create Date"/></th>
                            <th><FormattedMessage id="dashboard.action" defaultMessage="Action"/></th>
                        </tr>
                        </thead>

                        <tbody>
                        {
                            list.map((item) => (
                                <tr key={item.ID}>
                                    <td>{item.ID}</td>
                                    <td>{item.Name}</td>
                                    <td>{item.AddDate}</td>

                                    <td>
                                        <Link to={'/draw?planId='+item.ID} className={'btn btn-sm m-1 btn-success'}>Draw</Link>
                                        <Button className={'btn btn-sm btn-danger m-1'}
                                                onClick={() => deleteAction(item.ID)}>Delete</Button>
                                    </td>
                                </tr>
                            ))
                        }

                        </tbody>
                    </Table>

                    <Pages></Pages>
                </div>
            </div>

        </Layout>


    );
});

export default Dashboard;