import { GR_ROTATOR_ID } from "../../../core/settings/settingsUtils";

import { ReactComponent as IrrigationShapeSVG } from "../../../assets/irrigation-shape.svg";
import { ReactComponent as SprinklerSVG } from "../../../assets/sprinkler-step.svg";
import { ReactComponent as GRSprinklerSVG } from "../../../assets/gr_sprinkler-step.svg";
import { ReactComponent as SystemElementsSVG } from "../../../assets/system-elements.svg";
import { ReactComponent as PipelineSVG } from "../../../assets/pipeline.svg";
import { ReactComponent as SensorSVG } from "../../../assets/sensor-step.svg";
import { ReactComponent as RecommendationsSVG } from "../../../assets/recommendations.svg";
import { ReactComponent as PlanCheckSVG } from "../../../assets/Plan_Check.svg";

const STEPS_ICON = {
  areas: IrrigationShapeSVG,
  sprinklers: SprinklerSVG,
  "system-elements": SystemElementsSVG,
  pipeline: PipelineSVG,
  sensor: SensorSVG,
  recommendations: RecommendationsSVG,
};

function StepItemIcon({ stepName, sprinklerSetType, isCheckStatus }) {
  let IconComponent;
  switch (stepName) {
    case "sprinklers":
      IconComponent =
        sprinklerSetType === GR_ROTATOR_ID ? GRSprinklerSVG : undefined;
      break;
    case "recommendations":
      IconComponent = isCheckStatus ? PlanCheckSVG : undefined;
      break;
    default:
      break;
  }

  IconComponent = IconComponent ?? STEPS_ICON[stepName];

  return IconComponent ? <IconComponent /> : undefined;
}

export default StepItemIcon;
