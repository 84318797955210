import React from "react";
import { ReactComponent as ArrowUpSVG } from "./../../assets/arrow-up.svg";
import { ReactComponent as ArrowDownSVG } from "./../../assets/arrow-down.svg";

function HeaderSprinkleDropdown() {
  // State to manage current selection and dropdown visibility
  const [current, setCurrent] = React.useState(""); // Start with no selection
  const [hasOpen, setOpen] = React.useState(false);

  // Static data for dropdown options
  const dropdownOptions = [
    { label: "Option 1", value: "MP Rotator" },
    { label: "Option 2", value: "Gear Sprinkler" }
  ];

  const dropdownRef = React.useRef();
  const containerRef = React.useRef();

  const hideOutside = (e) => {
    if (hasOpen && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  React.useEffect(() => {
    const dropdownNode = dropdownRef.current;
    const containerNode = containerRef.current;
    const dropdownCoords = dropdownNode.getBoundingClientRect();

    if (hasOpen && containerNode) {
      let positionTop = (containerNode.style.top = dropdownCoords.top + dropdownNode.clientHeight + "px");
      if (dropdownCoords.top + dropdownNode.clientHeight + containerNode.clientHeight > window.innerHeight) {
        positionTop = dropdownCoords.top - containerNode.clientHeight + "px";
      }

      containerNode.style.top = positionTop;
      containerNode.style.width = dropdownNode.offsetWidth + "px";
      containerNode.style.visibility = "visible";
    }

    document.addEventListener("mouseup", hideOutside);
    return () => {
      document.removeEventListener("mouseup", hideOutside);
    };
  }, [hasOpen]);

  return (
    <div className="dropdown" ref={dropdownRef} style={{ minWidth: '50px', width: '100%' }}>
      <div
        className="dropdown-value"
        onClick={(e) => {
          e.preventDefault();
          setOpen(!hasOpen);
        }}
      >
        <div className="dropdown-text">
          {current || "Sprinkle Type"} {/* Show "Brands" if no selection */}
        </div>
        <div className="dropdown-arrow" style={{ marginLeft: '10px', marginRight: '10px', fontSize: 'large', fontWeight: '900' }}>
          {hasOpen ? <ArrowUpSVG /> : <ArrowDownSVG />}
        </div>
      </div>

      {hasOpen && (
        <div ref={containerRef} className="dropdown-container">
          {dropdownOptions.map((item, idx) => (
            <div
              style={{ color: 'white' }}
              key={idx}
              className={`dropdown-item ${current === item.value ? "active" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                setCurrent(item.value);
                setOpen(false);
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default HeaderSprinkleDropdown;
