import {Link, redirect} from "react-router-dom";
import logo from '../assets/img/draw-garden.png'
import profile from '../assets/img/profile.jpg'
import {Container, Dropdown, Nav, Navbar, Image, Modal, Button, Spinner, Col} from "react-bootstrap";
import {forwardRef, useEffect, useState} from "react";
import {observer} from "mobx-react";

import {FormattedMessage, useIntl} from "react-intl";
import axios from "axios";
import {toast} from "react-toastify";
import useStores from "../hooks/useStores";
import {Sidebar} from "./Sidebar";
export const Layout = observer(({children, title}) => {
    const intl = useIntl();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loading, setLoading] = useState(false)
    const { uiState } = useStores();
    const [ form, setForm] = useState({
        first_name: uiState.user?.firstName,
        last_name: uiState.user?.lastName,
        password: '',
        email: uiState.user?.email
    })




    const [show, setShow] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const sidebarShow = () => setSidebar(true);
    const sidebarHide = () => setSidebar(false);
    const editAction = async (e) => {
        setLoading(true)

        e.preventDefault();
        try{
            const { data } = await axios.post('http://localhost:5002/user/update-profile', form,{
                headers:{
                    'x-access-token': localStorage.getItem('authKey'),
                    'x-access-path':'edit-profile'
                }
            });
            uiState.user.login(data);
            setShow(false)
            toast.success(intl.formatMessage({
                id: 'navbar.edit_profile_success',
                defaultMessage:'Your information has been successfully saved'
            }))
        }catch (e) {
            let message = 'global.error';
            if(e){
                if(e.response){
                    if(e.response.status === 400){
                        if(e.response.data){
                            message = e.response.data.message[0].msg
                        }
                    }
                }
            }
            toast.error(intl.formatMessage({
                id:message,
                defaultMessage:'An error occurred please try again'
            }))
        }

        setLoading(false)
    }



    const CustomToggle = forwardRef(({ children, onClick }, ref) => (


        <Image className={'border border-success-clarity'} ref={ref}
               onClick={(e) => {
                   e.preventDefault();
                   onClick(e);
               }} src={profile} width={40} roundedCircle  alt="User-Profile-Image"/>


    ));


    return (
        <main className="geex-main-content">
            <Sidebar show={sidebar} sidebarHide={sidebarHide}/>
            <div className="geex-content">
                <Navbar className="geex-content__header" expand="lg">

                    <div className={'container flex-row justify-content-start'}>

                        <Navbar.Toggle onClick={sidebarShow} style={{fontSize:16}} className={'btn btn-sm'} aria-controls="basic-navbar-nav" />
                        <Navbar.Brand className={'ms-4'}>
                            <h3 className={'geex-content__header__title'}>{title}</h3>
                        </Navbar.Brand>
                    </div>
                </Navbar>
                <Dropdown style={{
                    position: 'absolute',
                    right: '33px',
                    top: '32px'
                }}>
                    <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={handleShow}><FormattedMessage id="navbar.edit_profile"
                                                                              defaultMessage="Edit Profile"/></Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            uiState.user.logout();
                            redirect('/login')
                        }}>
                            <FormattedMessage id="navbar.logout" defaultMessage="Logout"/>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <div className="geex-content__wrapper">
                   <Container>
                       {children}
                   </Container>
                </div>
            </div>

             <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className={'text-sm'}><FormattedMessage id="navbar.edit_profile"
                                                                             defaultMessage="Edit Profile"/></Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <form onSubmit={editAction} autoComplete="off">
                            <div className="mb-3">
                                <label className="mb-2 text-muted" htmlFor="first_name">
                                    <FormattedMessage id="navbar.first_name" defaultMessage="First Name"/>
                                </label>
                                <input id="first_name" value={form.first_name} onChange={(e) => {
                                    setForm({
                                        ...form,
                                        first_name: e.target.value
                                    })
                                }} placeholder={intl.formatMessage({
                                    id: 'navbar.first_name_placeholder',
                                    defaultMessage: 'Please enter first name'
                                })} type="text" className="form-control" autoFocus/>


                            </div>

                            <div className="mb-3">
                                <label className="mb-2 text-muted" htmlFor="last_name">
                                    <FormattedMessage id="navbar.last_name" defaultMessage="Last Name"/>
                                </label>
                                <input id="last_name" value={form.last_name} onChange={(e) => {
                                    setForm({
                                        ...form,
                                        last_name: e.target.value
                                    })
                                }} placeholder={intl.formatMessage({
                                    id: 'navbar.last_name_placeholder',
                                    defaultMessage: 'Please enter last name'
                                })} type="text" className="form-control" autoFocus/>


                            </div>

                            <div className="mb-3">
                                <label className="mb-2 text-muted" htmlFor="email">
                                    <FormattedMessage id="navbar.email" defaultMessage="E-Mail Address"/>
                                </label>
                                <input id="email" value={form.email} onChange={(e) => {
                                    setForm({
                                        ...form,
                                        email: e.target.value
                                    })
                                }} placeholder={intl.formatMessage({
                                    id: 'navbar.email_placeholder',
                                    defaultMessage: 'Please enter e email'
                                })} type="email" className="form-control" name="email" autoFocus/>


                            </div>

                            <div className="mb-3">
                                <div className="mb-2 w-100">
                                    <label className="text-muted" htmlFor="password">
                                        <FormattedMessage id="navbar.password" defaultMessage="Password"/>
                                    </label>
                                </div>
                                <input id="password" onChange={(e) => {
                                    setForm({
                                        ...form,
                                        password: e.target.value
                                    })
                                }} type="password" placeholder={intl.formatMessage({
                                    id: 'navbar.password_placeholder',
                                    defaultMessage: 'Please enter your password'
                                })} className="form-control"/>

                            </div>

                            <div className="d-flex align-items-center">

                                <Button variant="secondary" onClick={handleClose}>
                                    <FormattedMessage id="navbar.edit_profile_close" defaultMessage="Close"/>
                                </Button>


                                <button type="submit" className="btn btn-primary ms-auto" disabled={loading}>
                                    {
                                        loading ? (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <span> <FormattedMessage id="system.loading"
                                                                         defaultMessage="Loading..."/></span>
                                            </>
                                        ) : (
                                            <FormattedMessage id="navbar.edit_profile_button" defaultMessage="Save"/>
                                        )
                                    }

                                </button>
                            </div>

                        </form>
                    </Modal.Body>

                </Modal>
        </main>
)
})