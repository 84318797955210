import { action, extendObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";

import pointFactory from "./areaPointFactory";
import { sizeInMetersByPixel } from "../../core/planUtils";

import {
  rotatePoint,
  polygonSquare,
  linesByPoints,
  convertBezierToPoligon,
  calculateAngleByPoints,
  calculateAngleByLine,
  calculatePointsDirection,
} from "../../core/geometryUtils";
import {
  pointInArea,
  hasSelfIntersection,
  hasIntersection,
  generateElementPath,
  generatePolygonPath,
  IRRIGATION_QUANTITY,
} from "../../core/areaUtils";
import { calculateSvgPointsDirection } from "../../utils/uiUtils";

/**
 * Represents an area object
 * @param {*} param0 - area json
 * @param {*} settingsState - state that contain a labels for UI
 * @param {*} param2 - additional UI params
 * @returns area object
 */
const areaFactory = (
  {
    id,
    type = "area",
    areaType,
    x,
    y,
    startAngle = 0,
    width,
    height,
    points,
    quantity = "should",
    crossability = true,
    description = "",
    enclosed = true,
    disabled = false,
  } = {},
  settingsState,
  { scale } = {}
) => {
  const state = observable({
    crossability,
  });

  // inject variables
  const area = observable({
    id: id || "area-" + uuid(),
    areaType,
    type,
    x,
    y,
    startAngle: startAngle ? startAngle % 360 : 0,
    width,
    height,
    quantity,
    description,
    enclosed,
    disabled,
  });

  // override area point
  const areaPointFactory = action((data) => {
    const point = pointFactory(data);

    //additional getters
    extendObservable(point, {
      get nextPoint() {
        if (!area.points || area.points.length === 0) {
          return undefined;
        }
        const index = area.points.indexOf(point);
        const length = area.points.length;
        return index >= 0 && (area.enclosed || index + 1 < length)
          ? area.points[(index + 1) % length]
          : undefined;
      },
      get prevPoint() {
        if (!area.points || area.points.length === 0) {
          return undefined;
        }
        const index = area.points.indexOf(point);
        const length = area.points.length;
        return index >= 0 && (area.enclosed || index >= 1)
          ? area.points[(index - 1 + length) % length]
          : undefined;
      },
      get pointsDirection() {
        return area.pointsDirection;
      },
    });

    return point;
  });

  // inject free drawing points
  extendObservable(area, {
    points: Array.isArray(points)
      ? points.map((data) => areaPointFactory(data))
      : [],
  });

  // getters
  extendObservable(area, {
    get crossability() {
      return area.canBeNonCrossible
        ? state.crossability
        : IRRIGATION_QUANTITY[area.quantity].crossability;
    },
    get canBeNonCrossible() {
      return IRRIGATION_QUANTITY[area.quantity].canBeNonCrossible;
    },
    get color() {
      let color = { r: 255, g: 0, b: 0 };
      if (!area.hasSelfIntersection && !area.hasIntersection) {
        color = IRRIGATION_QUANTITY[area.quantity].color;
      }

      return color;
    },
    get extremePoints() {
      let acc = [];

      if (area.isRectangle || area.isCircle) {
        acc.push(
          ...[
            rotatePoint(
              { x: area.x - area.width / 2, y: area.y - area.height / 2 },
              { x: area.x, y: area.y },
              area.startAngle
            ),
            rotatePoint(
              { x: area.x + area.width / 2, y: area.y - area.height / 2 },
              { x: area.x, y: area.y },
              area.startAngle
            ),
            rotatePoint(
              { x: area.x + area.width / 2, y: area.y + area.height / 2 },
              { x: area.x, y: area.y },
              area.startAngle
            ),
            rotatePoint(
              { x: area.x - area.width / 2, y: area.y + area.height / 2 },
              { x: area.x, y: area.y },
              area.startAngle
            ),
          ]
        );
      } else {
        acc.push(...area.points.map((p) => ({ x: p.x, y: p.y })));
      }

      return acc;
    },
    get isCircle() {
      return area.areaType === "circle";
    },
    get isRectangle() {
      return area.areaType === "rectangle";
    },
    get isFreeDrawing() {
      return !area.isCircle && !area.isRectangle;
    },
    get deleteConfirmText() {
      const lables = settingsState.texts.tools;
      if (area.isCircle) return lables.circle.delete;
      if (area.isRectangle) return lables.rectangle.delete;
      if (area.isFreeDrawing) return lables.freeDrawing.delete;
      return null;
    },
    //generate lines by free drawing area points
    get lines() {
      if (!area.isFreeDrawing || !area.points || area.points.length === 0) {
        return null;
      }
      return linesByPoints(area.points, area.enclosed, true);
    },
    //get SVG path
    get path() {
      const path = generateElementPath(area.toJSON);
      return path;
    },
    //(use only for debug)
    get polygonPath() {
      return generatePolygonPath(area.toJSON);
    },
    get pointsDirection() {
      return area.points && area.points.length > 3
        ? calculateSvgPointsDirection(
            area.points.filter((p) => !p.isControlPoint)
          )
        : -1;
    },
    get pointsCenter() {
      if (area.points && area.points.length > 3 && area.enclosed) {
        const p = area.points.filter((p) => !p.isControlPoint || !p.isDefault);
        //centroid of a polygon
        let sum = null;
        try {
          sum = p
            .slice()
            .concat([p[0]])
            .reduce(
              (acc, obj) => {
                const { x, y } = obj.isControlPoint
                  ? obj.bezierCurveCenterPoint
                  : obj;
                if (acc && acc.lpx && acc.lpy) {
                  const a = acc.lpx * y - x * acc.lpy;
                  return {
                    x: acc.x + (x + acc.lpx) * a,
                    y: acc.y + (y + acc.lpy) * a,
                    sa: acc.sa + a,
                    lpx: x,
                    lpy: y,
                  };
                } else return { ...acc, lpx: x, lpy: y };
              },
              { x: 0, y: 0, sa: 0, lpx: null, lpy: null }
            );
        } catch (e) {
          console.error(e);
        }
        return sum
          ? { x: sum.x / (6.0 * 0.5 * sum.sa), y: sum.y / (6.0 * 0.5 * sum.sa) }
          : null;
      }
      return undefined;
    },
    get isWatermarkCanBeShowDeprecated() {
      if (area.quantity === "should") {
        const watermarkCoords =
          area.isCircle || area.isRectangle ? area : area.pointsCenter;
        if (watermarkCoords) {
          const watermarkArea = {
            id: "watermark",
            x: watermarkCoords.x,
            y: watermarkCoords.y,
            width: 200,
            height: 40,
            startAngle: 0,
            areaType: "rectangle",
            points: [],
          };

          return area.isCircle || area.isRectangle
            ? !hasIntersection(area.toJSON, [watermarkArea], false) &&
                !pointInArea(
                  {
                    x: area.x - area.width / 2,
                    y: area.y - area.height / 2,
                  },
                  watermarkArea
                )
            : area.points && area.points.length > 0
            ? !pointInArea(
                {
                  x: area.points[0].x,
                  y: area.points[0].y,
                },
                watermarkArea
              )
            : true;
        }
      }
      return false;
    },
    get hasSelfIntersection() {
      return hasSelfIntersection(area.toJSON);
    },
    get hasIntersection() {
      let areas = this.otherAreas
        ? this.otherAreas.filter((other) => area.quantity === other.quantity)
        : null;
      let diffAreas = this.otherAreas
        ? this.otherAreas.filter(
            (other) =>
              (area.quantity === "should" && other.quantity === "no") ||
              (area.quantity === "no" && other.quantity === "should")
          )
        : null;
      return (
        hasIntersection(area.toJSON, areas) ||
        hasIntersection(area.toJSON, diffAreas, false)
      );
    },
    get isLawnArea() {
      return area.quantity && area.quantity === "should";
    },
    get isDriplineArea() {
      return area.quantity && area.quantity === "dripline";
    },
    //calculate area square
    get size() {
      let size = 0;
      if (area.isFreeDrawing) {
        const points = convertBezierToPoligon(area.points, 5).map(
          ({ x, y }) => {
            return {
              x: sizeInMetersByPixel(x, scale),
              y: sizeInMetersByPixel(y, scale),
            };
          }
        );
        size = polygonSquare(points);
      } else if (area.isRectangle) {
        size =
          sizeInMetersByPixel(area.width, scale) *
          sizeInMetersByPixel(area.height, scale);
      } else if (area.isCircle) {
        size =
          Math.PI *
          sizeInMetersByPixel(area.width / 2, scale) *
          sizeInMetersByPixel(area.height / 2, scale);
      }

      return size;
    },
    //calculate area perimeter in meter
    get perimeter() {
      const widthInMeter = sizeInMetersByPixel(area.width, scale);
      const heightInMeter = sizeInMetersByPixel(area.height, scale);

      let perimeter = 0;
      if (area.isFreeDrawing) {
        area.lines.forEach(
          ({ length }) => (perimeter += sizeInMetersByPixel(length, scale))
        );
      } else if (area.isRectangle) {
        perimeter = widthInMeter * 2 + heightInMeter * 2;
      } else if (area.isCircle) {
        perimeter =
          2 *
          Math.PI *
          Math.sqrt(
            (widthInMeter * widthInMeter + heightInMeter * heightInMeter) / 8
          );
      }

      return perimeter;
    },
  });

  // actions
  extendObservable(area, {
    area: action((angle) => {
      area.startAngle = angle;
    }),
    move: action((x, y) => {
      if (area.isCircle || area.isRectangle) {
        area.x = x;
        area.y = y;
      } else {
        area.points.forEach((point) => {
          point.x += x;
          point.y += y;
        });
      }
    }),
    setIrrigationQuantity: action((val) => {
      area.quantity = val;
      state.crossability = IRRIGATION_QUANTITY[val].crossability;
    }),
    toggleCrossability: action(() => {
      if (!area.canBeNonCrossible) return false;
      state.crossability = !state.crossability;

      return true;
    }),
    changeWidth: action((val) => {
      area.width = val && val > 0 ? val : 0.1;
    }),
    changeHeight: action((val) => {
      area.height = val && val > 0 ? val : 0.1;
    }),
    changeStartAngle: action((val) => {
      area.startAngle = val;
    }),
    changeDescription: action((val) => {
      area.description = val;
    }),
    onDisable: action(() => {
      area.disabled = !area.disabled;
    }),
    encloseArea: action(() => {
      if (!area.enclosed) {
        area.enclosed = true;
        if (area.points && area.points.length > 4) {
          //generate last bezier point
          const bezierPoint = areaPointFactory({
            type: "control-point",
          });
          area.points.push(bezierPoint);
        }
      }
    }),
    addPoint: action((data, idx) => {
      const point = areaPointFactory({
        ...data,
        type: "point",
      });
      if (area.points.length === 0) {
        area.points.push(point);
      } else {
        area.points.splice(
          idx + 1,
          area.enclosed ? 1 : 0,
          areaPointFactory({ type: "control-point" }),
          point
        );

        if (area.enclosed) {
          area.points.splice(
            idx + 3,
            0,
            areaPointFactory({ type: "control-point" })
          );
        }
      }
      //point.move(point.x, point.y + 10);
      return point;
    }),
    removePoint: action((idx) => {
      if (area.points && (!area.enclosed || area.points.length > 6)) {
        area.points.splice(idx, 2);
        const prevBezierIdx = idx - 1 > 0 ? idx - 1 : area.points.length - 1;
        if (area.enclosed || area.points.length > prevBezierIdx + 1) {
          area.clearBezierPoint(prevBezierIdx);
        } else {
          area.points.splice(prevBezierIdx, 1);
        }
      }
    }),
    clearBezierPoint: action((idx) => {
      area.points[idx].isDefault = true;
    }),
    movePoint: action((key, x, y) => {
      if (key < 0 || key >= area.points.length) {
        return;
      }
      const elem = area.points[key];
      if (elem) {
        elem.move(x, y);
      }
    }),
    shiftPoint: action((key, dx, dy) => {
      if (key < 0 || key >= area.points.length) {
        return;
      }
      const elem = area.points[key];
      if (elem) {
        area.movePoint(key, elem.x + dx, elem.y + dy);
      }
    }),
    snapToRightAngle: action((key) => {
      if (key < 0 || key >= area.points.length) {
        return;
      }
      const elem = area.points[key];
      if (
        elem &&
        !elem.isControlPoint &&
        elem.prevPoint &&
        elem.prevPoint.prevPoint
      ) {
        const p1 = elem.prevPoint.prevPoint;
        const p0 = p1.prevPoint;
        if (p1 && p0) {
          const angle = calculateAngleByPoints(p0, p1, elem);
          if (Math.abs((angle % 180) - 90) < 5) {
            const distance =
              Math.round(
                Math.sqrt((elem.x - p1.x) ** 2 + (elem.y - p1.y) ** 2) * 100
              ) / 200;
            let prevLineAngle = calculateAngleByLine(p0, p1);
            prevLineAngle =
              prevLineAngle < 0 ? (prevLineAngle % 360) + 360 : prevLineAngle;
            const direction =
              calculatePointsDirection([p0, p1, elem]) > 0 ? -1 : 1;
            let alpha = prevLineAngle + direction * 90;
            alpha = alpha < 0 ? (alpha % 360) + 360 : alpha;
            alpha = (alpha * Math.PI) / 180;
            area.movePoint(
              key,
              p1.x + 2 * distance * Math.cos(alpha),
              p1.y + 2 * distance * Math.sin(alpha)
            );
          }
        }
      }
    }),
    get bomItem() {
      return undefined;
    },
    get toJSON() {
      const {
        id,
        type,
        areaType,
        x,
        y,
        startAngle,
        width,
        height,
        quantity,
        crossability,
        description,
        enclosed,
        disabled,
      } = this;

      return {
        id,
        type,
        areaType,
        x,
        y,
        startAngle: startAngle ? startAngle % 360 : 0,
        width,
        height,
        quantity,
        points: area.points ? area.points.map((point) => point.toJSON) : null,
        crossability,
        description,
        enclosed,
        disabled,
      };
    },
  });

  return area;
};

export default areaFactory;
