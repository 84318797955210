import React from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useDraggable, useDroppable } from "react-draggable-hoc";

import {
  SystemElementPoint,
  NotConnectedToValvePoint,
  DriplinePoint,
  DefaultPoint,
  SpecificSprinklerElementPoint,
} from "./PipePoint.commons";

import { pixelSizeByZoom } from "../../core/planUtils";
import { hexToRgba } from "../../core/formatter";
import PipeContext from "./PipeContext";
import useDelayedAction from "../../hooks/useDelayedAction";

const PipePoint = ({
  x,
  y,
  zoomDelta,
  pointType,
  id,
  color,
  hidden,
  isNotConnectedToValve,
  selectedByPipe,
  selectableByPipe,
  move = () => {},
  isSelected,
  setSelectedElement,
  selectPoint,
  selectedTool,
  onAfter = () => {},
  draggable,
  disabled,
  dragDisabled,
  isNew,
  cleanupPoint,
  onRemoveElement,
  deletePoint,
  clearMouseLine,
  cannotAddedAlert = () => {},
  driplineValvesCount,
  useDefaultStyles = true,
  isHoveredElement,
  setHoveredElementId = () => {},
  isSystemElementPoint,
  isRzwsPoint,
  isRaisedBedPoint,
}) => {
  const pipeContext = React.useContext(PipeContext);

  const defaultRef = React.useRef();

  const [changeDelayedAction] = useDelayedAction();

  const dragStartListener = () => {
    if (selectedTool !== "pipeline-add") {
      setSelectedElement(id);
    }
  };

  const dropListener = (state) => {
    const listener = () => {
      const { mode } = pipeContext;
      pipeContext.mode = "chain";

      if (mode === "chain") {
        if (selectableByPipe) {
          const result = selectPoint(id);
          if (isNew) {
            if (result && result === "rejected") {
              deletePoint(id);
            } else if (!result) {
              onAfter();
            }
          }
        } else {
          if (isNew) {
            onAfter();
          }
          // delay clearMouseLine due to mousemove triggered after touchend
          changeDelayedAction(() => {
            cannotAddedAlert();
            clearMouseLine();
          });
        }
      } else if (!dragDisabled && (state.deltaX !== 0 || state.deltaY !== 0)) {
        onAfter();
      }
      cleanupPoint();
    };

    changeDelayedAction(listener);
  };

  const dragListener = (state) => {
    changeDelayedAction(undefined);
    if (
      pipeContext.mode !== "move" &&
      [state.deltaX, state.deltaY].some((delta) => Math.abs(delta) > 2)
    ) {
      pipeContext.mode = "move";
    }
  };

  const draggableProps = useDraggable(defaultRef, {
    onDragStart: dragStartListener,
    onDrop: dropListener,
    onDrag: dragListener,
    dragProps: id,
    disabled,
  });

  React.useEffect(() => {
    const node = defaultRef && defaultRef.current;
    if (node == null) return;

    const listener = () => {
      changeDelayedAction(undefined);
      onRemoveElement(id, pointType === "t-point");
    };

    node.addEventListener("dblclick", listener);

    return () => {
      node.removeEventListener("dblclick", listener);
    };
  });

  const { isDragged } = draggableProps;
  const deltaX = dragDisabled ? 0 : draggableProps.deltaX;
  const deltaY = dragDisabled ? 0 : draggableProps.deltaY;

  const deltas = React.useRef({ deltaX, deltaY });

  React.useEffect(() => {
    if (isDragged) {
      const dx = deltaX - deltas.current.deltaX;
      const dy = deltaY - deltas.current.deltaY;
      if (dx !== 0 || dy !== 0) {
        move({
          x,
          y,
          deltaX: zoomDelta * dx,
          deltaY: zoomDelta * dy,
        });
      }
    }
    deltas.current = { deltaX, deltaY };
  });

  const ref = React.useRef();
  useDroppable(ref, {
    onDrop: async ({ dragProps }) => {
      if (dragProps == null) console.error("Invalid dragged");
      if (dragProps === "container" && selectableByPipe) {
        selectPoint(id);
      }
    },
    method: (state, r) => {
      const node = r.current;
      let res = false;
      if (state.current && node) {
        res = document
          .elementsFromPoint(state.current.x, state.current.y)
          .some((n) => node.contains(n));
      }

      return res;
    },
  });

  const allowActive =
    !draggable && (selectedTool !== "pipeline-add" || selectableByPipe);
  const active = isSelected || selectedByPipe;

  let Component = null;
  if (isSystemElementPoint) {
    Component = SystemElementPoint;
  } else if (isRzwsPoint || isRaisedBedPoint) {
    Component = SpecificSprinklerElementPoint;
  } else if (isNotConnectedToValve) {
    Component = NotConnectedToValvePoint;
  } else if (pointType === "dripline-point") {
    Component = DriplinePoint;
  }

  const componentProps = {
    hidden,
    driplineValvesCount,
    isNotConnectedToValve,
    zoomDelta,
    active,
    useDefaultStyles,
    color,
    pointType,
    ref: defaultRef,
    isHoveredElement,
  };

  return (
    <g
      className={`draggable element pipe-point ${
        !allowActive ? "disabled" : ""
      } ${active ? "active" : ""}`}
      transform={`translate(${x}, ${y})`}
      fill="#fff"
      ref={ref}
      stroke="#3e4346"
      strokeWidth={pixelSizeByZoom(1, zoomDelta)}
      onMouseEnter={() => {
        setHoveredElementId(id);
      }}
      onMouseLeave={() => {
        setHoveredElementId(undefined);
      }}
    >
      {Component ? (
        <Component {...componentProps} />
      ) : (
        <DefaultPoint
          {...componentProps}
          color={
            selectedTool !== "pipeline-add" ||
            selectableByPipe ||
            selectedByPipe ||
            isSelected
              ? color
              : hexToRgba(color, 0.8)
          }
        />
      )}
    </g>
  );
};

export default observer(
  ({
    element,
    selectPoint,
    clearMouseLine = () => {},
    cannotAddedAlert = () => {},
    onSave,
    scale,
    zoomDelta,
    planIsEditable,
    selectedTool,
    selectedOtherTool,
    selectedElementId,
    hoveredElementId,
    setSelectedElement = () => {},
    onRemoveElement = () => {},
    removeElementById = () => {},
    ...props
  }) => (
    <PipePoint
      id={element.id}
      x={element.x}
      y={element.y}
      key={element.id}
      disabled={selectedOtherTool === "draggable"}
      dragDisabled={
        element.disabled || element.isConnectionPoint || !planIsEditable
      }
      isNotConnectedToValve={element.isNotConnectedToValve}
      isSystemElementPoint={element.isSystemElementPoint}
      isRzwsPoint={element.isRzwsPoint}
      isRaisedBedPoint={element.isRaisedBedPoint}
      pointType={element.pointType}
      move={({ x, y, deltaX, deltaY }) => {
        element.move(x + deltaX, y + deltaY);
        clearMouseLine();
      }}
      selectableByPipe={
        selectedTool === "pipeline-add" &&
        element.selectableByPipe &&
        !element.isDriplinePoint
      }
      selectedByPipe={selectedTool === "pipeline-add" && element.selected}
      selectPoint={selectedTool === "pipeline-add" ? selectPoint : () => {}}
      selectedTool={selectedTool}
      onAfter={action(() => {
        element.lazyFixPointType();
        onSave();
      })}
      setSelectedElement={setSelectedElement}
      isSelected={selectedElementId && element.id === selectedElementId}
      isHoveredElement={hoveredElementId && element.id === hoveredElementId}
      isNew={element.isNew}
      cleanupPoint={() => {
        delete element.isNew;
      }}
      onRemoveElement={(...elemProps) => {
        clearMouseLine();
        onRemoveElement(...elemProps);
      }}
      deletePoint={
        planIsEditable
          ? (id) => {
              removeElementById(id);
            }
          : () => {}
      }
      zoomDelta={zoomDelta}
      driplineValvesCount={
        element.pointType === "dripline-point"
          ? element.driplineValvesCount
          : null
      }
      clearMouseLine={clearMouseLine}
      cannotAddedAlert={cannotAddedAlert}
      {...props}
    />
  )
);
