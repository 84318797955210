import React from "react";
import { useIntl } from "react-intl";
import { timeFormat } from "../../core/formatter";

import { ReactComponent as SaveSVG } from "../../assets/save-icon.svg";
import { ReactComponent as SaveDisconnectSVG } from "../../assets/save-disconnect.svg";

function SavingInfo({ saveStatus, lastSaveDate, labels }) {
  const { formatMessage } = useIntl();

  const { Icon, label } = React.useMemo(() => {
    switch (saveStatus) {
      case "saving":
        return {
          Icon: SaveSVG,
          label: labels
            ? formatMessage({
                id: labels.savingLabel,
              })
            : "saving",
        };
      case "disconnect":
        return {
          Icon: SaveDisconnectSVG,
          label: lastSaveDate ? timeFormat(lastSaveDate) : null,
        };
      default:
        return {
          Icon: SaveSVG,
          label: lastSaveDate ? timeFormat(lastSaveDate) : null,
        };
    }
  }, [formatMessage, labels, lastSaveDate, saveStatus]);

  if (saveStatus == null || Icon == null) return null;

  return (
    <span className={`right-item save-status ${saveStatus}`}>
      <i>
        <Icon />
      </i>
      <span>
        {label ??
          (labels
            ? formatMessage({
                id: labels.notSavedLabel,
              })
            : "plan not saved")}
      </span>
    </span>
  );
}

export default SavingInfo;
