import React from "react";
import { observer } from "mobx-react";

import { FreeDrawingAreaControl } from "./AreaControl";
import Draggable from "../Draggable";
import { Watermark } from "./Watermark";

import {
  pixelSizeByZoom,
  sizeInMetersByPixel,
  svgTextCondensed,
} from "../../core/planUtils";
import { calculateTextsPositions, remToPx } from "../../utils/uiUtils";
import { toFixedPrecision } from "../../core/formatter";
import { useIntl } from "react-intl";

const PolygonArea = ({
  id,
  points,
  lines,
  movePoint,
  shiftPoint,
  path,
  pointsCenter,
  watermarkCanBeShow,
  color,
  move,
  scale,
  zoomDelta,
  disabled,
  setSelectedElement = () => {},
  isSelected,
  clearBezierPoint,
  removePoint,
  addPoint,
  precision,
  filter,
  enclosed = true,
  disableClick = () => {},
  pointsDirection,
  onChange = () => {},
  useDefaultStyles,
  textsVisibility,
}) => {
  const { formatNumber } = useIntl();

  const [dragDisabled, changeDragDisabled] = React.useState(false);

  React.useEffect(() => {
    disableClick(dragDisabled);
  }, [dragDisabled, disableClick]);

  const texts = [];
  const textPositions = calculateTextsPositions(points, pointsDirection);

  if (textsVisibility && textsVisibility.areaTexts) {
    if (lines && lines.length > 0) {
      //lines
      lines.forEach((line, i) => {
        if (line.length) {
          const textPosition = textPositions[2 * i + 1];
          texts.push(
            <g
              key={`line-text-${i}`}
              style={{
                fontSize: pixelSizeByZoom(remToPx(0.75), zoomDelta),
                letterSpacing: pixelSizeByZoom(0.6, zoomDelta),
                fontWeight: "bold",
              }}
            >
              <g
                transform={`translate(${
                  textPosition.x + pixelSizeByZoom(textPosition.dx, zoomDelta)
                }, ${
                  textPosition.y + pixelSizeByZoom(textPosition.dy, zoomDelta)
                })`}
              >
                <text
                  x="0"
                  y="0"
                  dominantBaseline={
                    textPosition.dy <= 0 ? "baseline" : "hanging"
                  }
                  textAnchor={
                    textPosition.dx < 0
                      ? "end"
                      : textPosition.dx > 0
                      ? "start"
                      : "middle"
                  }
                  {...svgTextCondensed}
                >
                  {" "}
                  {formatNumber(
                    toFixedPrecision(
                      sizeInMetersByPixel(line.length, scale),
                      precision
                    )
                  )}{" "}
                  m
                </text>
              </g>
            </g>
          );
        }
      });
    }

    if (points && points.length > 0) {
      points.forEach((point, i) => {
        if (point.angle != null) {
          const textPosition = textPositions[i];
          texts.push(
            <g
              key={`line-angle-text-${i}`}
              style={{
                fontSize: pixelSizeByZoom(remToPx(0.75), zoomDelta),
                letterSpacing: pixelSizeByZoom(0.6, zoomDelta),
                fontWeight: "normal",
              }}
            >
              <g
                transform={`translate(${
                  textPosition.x + pixelSizeByZoom(textPosition.dx, zoomDelta)
                }, ${
                  textPosition.y + pixelSizeByZoom(textPosition.dy, zoomDelta)
                })`}
              >
                <text
                  x="0"
                  y="0"
                  dominantBaseline={"middle"}
                  textAnchor={"middle"}
                  {...svgTextCondensed}
                >
                  {formatNumber(toFixedPrecision(point.angle, 0))}°
                </text>
              </g>
            </g>
          );
        }
      });
    }
  }
  return (
    <Draggable
      id={id}
      move={move}
      draggable={!disabled}
      cloneable={!disabled}
      onAfterMove={onChange}
      dragProps={id}
      onClick={() => {
        setSelectedElement(id);
      }}
    >
      <g
        className={`element area ${isSelected ? "active" : ""}`}
        onClickCapture={() => {
          setSelectedElement(id);
        }}
        filter={filter}
      >
        <path
          d={path}
          fill={enclosed ? `rgb(${color.r}, ${color.g}, ${color.b})` : "none"}
          fillOpacity="0.25"
          stroke={`rgba(${color.r}, ${color.g}, ${color.b}, 1)`}
          strokeWidth={
            textsVisibility && !textsVisibility.areaBorder
              ? 0
              : pixelSizeByZoom(
                  useDefaultStyles ? 1 : isSelected || !enclosed ? 4 : 2,
                  zoomDelta
                )
          }
        />
        {textsVisibility &&
          textsVisibility.areaWatermark &&
          watermarkCanBeShow &&
          pointsCenter && (
            <Watermark
              transform={`translate(${pointsCenter.x} ${pointsCenter.y}) scale(${zoomDelta})`}
            />
          )}
        {texts ? texts : null}
        {((isSelected && !disabled) || !enclosed) && (
          <FreeDrawingAreaControl
            onAfter={onChange}
            move={movePoint}
            disableMove={changeDragDisabled}
            shift={shiftPoint}
            points={points}
            lines={lines}
            color={color}
            clearBezierPoint={clearBezierPoint}
            removePoint={removePoint}
            addPoint={addPoint}
            zoomDelta={zoomDelta}
            scale={scale}
          />
        )}
      </g>
    </Draggable>
  );
};

export default observer(
  ({
    element,
    getSvgPoint,
    clearMouseLine = () => {},
    onChange = () => {},
    planIsEditable = true,
    selectedElementId,
    ...props
  }) => (
    <PolygonArea
      id={element.id}
      color={element.color}
      size={element.size}
      move={element.move}
      points={element.points}
      disabled={element.disabled || !planIsEditable}
      enclosed={element.enclosed}
      lines={element.lines}
      hasSelfIntersection={element.hasSelfIntersection}
      hasIntersection={element.hasIntersection}
      pointsDirection={element.pointsDirection}
      path={element.path}
      pointsCenter={element.pointsCenter}
      watermarkCanBeShow={element.isWatermarkCanBeShow}
      movePoint={(key, x, y) => {
        element.movePoint(key, x, y);
        clearMouseLine();
      }}
      shiftPoint={(key, x, y) => {
        element.shiftPoint(key, x, y);
        clearMouseLine();
      }}
      clearBezierPoint={(idx) => {
        element.clearBezierPoint(idx);
        clearMouseLine();
      }}
      removePoint={(idx) => {
        element.removePoint(idx);
        clearMouseLine();
      }}
      addPoint={(e, idx) => {
        let point = getSvgPoint(e);
        if (point) element.addPoint({ x: point.x, y: point.y }, idx);
      }}
      onChange={element.enclosed ? onChange : () => {}}
      isSelected={element.id === selectedElementId}
      {...props}
    />
  )
);
