import logo from '../../assets/img/rainshift_logo_big.png';
import loginPage from '../../assets/img/loginPage.png';


import {Link, redirect} from "react-router-dom";

import {FormattedMessage,useIntl} from "react-intl";
import {useEffect, useState} from "react";
import {observer} from "mobx-react";
import axios from "axios";
import {toast} from "react-toastify";

import {Spinner} from "react-bootstrap";
import {config} from "../../config";
import useStores from "../../hooks/useStores";

export const  login = observer(()  => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const intl = useIntl();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loading, setLoading] = useState(false)
    const [ form, setForm] = useState({
        password: null,
        email: null
    })
    const { uiState } = useStores();
    useEffect(() => {
        document.body.classList = "geex-dashboard authentication-page";
    }, []);
   const loginAction = async (e) => {
       setLoading(true)

       e.preventDefault();
       try{
           const { data } = await axios.post(config.apiUrl+'/auth/login', form);
           await uiState.user.login(data);
           redirect('/dashboard')
       }catch (e) {
           console.log(e)
           let message = 'global.error';
           if(e){
                if(e.response){
                    if(e.response.status === 400){
                        if(e.response.data){
                            message = e.response.data.message[0].msg
                        }
                    }
                }
           }
           toast.error(intl.formatMessage({
               id:message,
               defaultMessage:'An error occurred please try again'
           }))
       }

       setLoading(false)
   }

    return (<main className="geex-content">
        <div className="geex-content__authentication">
            <div className="geex-content__authentication__content">
                <div className="geex-content__authentication__content__wrapper">
                    <div className="geex-content__authentication__content__logo">
                        <Link to={'/'}>
                            <img src={logo} alt="logo" width="450"/>
                        </Link>
                    </div>

                    <form className="geex-content__authentication__form"  onSubmit={loginAction} autoComplete="off">
                        <h2 className="geex-content__authentication__title"><FormattedMessage id="login.welcome" defaultMessage="Welcome to RainShift Admin Panel"/></h2>
                        <p className="mb-4 font-sm">
                            <FormattedMessage id="login.description" defaultMessage="Please enter your username and password to login"/>

                        </p>

                        <div className="geex-content__authentication__form-group">
                            <div className="geex-content__form__single__box mb-20">

                                <input id="email" onChange={(e) => {
                                    setForm({
                                        ...form,
                                        email: e.target.value
                                    })
                                }} placeholder={intl.formatMessage({
                                    id: 'login.email_placeholder',
                                    defaultMessage: 'Please enter e email'
                                })} type="email" className="form-control mb-2" name="email" autoFocus/>

                            </div>
                        </div>
                        <div className="geex-content__authentication__form-group">
                            <div className="geex-content__form__single__box mb-20">

                                <input id="password" onChange={(e) => {
                                    setForm({
                                        ...form,
                                        password: e.target.value
                                    })
                                }} type="password" placeholder={intl.formatMessage({
                                    id: 'login.password_placeholder',
                                    defaultMessage: 'Please enter your password'
                                })} className="form-control mb-2"/>
                            </div>
                        </div>
                        <div className="form-group justify-content-center">

                            <button type="submit" className="geex-btn geex-btn--primary text-center" disabled={loading}>
                                {
                                    loading ? (
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span> <FormattedMessage id="system.loading"
                                                                     defaultMessage="Loading..."/></span>
                                        </>
                                    ) : (
                                        <FormattedMessage id="login.button" defaultMessage="Login"/>
                                    )
                                }
                            </button>


                        </div>
                    </form>
                </div>
            </div>


            <div className="geex-content__authentication__img" style={{
                padding: 0,
                margin: 0,
                width: "100%",
                height: "100vh",
                display: "flex"
            }}>
                <img src={loginPage} alt=""
                     style={{
                         width: "100%",
                         height: "100%",
                         objectFit: "cover",
                         padding: 0,
                         margin: 0
                     }}
                     />
            </div>


        </div>
    </main>);
});
