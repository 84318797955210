import { useIntl } from "react-intl";
import WindowPopup from "../popups/WindowPopup";
import Scroll from "../elements/Scroll";
import Button from "../elements/Button";
import { calculateTotalPrice, normalizeBomItems } from "../../utils/bomUtils";
import { observer } from "mobx-react";
import { priceFormat } from "../../core/formatter";
import bomItemFactory from "../../store/types/bomItemFactory";
import useStores from "../../hooks/useStores";

const textsByType = {
  eco: {
    titleId: `texts.steps.bomType.eco.title`,
    subtitleId: undefined,
    descriptionId: `texts.steps.bomType.eco.description`,
    advantagesId: `texts.steps.bomType.eco.advantages`,
    buttonTextId: `texts.steps.bomType.eco.button`,
  },
  expert: {
    titleId: `texts.steps.bomType.expert.title`,
    subtitleId: `texts.steps.bomType.expert.subtitle`,
    descriptionId: `texts.steps.bomType.expert.description`,
    advantagesId: `texts.steps.bomType.expert.advantages`,
    buttonTextId: `texts.steps.bomType.expert.button`,
  },
  premium: {
    titleId: `texts.steps.bomType.premium.title`,
    subtitleId: undefined,
    descriptionId: `texts.steps.bomType.premium.description`,
    advantagesId: `texts.steps.bomType.premium.advantages`,
    buttonTextId: `texts.steps.bomType.premium.button`,
  },
};

const BomTypeItem = ({
  className,
  title,
  subtitle,
  price,
  description,
  advantages,
  buttonText,
  onClick,
}) => {
  return (
    <div className={["item", className].filter((e) => e).join(" ")}>
      <div className="header">
        <div className="title">{title}</div>
        {subtitle && <div className="subtitle">{subtitle}</div>}
      </div>
      <div className="content">
        <div className="price-wrapper">
          <div className="price">{price}</div>
          <div className="description">{description}</div>
        </div>

        <div className="advantages">
          <ul>
            {advantages.map((text) => (
              <li key={text}>{text}</li>
            ))}
          </ul>
        </div>

        <div className="button-wrapper">
          <Button type="button" title={buttonText} onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

let BomType = () => {
  const intl = useIntl();
  const { formatMessage } = useIntl();
  const { uiState } = useStores();

  const calcualteTotalPriceByType = (type) => {
    const tempBomItems = normalizeBomItems(
      uiState.plan.bomItems.map((item) => item.toJSON),
      uiState.settingsState
    ).map((data) => bomItemFactory(data, uiState.plan.pricesDictionary));

    uiState.plan.applyBomType(type, tempBomItems);
    return calculateTotalPrice(tempBomItems);
  };

  return (
    <WindowPopup
      wrapperClassName="recom-wrapper"
      title={formatMessage({ id: "texts.steps.bomType.title" })}
      className="bom-type"
    >
      <Scroll className="bom-type-content scroll">
        <div className="bom-type-list">
          {Object.entries(textsByType).map(
            ([
              type,
              {
                titleId,
                subtitleId,
                descriptionId,
                advantagesId,
                buttonTextId,
              },
            ]) => (
              <BomTypeItem
                key={type}
                className={type}
                title={formatMessage({ id: titleId })}
                subtitle={
                  subtitleId ? formatMessage({ id: subtitleId }) : undefined
                }
                price={formatMessage(
                  { id: "texts.steps.bomType.price" },
                  { price: priceFormat(intl, calcualteTotalPriceByType(type)) }
                )}
                description={formatMessage({ id: descriptionId })}
                advantages={formatMessage({ id: advantagesId }).split("\n")}
                buttonText={formatMessage({ id: buttonTextId })}
                onClick={() => {
                  uiState.plan.changeBomType(type);
                  uiState.savePlan();
                  uiState.sendMarketingStatistics();
                }}
              />
            )
          )}
        </div>
      </Scroll>
    </WindowPopup>
  );
};
BomType = observer(BomType);

export default BomType;
