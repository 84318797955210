import { extendObservable } from "mobx";

import { round, priceFormat } from "../../core/formatter";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state, intl) => {
  extendObservable(state, {
    get toMarketingJSON() {
      const { planId, user, planSize, plan } = state;
      const { planningTime, maxStep, createdAt, isNotify } = plan || {};

      const areaOfLawn = (plan.areas || [])
        .filter((area) => area.isLawnArea)
        .reduce((acc, area) => acc + round(area.size, 0), 0);

      const areaOfFlowerbed = (plan.areas || [])
        .filter((area) => area.isDriplineArea)
        .reduce((acc, area) => acc + round(area.size, 0), 0);

      const totalAmountInBOM =
        plan?.bomType != null
          ? (plan.bomItems || []).reduce(
              (acc, bom) => acc + +priceFormat(null, bom.price),
              0
            )
          : 0;

      const createdAtDate = createdAt ? new Date(createdAt) : undefined;

      return {
        emailEnabled: isNotify ? true : false,
        planId: planId,
        username: user?.name,
        step: maxStep,
        planWidth: planSize ? Math.ceil(planSize.width) : undefined,
        planHeight: planSize ? Math.ceil(planSize.height) : undefined,
        planArea: areaOfLawn + areaOfFlowerbed,
        sprinklersCount: (plan.sprinklers || []).length,
        lawnArea: areaOfLawn,
        flowerbedArea: areaOfFlowerbed,
        bomTotal: totalAmountInBOM ? Math.ceil(totalAmountInBOM) : undefined,
        timeSpent: planningTime ? planningTime : undefined,
        createdAt: createdAtDate?.toISOString(),
      };
    },
  });

  extendObservable(state, {
    sendMarketingStatistics: () => {
      if (
        state.planId == null ||
        state.user?.email == null ||
        window.sendinblue == null
      )
        return;

      const data = state.toMarketingJSON;

      window.sendinblue?.track(
        "step_changed",
        {
          email: state.user.email,
        },
        {
          data: data || {},
        }
      );
    },
  });
};
