import axios from "axios";
import {config} from "../config";

export const getUserInfo = async () => {
    try {
        const token = localStorage.getItem('authKey');
        const request = await axios.get(`${config.apiUrl}/auth/control`, {
            headers: {
                'x-access-token': token,
            }
        });
        return request.data
    } catch (e) {

        throw e
    }
};


export const createPlan = async (name, plan) => {
    try{
        const token = localStorage.getItem('authKey');
        const imageBase64 =
            plan.background && plan.background.src ? plan.background.src : null

        const imageType = imageBase64?.match(/^data:([/\w]+);/)

        const request = await axios.post(`${config.apiUrl}/plan/create-plan`,{
            name,
            image: imageBase64,
            image_type: imageType != null ? imageType[1] : null,
        }, {
            headers: {
                'x-access-token': token,
                'x-access-path': 'add-plan'
            }
        });

        return request.data;
    } catch (e) {
        console.error(e)
        throw e
    }
};


export const savePlan = async (id, name, planJSON, saveStatus) => {
    if (planJSON == null) {
        throw new Error("Invalid parameter value")
    }

    try{
        const token = localStorage.getItem('authKey');
        const request = await axios.post(config.apiUrl+'/plan/update-plan',{
            ID: id,
            Name: name,
            Status: saveStatus,
            PlanningTime: planJSON.planningTime,
            Data: JSON.stringify(planJSON),
        }, {
            headers: {
                'x-access-token': token,
                'x-access-path': 'update-plan'
            }
        });
        return request.data;

    } catch (e) {
        console.error(e)
        throw e
    }
};


export const getPlanById = async id => {
    if (id == null) {
        throw new Error("Invalid parameter value")
    }

    try {
        const token = localStorage.getItem('authKey');
        const request = await axios.get(`${config.apiUrl}/plan/get-plan/${id}`, {
            headers: {
                'x-access-token': token,
                'x-access-path': 'get-plan'
            }
        });
        const {
            Json: dataStr,
            Image:image,
            ImageType:image_type,
            ID:ident,
            Name:name,
            Status: planStatus,
            AddDate:created_at,
        } = request.data

        if(!dataStr){
            return;
        }
        const data = JSON.parse(dataStr)
        if (planStatus != null) data.status = planStatus

        return {
            name,
            data,
            image,
            image_type,
            ident,
            created_at
        }


    } catch (e) {

        throw e
    }
};