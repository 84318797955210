import {FormattedMessage} from "react-intl";

export const Loader = () => {
    return (
        <div className="loader text-center">
            <div className="loader-inner">

                <div className="lds-roller mb-3">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

                <h4 className="text-uppercase font-weight-bold"><FormattedMessage id="system.loading" defaultMessage="Loading..."/></h4>

            </div>
        </div>
    )
}