import { action, extendObservable, observable } from "mobx";
import {getUserInfo} from "../utils/rest";

const userStateFactory = () => {
  const state = observable({
    control: false,
    auth: false,
    fullName: '',
    email: '',
    id: '',
    isRoot: false,
    firstName: '',
    lastName: '',
  });

  extendObservable(state, {
    fetched: false,
    logout: action( ()  => {
      state.name = '';
      state.auth = false;
      state.email = '';
      state.customer_id ='';
      state.isRoot = '';
      state.firstName = '';
      state.lastName  = '';
      localStorage.removeItem('authKey')
    }),
    login: action( (data)  => {

      const {
        user:{
          fullName,
          email,
          id,
          isRoot,
          lastName,
          firstName
        },
        jwt
      } = data;


      state.name = fullName;
      state.auth = true;
      state.email = email;
      state.customer_id = id;
      state.isRoot = isRoot;
      state.firstName = firstName;
      state.lastName  = lastName;
      // JWT'yi saklayın
      localStorage.setItem('authKey', jwt);

    }),
    fetch: action(async () => {
      try {

        const response = await getUserInfo();


        const {
          user:{
            fullName,

            email,
            id,
            isRoot,
            lastName,
            firstName
          },
          jwt
        } = response;

        action(() => {
          state.fetched = true;
          state.control = true;
          state.auth = true;
          state.name = fullName;
          state.email = email;
          state.customer_id = id;
          state.isRoot = isRoot;
          state.firstName = firstName;
          state.lastName  = lastName;
          // JWT'yi saklayın
          localStorage.setItem('authKey', jwt);
        })();

      } catch (e) {
        action(() => {
          state.auth = false;
          state.fetched = true;
          state.control = true;
        })();
      } finally {
        action(() => {
          state.fetched = true;
          state.control = true;
        })();
      }
    }),
  });

  return state;
};

export default userStateFactory;
