import * as React from "react";
import { useIntl } from "react-intl";
import WindowPopup from "../WindowPopup";
import { observer } from "mobx-react";
import { ReactComponent as ArrowRightSVG } from "./../../../assets/arrow-right.svg";

let PlanForm = ({ children, prev, next, title }) => {
  const { formatMessage } = useIntl();

  return (
    <WindowPopup title={title} className="plan">
      {children}
      <div style={{ flex: "auto" }} />
      <div className="window-popup-controls">
        {prev != null && (
          <button type="button" onClick={prev}>
            <ArrowRightSVG
              width={15}
              height={30}
              style={{ transform: "rotate(-180deg)" }}
            />
            <span>{formatMessage({ id: "texts.steps.previous" })}</span>
          </button>
        )}

        <div style={{ flex: "auto" }} />

        {next != null && (
          <button type="button" onClick={next}>
            <span>{formatMessage({ id: "texts.steps.next" })}</span>
            <ArrowRightSVG width={15} height={30} />
          </button>
        )}
      </div>
    </WindowPopup>
  );
};
PlanForm = observer(PlanForm);

export default PlanForm;
