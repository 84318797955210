import { calculateTotalCircuitsCount, maxOfArray } from "./common-utils"

function controllerKits(settings, plan) {
  const controllerElement = plan.systemElements.find(
      e => e.systemType === "controller"
  )
  const { bomList, elements } = settings
  const { irrigationController: controllerList } = elements

  if (controllerElement == null) return []

  const controllerTypes =
      controllerList.find(item => item.value === controllerElement.controllerType)
          ?.types ?? []

  if (controllerTypes.length === 0) {
    throw new Error(`Controller ${controllerElement.controllerType} not found`)
  }

  const circuitsCount = calculateTotalCircuitsCount(plan)

  let quantity = 1

  let bomByCircuits = controllerTypes.find(({ minCircuits, maxCircuits }) => {
    return circuitsCount >= minCircuits && circuitsCount <= maxCircuits
  })

  if (bomByCircuits == null) {
    bomByCircuits = maxOfArray(controllerTypes, "maxCircuits")
    if (bomByCircuits == null) {
      throw new Error("Max controller not found")
    }

    quantity = Math.ceil(bomByCircuits.maxCircuits / circuitsCount)
  }

  if (bomByCircuits == null) {
    throw new Error(`Controller for ${circuitsCount} circuits not found`)
  }

  const bomItems = [
    {
      bomId: bomByCircuits.bomId,
      qualityList: bomList[bomByCircuits.bomId],
      quantity
    }
  ]

  return bomItems
}

export { controllerKits }
