import { useIntl } from "react-intl";
import { observer } from "mobx-react-lite";



import { availableStatuses } from "../../store/planFactory/injectPlanSerialization";

import Steps from "./steps/Steps";
import Logo from "./Logo";


import HeaderButton from "./HeaderButton";
import DownloadCsvButton from "./DownloadCsvButton";
import LockButton from "./LockButton";
import SavingInfo from "./SavingInfo";

import { ReactComponent as AvatarSVG } from "../../assets/avatar.svg";
import { ReactComponent as CheckedSVG } from "../../assets/checked.svg";

import { ReactComponent as HelperSVG } from "../../assets/helper.svg";
import { ReactComponent as SmartGardenSVG } from "../../assets/smart-garden.svg";
import useStores from "../../hooks/useStores";
import HeaderDropdown from "../elements/HeaderDropdown";
import HeaderSprinkleDropdown from "../elements/HeaderSprinkleDropdown";

let Header = () => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const { uiState } = useStores();

  const {
    planId,
    user: _user,
    plan,
    planName,
    setPlanName,
    stepIdx: currentStep,
    settingsState,
    updatePlan,
    saveStatus,
    lastSaveDate,
    stepIdxByName,

    planChecked,
  } = uiState;

  const user = _user?.auth ? _user : undefined;
  const labels = settingsState.texts.header;



  return (
      <header>
        <div style={{display: 'flex', gap: '30px', alignItems: 'center'}}>
          <Logo
              planName={planName}
              setPlanName={plan != null ? setPlanName : undefined}
              updatePlan={updatePlan}
          />
          <HeaderDropdown/>
          <HeaderSprinkleDropdown/>
        </div>


          <Steps/>
          <div className="right">


            {currentStep === stepIdxByName("recommendations") && (
                <DownloadCsvButton
                    plan={plan}
                    planName={planName}
                    settingsState={settingsState}
                    pricesDictionary={plan.pricesDictionary}
                />
            )}

            {(availableStatuses.ToCheck === plan?.status ||
                    availableStatuses.DuplicateToCheck === plan?.status) &&
                currentStep === stepIdxByName("recommendations") && (
                    <HeaderButton
                        onClick={planChecked}
                        Icon={CheckedSVG}
                        label={formatMessage({id: labels.checkedLabel})}
                    />
                )}



            {
              //IPAT-412 Implement manual locking against unintended modification
              //hasAdmin &&
                plan && (
                    <LockButton
                        isLocked={!plan?.editable}
                        lock={async () => {
                          plan.lock();
                          await updatePlan();
                        }}
                        unlock={async () => {
                          plan.unlock();
                          await updatePlan();
                        }}
                        labels={labels}
                    />
                )
            }

            <HeaderButton
                href={
                  settingsState
                      ? formatMessage({id: settingsState.links.helperURL})
                      : "/"
                }
                Icon={HelperSVG}
                label={labels ? formatMessage({id: labels.helperLabel}) : undefined}
                target="_blank"
                rel="noopener noreferrer"
            />

            <SavingInfo
                labels={labels}
                lastSaveDate={lastSaveDate}
                saveStatus={saveStatus}
            />

            <HeaderButton
                className="user"
                href={
                  settingsState ? settingsState.links.logoutURL : "/"
                }
                Icon={AvatarSVG}
                label={user?.name}
            />
          </div>
      </header>
);
};
Header = observer(Header);

export default Header;
