import { sizeInMetersByPixel } from "../../core/planUtils"
import { round } from "../../core/formatter"
import { findFittingByTubeType, maxOfArray } from "./common-utils"

function _findPipesAndConnectors(settings, tubeType, length) {
  length = Math.max(round(length, 1, "ceil"), 0.1)

  let bomItems = []

  const { bomList, elements } = settings
  const { pressureTubing, straightConnector } = elements

  let tubingQuantity = 1

  const tubeByTypes = pressureTubing.filter(tube => tube.type === tubeType)

  // !!! tube type not used
  let lineBom = tubeByTypes.find(
      tube => length >= tube.minLength && length <= tube.maxLength
  )
  if (lineBom == null) {
    lineBom = maxOfArray(tubeByTypes, "maxLength")
    if (lineBom == null) {
      throw new Error("Max tube not found")
    }
    tubingQuantity = Math.ceil(length / lineBom.maxLength)
  }

  if (lineBom == null) {
    throw new Error("Pressure tubing not found")
  }

  bomItems.push({
    bomId: lineBom.bomId,
    qualityList: bomList[lineBom.bomId],
    quantity: tubingQuantity
  })

  if (tubingQuantity > 1) {
    const connector = findFittingByTubeType(tubeType, straightConnector)
    bomItems.push({
      bomId: connector.bomId,
      qualityList: bomList[connector.bomId],
      quantity: tubingQuantity - 1
    })
  }

  return bomItems
}

function pipesAndFittingsForPressureTubing(settings, plan) {
  const { bomList, elements } = settings
  const {
    pressureTubingKitWithWaterTap,
    pressureTubingKitWithBlanking,
    pressureTubingLPoint,
    pipeCuttingTool
  } = elements

  const pressureTubings = plan.pipelines.filter(
      pipeline => pipeline.lineType === "pressure-tubing"
  )

  const length = pressureTubings.reduce((acc, pipeline) => {
    return acc + sizeInMetersByPixel(pipeline.totalLength, plan.scale)
  }, 0)

  let bomItems = []

  bomItems.push(
      ..._findPipesAndConnectors(settings, plan.pressureTubeType, length)
  )

  const kitWithWaterTapQuantity = pressureTubings.reduce((acc, pipeline) => {
    const quantity = pipeline.points.filter(p => p.isWaterTapPoint).length
    return acc + quantity
  }, 0)

  if (kitWithWaterTapQuantity > 0) {
    const waterTapKitByTube = findFittingByTubeType(
        plan.pressureTubeType,
        pressureTubingKitWithWaterTap
    )
    bomItems.push({
      bomId: waterTapKitByTube.bomId,
      qualityList: bomList[waterTapKitByTube.bomId],
      quantity: kitWithWaterTapQuantity
    })
  }

  const kitWithBlankingQuantity = pressureTubings.reduce((acc, pipeline) => {
    const quantity = pipeline.points.filter(
        p => p.pointType === "l-point" && p.linesCount === 1
    ).length
    return acc + quantity
  }, 0)

  if (kitWithBlankingQuantity > 0) {
    const kitWithBlankingByTube = findFittingByTubeType(
        plan.pressureTubeType,
        pressureTubingKitWithBlanking
    )

    bomItems.push({
      bomId: kitWithBlankingByTube.bomId,
      qualityList: bomList[kitWithBlankingByTube.bomId],
      quantity: kitWithBlankingQuantity
    })
  }

  const lPointQuantity = pressureTubings.reduce((acc, pipeline) => {
    const quantity = pipeline.points.filter(
        p => p.pointType === "l-point" && p.linesCount === 2
    ).length
    return acc + quantity
  }, 0)

  if (lPointQuantity > 0) {
    const lPointByTube = findFittingByTubeType(
        plan.pressureTubeType,
        pressureTubingLPoint
    )
    bomItems.push({
      bomId: lPointByTube.bomId,
      qualityList: bomList[lPointByTube.bomId],
      quantity: lPointQuantity
    })
  }

  bomItems.push({
    bomId: pipeCuttingTool.bomId,
    qualityList: bomList[pipeCuttingTool.bomId],
    quantity: 1
  })

  return bomItems
}

export { pipesAndFittingsForPressureTubing }
