import React from "react";

/**
 *
 * @param {*} param0
 *
 * value in percent
 */
const ProgressBar = ({ value, hasError }) => {
  let rightBorder = 5 - (100 - Math.round(value));
  rightBorder = rightBorder > 0 ? rightBorder : 0;
  if (value > 100) {
    rightBorder = 5;
  }

  return (
    <div className={`rainshift-progress-bar ${hasError ? "error" : ""}`}>
      <div
        className="rainshift-progress"
        style={{
          width: value + "%",
          borderRadius: `5px ${rightBorder}px ${rightBorder}px 5px`,
        }}
      />
    </div>
  );
};

export default ProgressBar;
