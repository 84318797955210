import React from "react";
import { observer } from "mobx-react";

import Sprinkler, { getSprinklerRadius } from "./Sprinkler";
import Draggable from "../Draggable";

import {
  pixelSizeByZoom,
  sizeInMetersByPixel,
  svgTextCondensed,
} from "../../core/planUtils";

import { calculateAngleByPoints } from "../../core/geometryUtils";
import {
  calculateSvgPointsDirection,
  toFixedPrecision,
  remToPx,
} from "../../utils/uiUtils";
import { WaterflowSVG, WaterdropSVG } from "../pipes/PipePointIcons";
import { useIntl } from "react-intl";

function Circle({
  startAngle,
  sectorAngle,
  radius,
  sprinklerRadius,
  zoomDelta,
  isActive,
  hasInvalid = false,
  noFillSector = false,
}) {
  let arrowCenter = (radius - sprinklerRadius) / 2 + sprinklerRadius;
  const endCoords = {
    x: radius * Math.cos((sectorAngle * Math.PI) / 180),
    y: radius * Math.sin((sectorAngle * Math.PI) / 180),
  };

  return (
    <g>
      <circle
        className="size"
        strokeWidth={pixelSizeByZoom(isActive ? 1 : 0.5, zoomDelta)}
        r={radius}
        cx="0"
        cy="0"
      />

      <g
        className={`sector ${noFillSector ? "nofill" : ""}`}
        strokeWidth={pixelSizeByZoom(isActive ? 1 : 0.5, zoomDelta)}
        transform={`rotate(${!isNaN(startAngle) ? startAngle - 90 : 0})`}
        fill={
          noFillSector
            ? "none"
            : hasInvalid
            ? "rgb(255, 118, 0)"
            : "rgb(0,196,255)"
        }
        fillOpacity={noFillSector ? "none" : hasInvalid ? 0.3 : 0.15}
      >
        <path
          className="sector-start"
          strokeWidth={pixelSizeByZoom(isActive ? 1 : 0.5, zoomDelta)}
          d={`M0,0 H ${radius} L${
            arrowCenter - pixelSizeByZoom(8, zoomDelta)
          },0 L${arrowCenter},${pixelSizeByZoom(8, zoomDelta)} L${
            arrowCenter + pixelSizeByZoom(8, zoomDelta)
          },0 z`}
        ></path>
        {sectorAngle >= 270 ? (
          <path
            d={`M0,0 L${radius},0 A${radius},${radius} 1 0,1 0,${radius} A${radius},${radius} 1 0,1 0,${-radius} A${radius},${radius} 1 0,1 ${
              endCoords.x
            },${endCoords.y} z`}
          ></path>
        ) : sectorAngle >= 180 ? (
          <path
            d={`M0,0 L${radius},0 A${radius},${radius} 1 0,1 0,${radius} A${radius},${radius} 1 0,1 ${endCoords.x},${endCoords.y} z`}
          ></path>
        ) : (
          <path
            d={`M0,0 L${radius},0 A${radius},${radius} 1 0,1 ${endCoords.x},${endCoords.y} z`}
          ></path>
        )}
      </g>
    </g>
  );
}

function Text({
  title,
  nozzleType,
  startAngle,
  sectorAngle,
  radius,
  waterflow,
  waterpressure,
  zoomDelta,
  hasWaterpressureWarn = false,
  fromElementDrawing = false,
  prsInfo = true,
  waterflowInfo = true,
  infoVisible = true,
  waterpressureInfo = false,
}) {
  const { formatMessage, formatNumber } = useIntl();

  const waterflowPrecision = formatNumber(
    toFixedPrecision(waterflow / 1000, 2)
  );

  return (
    <g>
      {title && (
        <>
          <text x="0" y="0" {...svgTextCondensed}>
            <tspan
              x="0"
              y="0"
              className={`sprinkler-info-title`}
              style={{ fontWeight: 700 }}
            >
              {!fromElementDrawing && formatMessage({ id: title })}
              {nozzleType === "PRS30" && (
                <React.Fragment>
                  <tspan className="star">*</tspan>
                  {prsInfo && (
                    <tspan className="hide-info">/{nozzleType}</tspan>
                  )}
                </React.Fragment>
              )}
            </tspan>
            {waterflowInfo && waterflow && (
              <tspan x="1em" dy="1.2em">
                {waterflowPrecision} m
                <tspan dy={-pixelSizeByZoom(5, zoomDelta)} fontSize="75%">
                  3
                </tspan>
                <tspan dy={pixelSizeByZoom(5, zoomDelta)}>/h</tspan>
              </tspan>
            )}
            {!fromElementDrawing &&
              waterpressureInfo &&
              waterpressure != null && (
                <tspan
                  x="1em"
                  dy="1.2em"
                  fill={hasWaterpressureWarn ? "#ff7600" : "3e4346"}
                  style={{ fontWeight: 500 }}
                >
                  {waterpressure} bar
                </tspan>
              )}
          </text>
          {waterflowInfo && waterflow && (
            <g
              transform={`translate(0, ${pixelSizeByZoom(
                5,
                zoomDelta
              )}) scale(${pixelSizeByZoom(1, zoomDelta)})`}
            >
              <WaterflowSVG />
            </g>
          )}
          {!fromElementDrawing && waterpressureInfo && hasWaterpressureWarn && (
            <g
              transform={`translate(0, ${pixelSizeByZoom(
                23,
                zoomDelta
              )}) scale(${pixelSizeByZoom(1, zoomDelta)})`}
            >
              <WaterdropSVG />
            </g>
          )}
        </>
      )}

      {infoVisible && (
        <text
          className="hide-info"
          x="0"
          y={waterflow ? "1.2em" : 0}
          {...svgTextCondensed}
        >
          <tspan x="0" dy="1.2em" style={{ fontFamily: "Arial" }}>
            ►
          </tspan>
          <tspan x="1.2em" dy="0">
            {startAngle}° – {sectorAngle}°
          </tspan>
          <tspan x="0" dy="1.2em">
            {radius} m
          </tspan>
        </text>
      )}
    </g>
  );
}

function SprinklerControl({
  startAngle,
  changeStartAngle,
  sectorAngle,
  changeSectorAngle,
  radius,
  changeRadius,
  zoomDelta,
  onAfter = () => {},
}) {
  const startPos = {
    x: radius * Math.sin(startAngle * (Math.PI / 180)),
    y: -radius * Math.cos(startAngle * (Math.PI / 180)),
  };

  const sectorPos = {
    x: radius * Math.sin((+startAngle + +sectorAngle) * (Math.PI / 180)),
    y: -radius * Math.cos((+startAngle + +sectorAngle) * (Math.PI / 180)),
  };

  const sprinklerControl = (
    <circle
      x={0}
      y={0}
      r={pixelSizeByZoom(7, zoomDelta)}
      stroke="#3e4346"
      strokeWidth={pixelSizeByZoom(1, zoomDelta)}
    />
  );

  return (
    <g>
      <Draggable
        className="control"
        x={startPos.x}
        y={startPos.y}
        movePhase="drag"
        dragProps={`resize-control-1`}
        move={(x, y) => {
          const points = [{ x, y }, { x: 0, y: 0 }, startPos];

          const angle = calculateAngleByPoints(...points);
          const direction = calculateSvgPointsDirection(points);

          changeStartAngle(
            (Math.round(startAngle + direction * angle) + 360) % 360
          );
          changeRadius(Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2)));
        }}
        onAfterMove={onAfter}
      >
        {sprinklerControl}
      </Draggable>
      <Draggable
        className="control"
        dragProps={`resize-control-2`}
        x={sectorPos.x}
        y={sectorPos.y}
        onAfterMove={onAfter}
        movePhase="drag"
        move={(x, y) => {
          const points = [{ x, y }, { x: 0, y: 0 }, sectorPos];

          let angle = calculateAngleByPoints(...points);
          const direction = calculateSvgPointsDirection(points);

          angle = (Math.round(sectorAngle + direction * angle) + 360) % 360;
          changeSectorAngle(angle === 0 ? 360 : angle);
          changeRadius(Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2)));
        }}
      >
        {sprinklerControl}
      </Draggable>
    </g>
  );
}

function SprinklerCircleBody({
  startAngle,
  sectorAngle,
  radius,
  sprinklerRadius,
  zoomDelta,
  isSelected,
  hasInvalid,
  title,
  precision,
  color,
  nozzleType,
  waterflow,
  waterpressure,
  inNonCrossedArea,
  scale,
  hasWaterpressureWarn = false,
  useDefaultStyles = true,
  fromElementDrawing = false,
  noFillSector = false,
  textsVisibility = {},
  titleHidden = false,
  hidden = false,
}) {
  const { formatNumber } = useIntl();
  const calcSprinklerRadius = sprinklerRadius ?? getSprinklerRadius(scale);
  const spRadius = useDefaultStyles
    ? pixelSizeByZoom(8, zoomDelta)
    : calcSprinklerRadius;
  const textStyle = {
    fontSize: pixelSizeByZoom(remToPx(0.875), zoomDelta),
    letterSpacing: pixelSizeByZoom(0.6, zoomDelta),
  };
  return (
    <g className="sprinkler" style={textStyle}>
      <g className={hidden ? "element-hidden" : ""}>
        {textsVisibility.sprinklersIrrigation && (
          <Circle
            startAngle={startAngle}
            sectorAngle={sectorAngle}
            radius={radius}
            sprinklerRadius={spRadius}
            zoomDelta={zoomDelta}
            isActive={isSelected}
            hasInvalid={hasInvalid}
            noFillSector={noFillSector}
          />
        )}
        {textsVisibility.sprinklerElements && (
          <Sprinkler
            color={color}
            nozzleType={nozzleType}
            hasInvalid={hasInvalid}
            inNonCrossedArea={inNonCrossedArea}
            zoomDelta={zoomDelta}
            scale={scale}
            useDefaultStyles={useDefaultStyles}
          />
        )}
      </g>
      {textsVisibility.sprinklersTexts && !titleHidden && (
        <g
          className="sprinkler-info"
          transform={`translate(0, ${
            calcSprinklerRadius + pixelSizeByZoom(16, zoomDelta)
          })`}
        >
          <Text
            title={title}
            nozzleType={nozzleType}
            startAngle={startAngle}
            sectorAngle={sectorAngle}
            radius={formatNumber(
              toFixedPrecision(sizeInMetersByPixel(radius, scale), precision)
            )}
            waterflow={waterflow}
            waterpressure={waterpressure}
            hasWaterpressureWarn={hasWaterpressureWarn}
            zoomDelta={zoomDelta}
            precision={precision}
            prsInfo={textsVisibility.sprinklersPrsInfo}
            fromElementDrawing={fromElementDrawing}
            infoVisible={textsVisibility.sprinklersInfo}
            waterflowInfo={textsVisibility.sprinklersWaterflowText}
            waterpressureInfo={textsVisibility.sprinklersWaterpressureText}
          />
        </g>
      )}
    </g>
  );
}

function SprinklerCircle({
  id,
  x,
  y,
  title,
  titleHidden,
  startAngle,
  changeStartAngle,
  sectorAngle,
  changeSectorAngle,
  radius,
  changeCircleRadius,
  color,
  hidden,
  waterflow,
  waterpressure,
  drag,
  hasInvalid,
  inNonCrossedArea,
  nozzleType,
  disabled,
  zoomDelta,
  scale,
  isSelected,
  isHovered,
  onChange,
  precision,
  sprinklerRadius,
  setSelectedElement = () => {},
  remove = () => {},
  hasWaterpressureWarn = false,
  elementIsChanged = false,
  fromElementDrawing = false,
  abjustPosition = () => {},
  noFillSector = false,
  textsVisibility = {},
  useDefaultStyles = true,
}) {
  const spRadius = useDefaultStyles
    ? pixelSizeByZoom(8, zoomDelta)
    : sprinklerRadius;

  const textStyle = {
    fontSize: pixelSizeByZoom(remToPx(0.875), zoomDelta),
    letterSpacing: pixelSizeByZoom(0.6, zoomDelta),
  };
  return (
    <Draggable
      id={id}
      x={x}
      y={y}
      move={drag}
      onChange={onChange}
      onAfterMove={() => {
        onChange();
        abjustPosition();
      }}
      draggable={!disabled}
      cloneable={!disabled}
      dragProps={id}
      movePhase="drag"
      onClick={() => {
        setSelectedElement(id);
      }}
      onDoubleClick={() => {
        remove(id, false);
      }}
    >
      <g
        className={`element sprinkler ${
          isHovered || isSelected ? "active" : ""
        } ${hasInvalid ? "invalid" : ""}`}
        onMouseEnter={(e) => {
          if (!elementIsChanged) {
            const sprinkler = e.target.closest(".sprinkler");
            if (sprinkler) {
              sprinkler.classList.add("hovered");
            }
          }
        }}
        onMouseLeave={(e) => {
          if (!elementIsChanged) {
            const sprinkler = e.target.closest(".sprinkler");
            if (sprinkler) {
              sprinkler.classList.remove("hovered");
            }
          }
        }}
        style={textStyle}
      >
        <SprinklerCircleBody
          startAngle={startAngle}
          sectorAngle={sectorAngle}
          radius={radius}
          sprinklerRadius={spRadius}
          zoomDelta={zoomDelta}
          isSelected={isSelected}
          hasInvalid={hasInvalid}
          noFillSector={noFillSector}
          hidden={hidden}
          color={color}
          nozzleType={nozzleType}
          inNonCrossedArea={inNonCrossedArea}
          scale={scale}
          useDefaultStyles={useDefaultStyles}
          title={title}
          waterflow={waterflow}
          waterpressure={waterpressure}
          hasWaterpressureWarn={hasWaterpressureWarn}
          precision={precision}
          titleHidden={titleHidden}
          textsVisibility={textsVisibility}
          fromElementDrawing={fromElementDrawing}
        />
        {isSelected && !disabled && (
          <SprinklerControl
            startAngle={startAngle}
            changeStartAngle={changeStartAngle}
            sectorAngle={sectorAngle}
            changeSectorAngle={changeSectorAngle}
            radius={radius}
            changeRadius={changeCircleRadius}
            zoomDelta={zoomDelta}
            onAfter={onChange}
          />
        )}
      </g>
    </Draggable>
  );
}

export default observer(
  ({
    element,
    planIsEditable,
    hoveredElementId,
    selectedElementId,
    scale,
    ...props
  }) => {
    return (
      <SprinklerCircle
        id={element.id}
        x={element.x}
        y={element.y}
        title={element.name}
        titleHidden={element.titleHidden}
        startAngle={element.startAngle}
        changeStartAngle={element.changeStartAngle}
        sectorAngle={element.circleSectorAngle}
        changeSectorAngle={element.changeCircleSectorAngle}
        radius={element.circleRadius}
        changeCircleRadius={element.changeCircleRadius}
        color={element.color}
        hidden={element.hidden}
        waterflow={element.waterflow}
        waterpressure={element.waterpressureInBar}
        hasWaterpressureWarn={element.hasWaterpressureWarn}
        drag={element.drag}
        disabled={element.disabled || !planIsEditable}
        nozzleType={element.nozzleType}
        hasInvalid={element.hasInvalid}
        inNonCrossedArea={element.inNonCrossedArea}
        isHovered={element.id === hoveredElementId}
        isSelected={element.id === selectedElementId}
        sprinklerRadius={getSprinklerRadius(scale ? scale : null)}
        scale={scale}
        {...props}
      />
    );
  }
);

export { SprinklerCircleBody };
