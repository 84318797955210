import { extendObservable, observable, action } from "mobx";
import { v4 as uuid } from "uuid";

import { lineLength } from "../../core/geometryUtils";

/**
 * Represents a trench object
 * @param {*} param0 - trench json
 * @returns trench object
 * contains
 * start - trench start point
 * end - trench end point
 * pipes - trenched elements
 */
const trenchFactory = ({ start, end, pipes = [] }) => {
  //inject object variables
  const trench = observable({
    id: "trench-" + uuid(),
    start,
    end,
    pipes,
    selected: false,
  });

  // getters
  extendObservable(trench, {
    get isValid() {
      return (
        this.start != null &&
        this.end != null &&
        lineLength(this.start, this.end) > 0
      );
    },
    get centerPoint() {
      return this.isValid
        ? {
            x: (this.start.x + this.end.x) / 2,
            y: (this.start.y + this.end.y) / 2,
          }
        : null;
    },
    get pipesCount() {
      return this.pipes && this.pipes.length > 0 ? this.pipes.length : 0;
    },
    get colors() {
      return this.isValid && this.pipesCount > 0
        ? this.pipes.map((el) =>
            el.color
              ? el.color
              : el.pipelines && el.pipelines.length > 0
              ? el.pipelines[0].color
              : "#9EA1A2"
          )
        : [];
    },
    get pipesForPopup() {
      const obj =
        this.isValid && this.pipesCount > 0
          ? this.pipes
              .map((el) => {
                return {
                  id: el.id,
                  color:
                    el.pipelines && el.pipelines.length > 0
                      ? el.pipelines[0].color
                      : el.color ?? "#9EA1A2",
                };
              })
              .reduce((acc, cur) => {
                (acc[cur.color] = acc[cur.color] || []).push(cur.id);
                return acc;
              }, {})
          : {};
      return Object.keys(obj).map((key) => {
        return { color: key, ids: obj[key] };
      });
    },
    get path() {
      if (!this.isValid) return undefined;
      return (
        "M " +
        this.start.x +
        " " +
        this.start.y +
        " L " +
        this.end.x +
        " " +
        this.end.y
      );
    },
    get length() {
      return lineLength(this.start, this.end, null);
    },
    get toJSON() {
      return {
        id: this.id,
        start: this.start,
        end: this.end,
        pipes: this.pipes,
        selected: this.selected,
        path: this.path,
      };
    },
  });

  // actions
  extendObservable(trench, {
    addPipe: action((pipe) => {
      trench.pipes.push(pipe);
    }),
    onSelect: action((value = !trench.selected) => {
      trench.selected = value;
    }),
  });

  return trench;
};

export { trenchFactory };
export default trenchFactory;
