import { extendObservable, action, observable } from "mobx";
import { throttle } from "lodash";
import WorkerThreadApi from "../utils/workerThreadApi";
import trenchingPipelinesAdapter from "../types/trenchingPipelinesAdapter";
import trenchFactory from "../types/trenchFactory";
import { pixelSizeByZoom } from "../../core/planUtils";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (plan) => {
  const uiTrenchingWorker = new WorkerThreadApi(
    "/uiTrenchingWorker.js"
  );

  const innerState = observable({
    trenches: undefined,
  });

  extendObservable(plan, {
    cleanupTrenches() {
      innerState.trenches = undefined;
      uiTrenchingWorker.send("trenching-cleanup");
    },
    recalculateTrenches: throttle(async (hiddenPipeColors = [], zoomDelta) => {
      const threshold = zoomDelta ? pixelSizeByZoom(10, zoomDelta) : 10;
      uiTrenchingWorker.send("trenching-start", {
        pipelines: trenchingPipelinesAdapter(plan.pipelines),
        hiddenPipeColors: JSON.parse(JSON.stringify(hiddenPipeColors)),
        threshold,
      });
    }, 1000),
    get trenches() {
      return innerState.trenches;
    },
  });

  uiTrenchingWorker.on(
    "trenching-start",
    action(({ trenches }) => {
      innerState.trenches = trenches
        ?.filter((t) => t?.pipes?.length > 1)
        ?.map((t) => trenchFactory(t));
    })
  );
};
