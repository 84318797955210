const { createIntl, createIntlCache } = require("react-intl");

const languages = [
  {
    id: "en-GB",
    label: "English",
    locales: [
      "en",
      "en-GB",
      "en-US",
      "en-AU",
      "en-BZ",
      "en-CA",
      "en-CB",
      "en-IE",
      "en-JM",
      "en-NZ",
      "en-PH",
      "en-TT",
      "en-ZA",
      "en-ZW",
    ],
    messages: require("./translations/en.json"),
  },
  {
    id: "de-DE",
    label: "Deutsch",
    locales: ["de", "de-AT", "de-CH", "de-DE", "de-LI", "de-LU"],
    messages: require("./translations/de.json"),
  },
  {
    id: "fr-FR",
    label: "Français",
    locales: ["fr", "fr-BE", "fr-CA", "fr-CH", "fr-FR", "fr-LU", "fr-MC"],
    messages: require("./translations/fr.json"),
  },
];

const findLocaleCnf = (locale) => {
  return languages.find(({ locales }) =>
    locales.map((l) => l.toLowerCase()).includes(locale.toLowerCase())
  );
};

const intlStore = (locale) => {
  const DEFAULT_LOCALE = "en-GB"; //"fr-FR"; //"de-DE";
  const defaultLocaleCnf = findLocaleCnf(DEFAULT_LOCALE);
  const localeCnf = findLocaleCnf(locale ?? DEFAULT_LOCALE);

  const messages = {
    ...(defaultLocaleCnf?.messages || {}),
    ...(localeCnf?.messages || {}),
  };

  const cache = createIntlCache();
  return createIntl(
    {
      locale: localeCnf?.id || defaultLocaleCnf?.id,
      defaultLocale: defaultLocaleCnf?.id,
      messages,
      onError: () => {},
    },
    cache
  );
};

module.exports = { intlStore };
