import React from "react";
import { observer } from "mobx-react";

import {
  pixelSizeByZoom,
  sizeInMetersByPixel,
  svgTextCondensed,
} from "../../core/planUtils";
import {
  calculateAngleByPoints,
  pointFactory,
  lineLength,
} from "../../core/geometryUtils";
import {
  calculateSvgPointsDirection,
  toFixedPrecision,
  remToPx,
} from "../../utils/uiUtils";
import { mod } from "../../core/helpers";

import Draggable from "../Draggable";
import Sprinkler, { getSprinklerRadius } from "./Sprinkler";
import { useIntl } from "react-intl";

import { WaterflowSVG, WaterdropSVG } from "../pipes/PipePointIcons";

function Rect({
  startAngle,
  x,
  y,
  width,
  height,
  isActive,
  zoomDelta,
  hasInvalid,
  noFillSector,
}) {
  return (
    <rect
      transform={`rotate(${
        !isNaN(startAngle) && startAngle !== "" ? startAngle : 0
      }, 0, 0)`}
      className={`rect rect-sector ${noFillSector ? "nofill" : ""}`}
      fill={
        noFillSector
          ? "none"
          : hasInvalid
          ? "rgb(255, 118, 0)"
          : "rgb(0,196,255)"
      }
      fillOpacity={noFillSector ? "none" : hasInvalid ? 0.3 : 0.15}
      x={x}
      y={y}
      width={width}
      height={height}
      strokeWidth={pixelSizeByZoom(isActive ? 1 : 0.5, zoomDelta)}
    />
  );
}

function Text({
  type,
  title,
  nozzleType,
  startAngle,
  width,
  height,
  waterflow,
  waterpressure,
  zoomDelta,
  fromElementDrawing,
  hasWaterpressureWarn = false,
  prsInfo = true,
  infoVisible = true,
  waterflowInfo = true,
  waterpressureInfo = false,
}) {
  const { formatMessage, formatNumber } = useIntl();

  const waterflowPrecision = formatNumber(
    toFixedPrecision(waterflow / 1000, 2)
  );

  return (
    <g textAnchor={`${type === "right" ? "end" : "start"}`}>
      {title && (
        <>
          <text x="0" y="0" {...svgTextCondensed}>
            <tspan
              x="0"
              y="0"
              className={`sprinkler-info-title`}
              style={{ fontWeight: 700 }}
            >
              {!fromElementDrawing && formatMessage({ id: title })}
              {nozzleType === "PRS30" && (
                <React.Fragment>
                  <tspan className="star">*</tspan>
                  {prsInfo && (
                    <tspan className="hide-info">/{nozzleType}</tspan>
                  )}
                </React.Fragment>
              )}
            </tspan>
            {waterflowInfo && waterflow && (
              <tspan x={type === "right" ? "0" : "1em"} dy="-1.2em">
                {waterflowPrecision} m
                <tspan dy={-pixelSizeByZoom(5, zoomDelta)} fontSize="75%">
                  3
                </tspan>
                <tspan dy={pixelSizeByZoom(5, zoomDelta)}>/h</tspan>
              </tspan>
            )}
            {!fromElementDrawing &&
              waterpressureInfo &&
              waterpressure != null && (
                <tspan
                  x={type === "right" ? "0" : "1em"}
                  dy="-1.2em"
                  fill={hasWaterpressureWarn ? "#ff7600" : "3e4346"}
                  style={{ fontWeight: 500 }}
                >
                  {waterpressure} bar
                </tspan>
              )}
          </text>
          {waterflowInfo && waterflow && (
            <g
              transform={`translate(${
                type === "right" ? -pixelSizeByZoom(70, zoomDelta) : 0
              }, -${pixelSizeByZoom(28, zoomDelta)}) scale(${pixelSizeByZoom(
                1,
                zoomDelta
              )})`}
            >
              <WaterflowSVG />
            </g>
          )}
          {!fromElementDrawing && waterpressureInfo && hasWaterpressureWarn && (
            <g
              transform={`translate(${
                type === "right" ? -pixelSizeByZoom(70, zoomDelta) : 0
              }, -${pixelSizeByZoom(45, zoomDelta)}) scale(${pixelSizeByZoom(
                1,
                zoomDelta
              )})`}
            >
              <WaterdropSVG />
            </g>
          )}
        </>
      )}
      {infoVisible && (
        <text
          className="hide-info"
          x="0"
          y={waterflow ? "-1.2em" : 0}
          {...svgTextCondensed}
        >
          <tspan x="0" dy="-1.2em">
            {startAngle}°
          </tspan>
          <tspan x="0" dy="-1.2em">
            {width} x {height} m
          </tspan>
        </text>
      )}
    </g>
  );
}

function SprinklerControl({
  left,
  top,
  right,
  startAngle,
  sprinklerRef,
  changeStartAngle = () => {},
  changeWidth = () => {},
  zoomDelta,
  onAfter = () => {},
}) {
  const sprinklerControl = (
    <circle
      x={0}
      y={0}
      r={pixelSizeByZoom(6, zoomDelta)}
      stroke="#3e4346"
      strokeWidth={pixelSizeByZoom(1, zoomDelta)}
    />
  );

  const rotateControl = (
    <g>
      <circle
        className="rotate-circle"
        x="0"
        y="0"
        r={pixelSizeByZoom(10.5, zoomDelta)}
      ></circle>
      <g
        className="rotate-arrow"
        transform={`translate(${-pixelSizeByZoom(
          9,
          zoomDelta
        )}, ${-pixelSizeByZoom(8, zoomDelta)}) scale(${pixelSizeByZoom(
          1,
          zoomDelta
        )})`}
        fill="#3e4346"
        stroke="none"
      >
        <path d="M9.4,14v2a3.75,3.75,0,0,0,1.1-.1l-.3-2A2.485,2.485,0,0,1,9.4,14Z" />
        <path d="M12.6,13l1.1,1.7a5.5,5.5,0,0,0,.9-.7l-1.3-1.5A3.039,3.039,0,0,1,12.6,13Z" />
        <path d="M11,13.8l.6,1.9a4.875,4.875,0,0,0,1.1-.4l-.8-1.8Z" />
        <path d="M13.9,11.9l1.5,1.3c.2-.3.5-.6.7-.9l-1.7-1.1A4.233,4.233,0,0,1,13.9,11.9Z" />
        <path d="M14.8,10.5l1.8.8a3.582,3.582,0,0,0,.4-1.1l-1.9-.6A9.44,9.44,0,0,1,14.8,10.5Z" />
        <path d="M9.4,0A8.076,8.076,0,0,0,1.7,5.6L.9,4,0,4.5,2.2,8.3,5.8,5.5l-.6-.7L3.7,6A5.957,5.957,0,0,1,9.3,2a6.018,6.018,0,0,1,6,6,2.769,2.769,0,0,1-.1.9l2,.3a4.484,4.484,0,0,0,.1-1.1A7.9,7.9,0,0,0,9.4,0Z" />
      </g>
    </g>
  );

  const controls = { left, right };

  const sprinklerRect = React.useRef();
  const startAngleRef = React.useRef();

  return (
    <g transform={`rotate(${startAngle}, 0, 0)`}>
      {top != null && (
        <g>
          <g
            fill="#fff"
            stroke="#3e4346"
            strokeWidth={pixelSizeByZoom(1, zoomDelta)}
          >
            <g>
              <Draggable
                className="control"
                dragProps={`resize-control-top`}
                x={top.x}
                y={top.y - pixelSizeByZoom(30, zoomDelta)}
                onBeforeMove={() => {
                  sprinklerRect.current =
                    sprinklerRef.current.getBoundingClientRect();
                  startAngleRef.current = startAngle;
                }}
                onAfterMove={onAfter}
                movePhase="drag"
                move={(_x, _y, { state }) => {
                  const rect = sprinklerRect.current;
                  const points = [state.current, rect, state.initial].map(
                    pointFactory
                  );

                  const angle = calculateAngleByPoints(...points);
                  const direction = calculateSvgPointsDirection(points);

                  changeStartAngle(
                    mod(
                      Math.round(startAngleRef.current + direction * angle),
                      360
                    )
                  );
                }}
              >
                {rotateControl}
              </Draggable>
            </g>
          </g>
          <g className="control" transform={`translate(${top.x}, ${top.y})`}>
            <line
              x1="0"
              y1={pixelSizeByZoom(0, zoomDelta)}
              x2="0"
              y2={pixelSizeByZoom(-20, zoomDelta)}
              stroke="#3e4346"
              strokeWidth={pixelSizeByZoom(1, zoomDelta)}
            />
          </g>
        </g>
      )}
      {Object.keys(controls)
        .filter((key) => controls[key] != null)
        .map((key) => (
          <Draggable
            key={key}
            dragProps={`resize-control-${key}`}
            className="control"
            x={controls[key].x}
            y={controls[key].y}
            onAfterMove={onAfter}
            onBeforeMove={() => {
              sprinklerRect.current =
                sprinklerRef.current.getBoundingClientRect();
            }}
            move={(_x, _y, { state }) => {
              changeWidth(
                pixelSizeByZoom(
                  lineLength(
                    ...[sprinklerRect.current, state.current].map(pointFactory)
                  ),
                  zoomDelta
                )
              );
            }}
            movePhase="drag"
          >
            {sprinklerControl}
          </Draggable>
        ))}
    </g>
  );
}

function generateSettingsByType(
  type,
  width,
  height,
  zoomDelta,
  sprinklerRadius, //spRadius
  changeWidth = () => {}
) {
  let settings = {
    rectPosition: {
      x: 0,
      y: 0,
    },
    textPosition: { x: 0, y: 0 },
  };
  switch (type) {
    case "left":
      settings = {
        rectPosition: {
          x: 0,
          y: -height,
        },
        textPosition: {
          x: pixelSizeByZoom(10, zoomDelta),
          y: -(pixelSizeByZoom(5, zoomDelta) + sprinklerRadius),
        },
        controlPos: {
          right: { x: width, y: -height },
          top: { x: width / 2, y: -height },
          center: { x: width / 2, y: -height / 2 },
          changeWidth: (val) => changeWidth(val),
        },
      };
      break;
    case "right":
      settings = {
        rectPosition: {
          x: -width,
          y: -height,
        },
        textPosition: {
          x: 0,
          y: -(pixelSizeByZoom(5, zoomDelta) + sprinklerRadius),
        },
        controlPos: {
          left: { x: -width, y: -height },
          top: { x: -width / 2, y: -height },
          center: { x: -width / 2, y: -height / 2 },
          changeWidth: (val) => changeWidth(val),
        },
      };

      break;
    case "center":
      settings = {
        rectPosition: {
          x: -width / 2,
          y: -height,
        },

        textPosition: {
          x: 0,
          y: -(pixelSizeByZoom(5, zoomDelta) + sprinklerRadius),
        },
        controlPos: {
          right: { x: width / 2, y: -height },
          top: { x: 0, y: -height },
          center: { x: 0, y: -height / 2 },
          changeWidth: (val) => changeWidth(val * 2),
        },
      };

      break;
    default: {
      break;
    }
  }
  return settings;
}

function SprinklerRectBody({
  type,
  disabled,
  sprinklerRadius,
  startAngle,
  width,
  height,
  isActive,
  zoomDelta,
  hasInvalid,
  title,
  titleHidden,
  color,
  hidden,
  waterflow,
  waterpressure,
  inNonCrossedArea,
  nozzleType,
  scale,
  precision,
  fromElementDrawing,
  noFillSector,
  textsVisibility,
  hasWaterpressureWarn = false,
  useDefaultStyles = true,
  changeStartAngle = () => {},
  onChange = () => {},
  changeWidth = () => {},
}) {
  const { formatNumber } = useIntl();
  const calcSprinklerRadius = sprinklerRadius ?? getSprinklerRadius(scale);
  const spRadius = useDefaultStyles
    ? pixelSizeByZoom(8, zoomDelta)
    : calcSprinklerRadius;

  const textStyle = {
    fontSize: pixelSizeByZoom(remToPx(0.875), zoomDelta),
    letterSpacing: pixelSizeByZoom(0.6, zoomDelta),
  };

  const settings = generateSettingsByType(
    type,
    width,
    height,
    zoomDelta,
    spRadius,
    changeWidth
  );

  const sprinklerRef = React.useRef();
  return (
    <g className="sprinkler" style={textStyle}>
      <g className={hidden ? "element-hidden" : ""}>
        {textsVisibility.sprinklersIrrigation && (
          <Rect
            x={settings.rectPosition.x}
            y={settings.rectPosition.y}
            startAngle={startAngle}
            width={width}
            height={height}
            isActive={isActive}
            zoomDelta={zoomDelta}
            hasInvalid={hasInvalid}
            noFillSector={noFillSector}
          />
        )}
        {textsVisibility.sprinklerElements && (
          <Sprinkler
            color={color}
            nozzleType={nozzleType}
            hasInvalid={hasInvalid}
            inNonCrossedArea={inNonCrossedArea}
            ref={sprinklerRef}
            zoomDelta={zoomDelta}
            scale={scale}
            useDefaultStyles={useDefaultStyles}
          />
        )}
      </g>
      {textsVisibility.sprinklersTexts && !titleHidden && (
        <g
          className="sprinkler-info"
          transform={`translate(${settings.textPosition.x}, ${settings.textPosition.y})`}
        >
          <Text
            type={type}
            title={title}
            nozzleType={nozzleType}
            startAngle={startAngle}
            width={formatNumber(
              toFixedPrecision(sizeInMetersByPixel(width, scale), precision)
            )}
            height={formatNumber(
              toFixedPrecision(sizeInMetersByPixel(height, scale), precision)
            )}
            waterflow={waterflow}
            waterpressure={waterpressure}
            hasWaterpressureWarn={hasWaterpressureWarn}
            zoomDelta={zoomDelta}
            precision={precision}
            fromElementDrawing={fromElementDrawing}
            prsInfo={textsVisibility.sprinklersPrsInfo}
            infoVisible={textsVisibility.sprinklersInfo}
            waterflowInfo={textsVisibility.sprinklersWaterflowText}
            waterpressureInfo={textsVisibility.sprinklersWaterpressureText}
          />
        </g>
      )}
      {isActive && !disabled && (
        <SprinklerControl
          {...settings.controlPos}
          sprinklerRef={sprinklerRef}
          startAngle={startAngle}
          changeStartAngle={changeStartAngle}
          zoomDelta={zoomDelta}
          onAfter={onChange}
        />
      )}
    </g>
  );
}

function SprinklerRect({
  id,
  title,
  titleHidden,
  startAngle,
  width,
  height,
  type,
  color,
  hidden,
  waterflow,
  waterpressure,
  x,
  y,
  hasInvalid,
  inNonCrossedArea,
  nozzleType,
  changeStartAngle,
  changeWidth,
  drag,
  disabled,
  scale,
  isSelected,
  isHovered,
  onChange,
  precision,
  sprinklerRadius,
  zoomDelta = 1,
  setSelectedElement = () => {},
  remove = () => {},
  abjustPosition = () => {},
  hasWaterpressureWarn = false,
  elementIsChanged = false,
  fromElementDrawing = false,
  noFillSector = false,
  textsVisibility = {},
  useDefaultStyles = true,
}) {
  const textStyle = {
    fontSize: pixelSizeByZoom(remToPx(0.875), zoomDelta),
    letterSpacing: pixelSizeByZoom(0.6, zoomDelta),
  };

  return (
    <Draggable
      id={id}
      x={x}
      y={y}
      move={drag}
      onChange={onChange}
      onAfterMove={() => {
        onChange();
        abjustPosition();
      }}
      draggable={!disabled}
      cloneable={!disabled}
      dragProps={id}
      movePhase="drag"
      onClick={() => {
        setSelectedElement(id);
      }}
      onDoubleClick={() => {
        remove(id, false);
      }}
    >
      <g
        className={`element sprinkler ${
          isSelected || isHovered ? "active" : ""
        } ${hasInvalid ? "invalid" : ""}`}
        onMouseEnter={(e) => {
          if (!elementIsChanged) {
            const sprinkler = e.target.closest(".sprinkler");
            if (sprinkler) {
              sprinkler.classList.add("hovered");
            }
          }
        }}
        onMouseLeave={(e) => {
          if (!elementIsChanged) {
            const sprinkler = e.target.closest(".sprinkler");
            if (sprinkler) {
              sprinkler.classList.remove("hovered");
            }
          }
        }}
        style={textStyle}
      >
        <SprinklerRectBody
          type={type}
          sprinklerRadius={sprinklerRadius}
          startAngle={startAngle}
          width={width}
          height={height}
          isActive={isSelected}
          zoomDelta={zoomDelta}
          hasInvalid={hasInvalid}
          title={title}
          titleHidden={titleHidden}
          color={color}
          hidden={hidden}
          waterflow={waterflow}
          waterpressure={waterpressure}
          hasWaterpressureWarn={hasWaterpressureWarn}
          inNonCrossedArea={inNonCrossedArea}
          nozzleType={nozzleType}
          scale={scale}
          precision={precision}
          fromElementDrawing={fromElementDrawing}
          noFillSector={noFillSector}
          textsVisibility={textsVisibility}
          useDefaultStyles={useDefaultStyles}
          disabled={disabled}
          changeStartAngle={changeStartAngle}
          onChange={onChange}
          changeWidth={changeWidth}
        />
      </g>
    </Draggable>
  );
}

export default observer(
  ({
    element,
    planIsEditable,
    hoveredElementId,
    selectedElementId,
    scale,
    ...props
  }) => {
    return (
      <SprinklerRect
        id={element.id}
        title={element.name}
        titleHidden={element.titleHidden}
        startAngle={element.startAngle}
        changeStartAngle={element.changeStartAngle}
        width={element.rectWidth}
        changeWidth={element.changeRectWidth}
        height={element.rectHeight}
        changeHeight={element.changeRectHeight}
        type={element.rectType}
        color={element.color}
        hidden={element.hidden}
        waterflow={element.waterflow}
        waterpressure={element.waterpressureInBar}
        hasWaterpressureWarn={element.hasWaterpressureWarn}
        x={element.x}
        y={element.y}
        hasInvalid={element.hasInvalid}
        inNonCrossedArea={element.inNonCrossedArea}
        nozzleType={element.nozzleType}
        disabled={element.disabled || !planIsEditable}
        drag={element.drag}
        isHovered={element.id === hoveredElementId}
        isSelected={element.id === selectedElementId}
        scale={scale}
        sprinklerRadius={getSprinklerRadius(scale ? scale : null)}
        {...props}
      />
    );
  }
);

export { SprinklerRectBody };
