import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "mobx-react";
import { RawIntlProvider } from "react-intl";

import Helpers from "./core/helpers";
import { intlStore } from "./core/intlStore";


import uiStateFactory from "./store/uiState";
import { calcApi } from "./utils/api";

import App from "./App";
import { cookies } from "./utils/uiUtils";
import {Modal} from "react-bootstrap";


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
          <Modal show={true}>
            <Modal.Header closeButton>
              <Modal.Title>App Error</Modal.Title>
              <Modal.Body>Something went wrong.</Modal.Body>
            </Modal.Header>
          </Modal>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

// This is optional but highly recommended
// since it prevents memory leak

// CONFIGURE HELPERS
Helpers.performance = performance;

window.runApplication = async () => {
  let Component = null;
  try {
    const settings = await calcApi.fetchSettingsFile();
    const intl = intlStore(
      cookies?.language
        ? cookies?.language.replace("_", "-")
        : window?.navigator?.language
    );
    const uiState = uiStateFactory(settings, intl);

    Component = (
      <ErrorBoundary>
        <Provider uiState={uiState}>
          <RawIntlProvider value={intl}>
            <App />
          </RawIntlProvider>
        </Provider>
      </ErrorBoundary>
    );
  } catch (e) {
    console.error(e);
    Component = (
      <Modal show={true}>
        <Modal.Header>
          <Modal.Title>App Error</Modal.Title>

        </Modal.Header>
        <Modal.Body>Connect Error</Modal.Body>
      </Modal>
    );
  }

  const root = createRoot(document.getElementById("root"));

  root.render(Component);



};

window.runApplication();