import {Modal, Button} from "react-bootstrap";
import {observer} from "mobx-react";
import {FormattedMessage} from "react-intl";
import React, {useEffect, useState, useRef} from "react";
import useStores from "../hooks/useStores";




export const SettingModal = observer(({type = 0, value="", title = '', subtypes = []}) => {
    const { uiState: { settingsState: { elements }, updateElements } } = useStores();

    let input = {};
    let form = {};
    let setInput = () => {}

    useEffect(() => {
      //  console.log(input);
    }, [input]);




    const [show, setShow] = useState(false);
    const products =  [{ Name:'Select Product', Code:'' },{ Name:'Product1', Code:'Name' }]
    let Content = () => <div></div> ;

    if(type === 0){
        form[value] = {
            bomId: elements[value].bomId
        };

        [input,setInput] = useState(form);

        Content = () => {
            return (
                <div className="mb-3">
                    <select value={input[value]?.bomId || ""} className={'form-select'} onChange={(e) =>  {
                        setInput((prevState) => {
                            const updatedState = { ...prevState };
                            updatedState[value] = {
                                ...updatedState[value],
                                bomId: e.target.value
                            };
                            return updatedState;
                        })
                    }}>
                        {products.map((p, i) => {
                            return (
                                <option key={i} value={p.Code}>
                                    {p.Name}
                                </option>
                            );
                        })}
                    </select>
                </div>
            )
        }
    }
    else if(type === 1){

        form[value] = {
            name: elements[value].name,
            value: elements[value].value,
            bomId: elements[value].bomId
        };
        [input,setInput] = useState(form);


        Content = () => {
            return (
                <>
                    <div className="mb-3">
                        <select value={input[value]?.bomId || ""}  className={'form-select'} onChange={(e) => {
                            setInput((prevState) => {
                                const updatedState = { ...prevState };
                                updatedState[value] = {
                                    ...updatedState[value],
                                    bomId: e.target.value
                                };
                                console.log(updatedState)
                                return updatedState;
                            })

                        }}>
                            {products.map((p, i) => {
                                return (
                                    <option key={i} value={p.Code}>
                                        {p.Name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                    <div className="mb-3">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            checked={input[value].value}
                            id={value}
                            onChange={(e) => {
                                setInput((prevState) => {
                                    const updatedState = { ...prevState };
                                    updatedState[value] = {
                                        ...updatedState[value],
                                        value: e.target.checked
                                    };
                                    return updatedState;
                                })
                            }}
                        />
                        <label className="form-check-label ms-1" htmlFor={value}>
                            {` Default Value`}
                        </label>
                    </div>

                </>
            )
        }
    }
    else if(type === 2){
         [input, setInput] = useState(() => {
             let initialForm = {};
             initialForm[value] = elements[value].map(values => {
                 const newValues = {};
                 Object.keys(values).forEach(sub => {
                     newValues[sub] = values[sub];
                 });
                 return newValues;
             });
            return initialForm;
        });

        Content = () => {
            return subtypes.map((item,index) => {
                const current = input[value].find(e => e.type === item.value);
                return (

                        <div className="mb-3" key={index}>
                            <label className="mb-2 text-muted" htmlFor="first_name">
                                {item.title}
                            </label>
                            <select value={current?.bomId || ""} className={'form-select'} onChange={(e) => {
                                setInput((prevState) => {
                                    const updatedState = { ...prevState };
                                    updatedState[value] = [
                                        ...updatedState[value]
                                    ]
                                    let findIndex = updatedState[value].findIndex(e =>  e.type === item.value)
                                    updatedState[value][findIndex].bomId = e.target.value
                                    return updatedState;
                                })
                            }}>
                                {products.map((p, i) => {
                                    return (
                                        <option key={i} value={p.Code}>
                                            {p.Name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                )
            })

        }
    }
    else if(type === 3){
        [input, setInput] = useState(() => {
            let initialForm = {};
            initialForm[value] = elements[value].map(values => {
                const newValues = {};
                Object.keys(values).forEach(sub => {
                    newValues[sub] = values[sub];
                });
                return newValues;
            });
            return initialForm;
        });

        const inputRefs = useRef([]);



        useEffect(() => {
            const activeInput = document.activeElement;
            console.log(activeInput)
            if (activeInput.tagName === 'INPUT' || activeInput.tagName === 'SELECT') {
                activeInput.focus();
            }
        }, [input]);


        Content = () => {


            return(
                 <div>
                     {
                         input[value].map((item,index) => {
                             return (<div
                                 className={"mb-1"}
                                 style={{
                                     border: '1px solid #000',
                                     borderRadius: '10px',
                                     paddingRight: '10px',
                                     paddingLeft: '10px',
                                     paddingTop: '10px',
                                     paddingBottom: '10px',
                                 }}
                                 key={index}
                             >
                                 <div className="mb-1">

                                     <select value={item?.bomId || ""} className={'form-select'} onChange={(e) => {
                                         setInput((prevState) => {
                                             const updatedState = {...prevState};
                                             updatedState[value] = [
                                                 ...updatedState[value]
                                             ]
                                             let findIndex = updatedState[value].findIndex((e,i) => index === i)
                                             updatedState[value][findIndex].bomId = e.target.value
                                             return updatedState;
                                         })
                                     }}>
                                         {products.map((p, i) => {
                                             return (
                                                 <option key={i} value={p.Code}>
                                                     {p.Name}
                                                 </option>
                                             );
                                         })}
                                     </select>
                                 </div>

                                 <div className="mb-1">

                                     <select value={item?.type || ""} className={'form-select'} onChange={(e) => {
                                         setInput((prevState) => {
                                             const updatedState = {...prevState};
                                             updatedState[value] = [
                                                 ...updatedState[value]
                                             ]
                                             let findIndex = updatedState[value].findIndex((e,i) => index === i)
                                             updatedState[value][findIndex].type = e.target.value
                                             return updatedState;
                                         })
                                     }}>
                                         {subtypes.map((p, i) => {
                                             return (
                                                 <option key={i} value={p.value}>
                                                     {p.title}
                                                 </option>
                                             );
                                         })}
                                     </select>
                                 </div>
                                 <div className="mb-1">
                                     <input
                                         type="number"
                                         className="form-control"
                                         placeholder={`Enter Min Length`}
                                         ref={(el) => (inputRefs.current[index] = el)}
                                         value={item.minLength}
                                         onChange={(e) => {
                                             setInput((prevState) => {
                                                 const updatedState = {...prevState};
                                                 updatedState[value] = [
                                                     ...updatedState[value]
                                                 ]
                                                 let findIndex = updatedState[value].findIndex((e,i) => index === i)
                                                 updatedState[value][findIndex].minLength = parseFloat(e.target.value) || 0;
                                                 return updatedState;
                                             })
                                         }}
                                     />
                                 </div>


                                 <div >
                                     <input
                                         type="number"
                                         className="form-control"
                                         placeholder={`Enter Max Length`}
                                         value={item.maxLength}
                                         ref={(el) => (inputRefs.current[index + 100] = el)}
                                         onChange={(e) => {
                                             setInput((prevState) => {
                                                 const updatedState = {...prevState};
                                                 updatedState[value] = [
                                                     ...updatedState[value]
                                                 ]
                                                 let findIndex = updatedState[value].findIndex((e,i) => index === i)
                                                 updatedState[value][findIndex].maxLength = parseFloat(e.target.value) || 0;
                                                 return updatedState;
                                             })
                                         }}
                                     />
                                 </div>



                             </div>)
                         })
                     }
                     <Button className={'btn btn-sm mb-1 mt-1'} onClick={() => setInput((prevState) => {
                         const states = {...prevState};

                         states[value].push({
                             bomId: '',
                             type: 'pe_25mm',
                             minLength: 0,
                             maxLength: 0,
                         });

                         return states;
                     })}>Add Input</Button>
                 </div>
             )

        }
    }


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button className={'m-1'} onClick={handleShow}>{title}</Button>
            <Modal show={show} onHide={handleClose} className={'modal-lg'}>
                <Modal.Header closeButton>
                    <Modal.Title className={'text-sm'}>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={(e) => {
                        console.log(input)
                        e.preventDefault();
                        updateElements(input)
                        handleClose();
                    }} autoComplete="off">

                        <Content/>
                        <div className="d-flex align-items-center">
                            <Button variant="secondary" onClick={handleClose}>
                                <FormattedMessage id="settingsmodal.close" defaultMessage="Close"/>
                                </Button>


                                <button type="submit" className="btn btn-primary ms-auto">
                                    <FormattedMessage id="settingsmodal.save" defaultMessage="Save"/>
                                </button>
                            </div>

                        </form>
                    </Modal.Body>

                </Modal>
        </>
    );
})