import {FormattedMessage, useIntl} from "react-intl";
import logo from '../assets/img/rainshift_logo_big.png';
import {Link,useLocation} from "react-router-dom";

import {observer} from "mobx-react";

import useStores from "../hooks/useStores";
import React, {useEffect} from "react";
import CloseButton from 'react-bootstrap/CloseButton';

export const Sidebar =  observer(({show,sidebarHide}) => {

    useEffect(() => {
        document.body.classList = "geex-dashboard";
    }, []);

    const { uiState } = useStores();
    const location = useLocation();

    const isActive = (path) => location.pathname === path;


    return (
        <div className={"geex-sidebar "+(show?"show":"" )+""}>
            <span  className="geex-sidebar__close" onClick={sidebarHide}>
                <CloseButton/>
            </span>
            <div className="geex-sidebar__wrapper">
                <div className="geex-sidebar__header">
                    <Link to="/dashboard" className="geex-sidebar__logo">
                        <img className="logo-lite" src={logo} alt="logo"/>
                        <img className="logo-dark" src={logo} alt="logo"/>
                    </Link>
                </div>
                <nav className="geex-sidebar__menu-wrapper">
                    <ul className="geex-sidebar__menu">
                        <li className="geex-sidebar__menu__item has-children">
                            <Link to="/dashboard" className={`geex-sidebar__menu__link ${isActive('/dashboard') ? 'active' : ''}`}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1139_9707)">
                                        <path
                                            d="M21.1943 8.31319L14.2413 1.35936C13.3808 0.501345 12.2152 0.0195312 11 0.0195312C9.78482 0.0195312 8.61921 0.501345 7.75868 1.35936L0.805761 8.31319C0.549484 8.56782 0.3463 8.8708 0.207987 9.20454C0.0696733 9.53829 -0.00101787 9.89617 1.10729e-05 10.2574V19.2564C1.10729e-05 19.9857 0.289742 20.6852 0.805467 21.2009C1.32119 21.7166 2.02067 22.0064 2.75001 22.0064H19.25C19.9794 22.0064 20.6788 21.7166 21.1946 21.2009C21.7103 20.6852 22 19.9857 22 19.2564V10.2574C22.001 9.89617 21.9303 9.53829 21.792 9.20454C21.6537 8.8708 21.4505 8.56782 21.1943 8.31319ZM13.75 20.173H8.25001V16.5669C8.25001 15.8375 8.53974 15.138 9.05547 14.6223C9.57119 14.1066 10.2707 13.8169 11 13.8169C11.7294 13.8169 12.4288 14.1066 12.9446 14.6223C13.4603 15.138 13.75 15.8375 13.75 16.5669V20.173ZM20.1667 19.2564C20.1667 19.4995 20.0701 19.7326 19.8982 19.9045C19.7263 20.0764 19.4931 20.173 19.25 20.173H15.5833V16.5669C15.5833 15.3513 15.1005 14.1855 14.2409 13.3259C13.3814 12.4664 12.2156 11.9835 11 11.9835C9.78444 11.9835 8.61865 12.4664 7.75911 13.3259C6.89956 14.1855 6.41668 15.3513 6.41668 16.5669V20.173H2.75001C2.5069 20.173 2.27374 20.0764 2.10183 19.9045C1.92992 19.7326 1.83334 19.4995 1.83334 19.2564V10.2574C1.83419 10.0145 1.93068 9.78168 2.10193 9.60935L9.05485 2.65827C9.57157 2.14396 10.271 1.85522 11 1.85522C11.7291 1.85522 12.4285 2.14396 12.9452 2.65827L19.8981 9.61211C20.0687 9.78375 20.1651 10.0155 20.1667 10.2574V19.2564Z"
                                            fill="#B9BBBD"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1139_9707">
                                            <rect width="22" height="22" fill="white"></rect>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <span> <FormattedMessage id="navbar.dashboard" defaultMessage="Dashboard"/></span>
                            </Link>

                        </li>

                        {
                            uiState.user?.isRoot ? (
                                <>
                                    <li className="geex-sidebar__menu__item has-children">
                                        <Link to="/products" className={`geex-sidebar__menu__link ${isActive('/products') ? 'active' : ''}`}>
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1139_9709)">
                                                    <path
                                                        d="M1.83333 10.0833H11.9167C12.4029 10.0833 12.8692 9.89018 13.213 9.54636C13.5568 9.20255 13.75 8.73623 13.75 8.25V1.83333C13.75 1.3471 13.5568 0.880788 13.213 0.536971C12.8692 0.193154 12.4029 0 11.9167 0L1.83333 0C1.3471 0 0.880788 0.193154 0.536971 0.536971C0.193154 0.880788 0 1.3471 0 1.83333L0 8.25C0 8.73623 0.193154 9.20255 0.536971 9.54636C0.880788 9.89018 1.3471 10.0833 1.83333 10.0833ZM1.83333 1.83333H11.9167V8.25H1.83333V1.83333Z"
                                                        fill="#B9BBBD"></path>
                                                    <path
                                                        d="M20.1673 0H17.4173C16.9311 0 16.4648 0.193154 16.121 0.536971C15.7771 0.880788 15.584 1.3471 15.584 1.83333V8.25C15.584 8.73623 15.7771 9.20255 16.121 9.54636C16.4648 9.89018 16.9311 10.0833 17.4173 10.0833H20.1673C20.6535 10.0833 21.1199 9.89018 21.4637 9.54636C21.8075 9.20255 22.0007 8.73623 22.0007 8.25V1.83333C22.0007 1.3471 21.8075 0.880788 21.4637 0.536971C21.1199 0.193154 20.6535 0 20.1673 0V0ZM20.1673 8.25H17.4173V1.83333H20.1673V8.25Z"
                                                        fill="#B9BBBD"></path>
                                                    <path
                                                        d="M4.58333 11.917H1.83333C1.3471 11.917 0.880788 12.1101 0.536971 12.454C0.193154 12.7978 0 13.2641 0 13.7503L0 20.167C0 20.6532 0.193154 21.1195 0.536971 21.4633C0.880788 21.8072 1.3471 22.0003 1.83333 22.0003H4.58333C5.06956 22.0003 5.53588 21.8072 5.8797 21.4633C6.22351 21.1195 6.41667 20.6532 6.41667 20.167V13.7503C6.41667 13.2641 6.22351 12.7978 5.8797 12.454C5.53588 12.1101 5.06956 11.917 4.58333 11.917ZM4.58333 20.167H1.83333V13.7503H4.58333V20.167Z"
                                                        fill="#B9BBBD"></path>
                                                    <path
                                                        d="M20.1667 11.917H10.0833C9.5971 11.917 9.13079 12.1101 8.78697 12.454C8.44315 12.7978 8.25 13.2641 8.25 13.7503V20.167C8.25 20.6532 8.44315 21.1195 8.78697 21.4633C9.13079 21.8072 9.5971 22.0003 10.0833 22.0003H20.1667C20.6529 22.0003 21.1192 21.8072 21.463 21.4633C21.8068 21.1195 22 20.6532 22 20.167V13.7503C22 13.2641 21.8068 12.7978 21.463 12.454C21.1192 12.1101 20.6529 11.917 20.1667 11.917ZM20.1667 20.167H10.0833V13.7503H20.1667V20.167Z"
                                                        fill="#B9BBBD"></path>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1139_9709">
                                                        <rect width="22" height="22" fill="white"></rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <span><FormattedMessage id="navbar.product"
                                                                    defaultMessage="Products"/></span>
                                        </Link>
                                    </li>

                                    <li className="geex-sidebar__menu__item has-children">
                                        <Link to={'/users'} className={`geex-sidebar__menu__link ${isActive('/users') ? 'active' : ''}`}>
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21"
                                                    stroke="#B9BBBD" strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round"></path>
                                                <path
                                                    d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
                                                    stroke="#B9BBBD" strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round"></path>
                                                <path d="M21 10V16" stroke="#B9BBBD" strokeWidth="2"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"></path>
                                                <path d="M18 13H24" stroke="#B9BBBD" strokeWidth="2"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"></path>
                                            </svg>
                                            <span><FormattedMessage id="navbar.users" defaultMessage="Users"/></span>
                                        </Link>
                                    </li>
                                </>
                            ) : null
                        }


                        <li className="geex-sidebar__menu__item has-children">
                            <Link to="/draw" className="geex-sidebar__menu__link">
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 2L2 7L11 12L20 7L11 2Z" stroke="#B9BBBD" strokeWidth="2"
                                          strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M2 17L11 22L20 17" stroke="#B9BBBD" strokeWidth="2" strokeLinecap="round"
                                          strokeLinejoin="round"></path>
                                    <path d="M2 12L11 17L20 12" stroke="#B9BBBD" strokeWidth="2" strokeLinecap="round"
                                          strokeLinejoin="round"></path>
                                </svg>
                                <span> <FormattedMessage id="dashboard.create" defaultMessage="New Project"/></span>
                            </Link>
                        </li>


                    </ul>
                </nav>
                <div className="geex-sidebar__footer">
                    <span className="geex-sidebar__footer__title">RainShift Dashboard</span>
                    <p className="geex-sidebar__footer__copyright">© 2024 All Rights Reserved</p>
                    <p className="geex-sidebar__footer__author">Made with <span className="heart-icon">♥</span></p>
                </div>
            </div>
        </div>
    )
});