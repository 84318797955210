import React from "react";

export const Watermark = ({ ...props }) => (
    <g className="watermark" {...props}>
      <g transform="translate(-70,-18)" fill="#3e4346" style={{ opacity: 0.6 }}>
        <g transform="translate(30 35) scale(0.01 -0.01)">
          <path d="M406 3109 c-146 -19 -266 -65 -334 -128 -90 -84 -83 -216 21 -384 47
-74 78 -110 64 -73 -4 10 -10 42 -13 72 -7 54 -6 55 35 94 69 66 246 110 443
110 242 0 563 -85 627 -166 32 -40 25 -103 -22 -197 -94 -186 -306 -351 -471
-364 -45 -4 -70 -2 -81 7 -21 17 -19 117 5 315 37 300 23 351 -88 326 -26 -5
-68 -13 -94 -16 -59 -8 -104 -43 -131 -104 -15 -34 -22 -79 -27 -184 -8 -151
-19 -179 -57 -158 -40 21 -95 -10 -119 -66 -30 -71 -11 -107 91 -175 35 -23
66 -47 69 -53 2 -6 -2 -133 -10 -281 -15 -295 -17 -444 -8 -468 8 -20 44 -20
108 -1 66 20 104 52 144 117 46 78 59 148 42 236 -12 66 -12 72 7 104 11 18
26 33 34 32 8 -1 130 -87 271 -191 387 -287 584 -413 645 -413 65 0 124 120
158 322 22 130 18 146 -23 86 -22 -32 -40 -48 -55 -48 -11 0 -64 26 -117 57
-171 102 -720 473 -720 487 0 9 25 15 93 20 286 21 477 84 602 198 72 66 96
125 96 234 0 127 -20 192 -89 297 -81 124 -229 224 -422 285 -223 70 -472 97
-674 71z"/>
          <path d="M6195 3110 c-237 -24 -432 -86 -583 -187 -95 -63 -115 -90 -137 -185
-23 -104 -20 -152 12 -177 49 -39 68 -37 124 13 153 138 344 200 656 213 193
7 227 -1 294 -74 l42 -45 -6 -74 c-4 -58 -3 -74 8 -74 21 0 133 128 160 184
29 59 46 164 34 214 -16 73 -92 140 -197 173 -78 25 -261 33 -407 19z"/>
          <path d="M9628 2903 c-24 -6 -24 -98 -2 -278 9 -71 14 -167 12 -212 -4 -106
-16 -119 -117 -132 -59 -8 -79 -15 -100 -36 -25 -24 -26 -31 -25 -123 1 -136
36 -203 112 -218 35 -7 43 7 18 35 -22 25 -27 56 -14 74 17 23 102 27 122 7
11 -12 16 -96 26 -385 l13 -370 29 -42 c50 -69 148 -130 254 -156 73 -19 164
-31 164 -23 0 4 -7 17 -16 31 -17 26 -10 47 39 113 l25 33 -51 67 c-103 137
-128 221 -147 510 -16 227 -17 224 102 221 69 -3 80 0 103 21 31 29 57 107 59
177 1 46 -3 58 -23 74 -27 22 -102 26 -164 8 -87 -24 -102 3 -116 203 -15 226
-20 240 -94 317 -81 83 -133 104 -209 84z"/>
          <path d="M8804 2858 c-93 -98 -105 -129 -46 -121 42 5 90 -10 98 -31 3 -9 -7
-29 -25 -48 -72 -76 -107 -205 -96 -350 3 -48 4 -96 0 -107 -7 -22 -43 -36
-119 -46 -68 -9 -96 -39 -96 -103 0 -72 13 -84 103 -91 42 -4 81 -11 87 -17 7
-7 14 -130 20 -325 6 -173 14 -343 19 -378 7 -58 12 -68 47 -99 42 -37 92 -62
123 -62 11 0 32 10 47 23 l27 23 -11 190 c-17 278 -26 578 -18 607 11 47 103
71 174 46 51 -17 58 -60 33 -194 -11 -60 -31 -141 -45 -180 -18 -50 -23 -79
-18 -103 4 -18 11 -33 16 -33 21 0 96 218 96 281 0 19 11 78 25 130 30 117 32
193 5 237 -26 43 -38 48 -165 72 -60 11 -113 25 -118 30 -16 16 -4 371 13 406
19 38 55 43 107 16 59 -29 67 -43 59 -101 -5 -39 -4 -50 7 -50 16 0 69 120 92
208 l16 57 -33 28 c-51 44 -166 95 -250 112 -117 23 -128 21 -174 -27z"/>
          <path d="M6990 2872 l-25 -20 -3 -239 c-5 -290 13 -795 34 -996 l16 -148 -26
-98 c-14 -54 -26 -112 -26 -129 0 -57 74 -122 163 -142 129 -29 142 15 122
425 l-7 140 44 60 c82 110 161 164 257 172 46 4 60 2 81 -15 l25 -20 5 -299 5
-298 29 -58 c35 -73 89 -110 180 -126 104 -18 96 -29 96 122 0 271 -33 747
-56 819 -30 93 -104 128 -240 114 -149 -15 -229 -72 -340 -240 -38 -58 -72
-106 -75 -106 -11 0 -18 40 -25 150 -8 124 2 196 58 440 22 96 42 210 45 253
l6 78 -46 44 c-46 44 -187 120 -242 131 -22 4 -38 0 -55 -14z"/>
          <path d="M3595 2822 c-142 -70 -256 -254 -215 -351 22 -54 103 -101 172 -101
42 0 94 54 103 106 9 55 21 76 83 147 87 99 89 103 69 140 -22 40 -74 76 -118
83 -25 4 -51 -3 -94 -24z"/>
          <path d="M8305 2822 c-142 -70 -256 -254 -215 -351 22 -54 103 -101 172 -101
42 0 94 54 103 106 9 55 21 76 83 147 87 99 89 103 69 140 -22 40 -74 76 -118
83 -25 4 -51 -3 -94 -24z"/>
          <path d="M5440 2415 c0 -77 12 -105 72 -161 91 -87 211 -151 340 -183 47 -11
205 -41 350 -66 420 -72 431 -75 443 -122 6 -25 1 -34 -47 -77 -157 -144 -420
-271 -668 -323 -124 -26 -384 -26 -424 0 -27 18 -28 21 -21 75 4 32 15 75 24
97 22 50 93 119 171 166 32 20 56 39 53 43 -11 11 -165 6 -243 -8 -144 -26
-217 -73 -240 -154 -14 -52 -12 -208 4 -267 65 -247 318 -366 691 -326 217 24
393 74 560 159 112 57 245 184 288 273 47 100 60 196 32 249 -10 19 -14 54
-13 103 1 64 -3 84 -30 140 -55 118 -154 188 -329 233 -54 14 -233 47 -398 75
-321 54 -469 85 -556 118 -30 12 -55 21 -56 21 -2 0 -3 -29 -3 -65z"/>
          <path d="M3933 2313 c-18 -2 -33 -9 -33 -15 0 -6 9 -63 20 -127 39 -232 54
-635 25 -704 -17 -42 -20 -164 -5 -218 25 -91 85 -138 185 -147 l64 -5 15 54
c35 119 46 197 46 329 l0 135 45 79 c89 157 247 346 288 346 41 0 49 -14 54
-91 22 -398 56 -612 119 -745 44 -92 95 -114 174 -74 67 34 169 195 185 290
l6 35 -29 -33 c-24 -27 -34 -32 -56 -26 -24 6 -26 10 -26 58 0 28 -7 94 -15
146 -8 52 -20 142 -26 200 -22 213 -61 332 -130 396 -98 91 -216 63 -434 -101
-146 -110 -186 -103 -211 40 -7 42 -22 89 -34 104 -38 55 -137 87 -227 74z"/>
          <path d="M2595 2256 c-265 -63 -560 -299 -688 -551 -81 -161 -76 -364 11 -466
45 -52 83 -68 180 -75 219 -16 422 94 631 344 71 85 99 112 117 112 21 0 29
-11 26 -40 -35 -386 -16 -542 72 -584 100 -47 187 18 252 190 41 111 70 275
44 259 -5 -3 -22 1 -38 9 l-29 15 -7 128 c-4 70 -11 203 -15 294 -11 205 -16
219 -91 237 -32 8 -81 32 -118 58 -35 24 -76 51 -93 59 -42 22 -180 28 -254
11z m321 -235 c-15 -167 -80 -281 -213 -375 -165 -117 -377 -216 -462 -216
-55 0 -75 26 -67 84 22 148 217 364 449 499 100 58 153 75 236 76 l64 1 -7
-69z"/>
          <path d="M3545 2232 c-72 -25 -123 -67 -134 -111 -26 -100 -35 -211 -35 -426
0 -249 17 -418 54 -539 23 -73 43 -91 93 -80 42 9 137 101 188 182 38 61 79
144 79 162 0 4 -17 6 -39 3 l-39 -5 -6 58 c-15 136 -26 362 -26 545 0 152 -3
199 -13 208 -25 20 -71 21 -122 3z"/>
          <path d="M8255 2232 c-72 -25 -123 -67 -134 -111 -26 -100 -35 -211 -35 -426
0 -249 17 -418 54 -539 23 -73 43 -91 93 -80 42 9 137 101 188 182 38 61 79
144 79 162 0 4 -17 6 -39 3 l-39 -5 -6 58 c-15 136 -26 362 -26 545 0 152 -3
199 -13 208 -25 20 -71 21 -122 3z"/>
          <path d="M1065 571 c-11 -5 -29 -19 -40 -31 -18 -20 -20 -36 -20 -206 l0 -184
28 -27 c23 -23 36 -28 78 -28 43 0 54 4 74 27 27 32 38 87 33 178 l-3 65 -50
0 c-49 0 -50 -1 -53 -32 -3 -28 0 -33 18 -33 19 0 21 -5 18 -67 -3 -67 -4 -68
-32 -71 -16 -2 -32 2 -37 10 -5 7 -9 81 -9 163 0 83 4 156 9 163 5 8 21 12 37
10 26 -3 29 -7 32 -45 3 -42 4 -43 39 -43 l36 0 -7 47 c-3 26 -13 57 -22 70
-24 38 -82 53 -129 34z"/>
          <path d="M2155 571 c-11 -5 -29 -19 -40 -31 -18 -20 -20 -36 -20 -206 l0 -184
28 -27 c23 -23 36 -28 78 -28 43 0 54 4 74 27 27 32 38 87 33 178 l-3 65 -50
0 c-49 0 -50 -1 -53 -32 -3 -28 0 -33 18 -33 19 0 21 -5 18 -67 -3 -67 -4 -68
-32 -71 -16 -2 -32 2 -37 10 -5 7 -9 81 -9 163 0 83 4 156 9 163 5 8 21 12 37
10 26 -3 29 -7 32 -45 3 -42 4 -43 39 -43 l36 0 -7 47 c-3 26 -13 57 -22 70
-24 38 -82 53 -129 34z"/>
          <path d="M2585 571 c-11 -5 -29 -19 -40 -31 -18 -20 -20 -36 -20 -206 l0 -184
28 -27 c23 -23 36 -28 78 -28 43 0 54 4 74 27 27 32 38 87 33 178 l-3 65 -50
0 c-49 0 -50 -1 -53 -32 -3 -28 0 -33 18 -33 19 0 21 -5 18 -67 -3 -67 -4 -68
-32 -71 -16 -2 -32 2 -37 10 -5 7 -9 81 -9 163 0 83 4 156 9 163 5 8 21 12 37
10 26 -3 29 -7 32 -45 3 -42 4 -43 39 -43 l36 0 -7 47 c-3 26 -13 57 -22 70
-24 38 -82 53 -129 34z"/>
          <path d="M4895 571 c-11 -5 -29 -19 -40 -31 -18 -20 -20 -36 -20 -205 0 -180
0 -184 24 -209 47 -50 147 -42 176 14 21 41 21 348 0 389 -21 42 -90 62 -140
42z m80 -236 l0 -170 -29 -3 c-16 -2 -32 2 -37 10 -10 15 -12 298 -3 323 5 11
16 15 38 13 l31 -3 0 -170z"/>
          <path d="M6115 571 c-11 -5 -29 -19 -40 -31 -18 -20 -20 -36 -20 -205 0 -180
0 -184 24 -209 47 -50 147 -42 176 14 21 41 21 348 0 389 -21 42 -90 62 -140
42z m80 -236 l0 -170 -29 -3 c-16 -2 -32 2 -37 10 -10 15 -12 298 -3 323 5 11
16 15 38 13 l31 -3 0 -170z"/>
          <path d="M6835 571 c-11 -5 -29 -19 -40 -31 -18 -20 -20 -36 -20 -205 0 -180
0 -184 24 -209 47 -50 147 -42 176 14 21 41 21 348 0 389 -21 42 -90 62 -140
42z m80 -236 l0 -170 -29 -3 c-16 -2 -32 2 -37 10 -10 15 -12 298 -3 323 5 11
16 15 38 13 l31 -3 0 -170z"/>
          <path d="M7865 571 c-11 -5 -29 -19 -40 -31 -18 -20 -20 -36 -20 -205 0 -180
0 -184 24 -209 47 -50 147 -42 176 14 21 41 21 348 0 389 -21 42 -90 62 -140
42z m80 -236 l0 -170 -29 -3 c-16 -2 -32 2 -37 10 -10 15 -12 298 -3 323 5 11
16 15 38 13 l31 -3 0 -170z"/>
          <path d="M8125 571 c-11 -5 -29 -19 -40 -31 -18 -20 -20 -36 -20 -205 0 -180
0 -184 24 -209 47 -50 147 -42 176 14 21 41 21 348 0 389 -21 42 -90 62 -140
42z m80 -236 l0 -170 -29 -3 c-16 -2 -32 2 -37 10 -10 15 -12 298 -3 323 5 11
16 15 38 13 l31 -3 0 -170z"/>
          <path d="M9135 571 c-11 -5 -29 -19 -40 -31 -18 -20 -20 -36 -20 -206 0 -183
0 -184 26 -209 46 -47 131 -42 164 9 9 13 19 50 22 80 l6 56 -36 0 -36 0 -3
-52 c-3 -50 -5 -53 -32 -56 -16 -2 -32 2 -37 10 -5 7 -9 81 -9 163 0 83 4 156
9 163 5 8 21 12 37 10 27 -3 29 -6 32 -50 3 -45 5 -48 32 -48 16 0 32 5 35 11
12 19 -6 99 -28 125 -24 27 -87 40 -122 25z"/>
          <path d="M10 336 l0 -236 65 0 c86 0 129 18 144 60 23 67 6 168 -31 183 -9 4
-10 8 -2 14 22 16 34 53 34 102 0 38 -6 56 -24 78 -22 25 -31 28 -105 31 l-81
4 0 -236z m138 125 c6 -59 -5 -81 -38 -81 -30 0 -30 0 -30 61 l0 60 33 -3 c28
-3 32 -7 35 -37z m0 -163 c13 -13 16 -85 6 -112 -4 -10 -18 -16 -40 -16 l-34
0 0 70 c0 70 0 70 28 70 15 0 33 -5 40 -12z"/>
          <path d="M270 335 l0 -235 95 0 95 0 0 35 0 35 -60 0 -60 0 0 70 0 70 50 0 50
0 0 35 0 35 -50 0 -50 0 0 60 0 60 60 0 60 0 0 35 0 35 -95 0 -95 0 0 -235z"/>
          <path d="M510 336 l0 -236 35 0 35 0 0 100 c0 99 0 100 24 100 37 0 45 -19 48
-110 l3 -85 35 -3 35 -3 -5 100 c-4 80 -9 103 -25 121 -12 14 -15 24 -9 28 22
14 34 52 34 106 0 44 -5 61 -24 83 -22 25 -31 28 -105 31 l-81 4 0 -236z m138
120 c5 -65 -4 -86 -38 -86 l-30 0 0 66 0 65 33 -3 c29 -3 32 -6 35 -42z"/>
          <path d="M780 335 l0 -235 95 0 95 0 0 35 0 35 -60 0 -60 0 0 70 0 70 50 0 50
0 0 35 0 35 -50 0 -50 0 0 60 0 60 60 0 60 0 0 35 0 35 -95 0 -95 0 0 -235z"/>
          <path d="M1270 335 l0 -235 30 0 30 0 2 168 3 167 23 -90 c66 -260 60 -245 98
-245 l34 0 0 235 0 235 -30 0 -29 0 -3 -137 -3 -137 -35 137 -35 137 -42 0
-43 0 0 -235z"/>
          <path d="M1550 370 c0 -223 5 -247 59 -270 41 -17 92 -6 122 26 24 25 24 29
27 235 l3 209 -35 0 -36 0 -2 -202 -3 -203 -30 0 -30 0 -3 203 -2 202 -35 0
-35 0 0 -200z"/>
          <path d="M1820 335 l0 -235 30 0 30 0 2 168 3 167 23 -90 c66 -260 60 -245 98
-245 l34 0 0 235 0 235 -30 0 -29 0 -3 -137 -3 -137 -35 137 -35 137 -42 0
-43 0 0 -235z"/>
          <path d="M2845 558 c-2 -7 -18 -103 -35 -213 -16 -110 -32 -210 -35 -222 -5
-21 -2 -23 30 -23 30 0 35 3 35 23 0 57 10 68 54 65 40 -3 41 -4 46 -43 5 -38
7 -40 41 -43 l35 -3 -37 233 c-21 128 -38 234 -39 236 -1 9 -91 0 -95 -10z
m74 -275 c1 -30 -2 -33 -29 -33 -18 0 -30 5 -30 13 0 27 21 182 27 197 6 16
31 -120 32 -177z"/>
          <path d="M3050 336 l0 -236 35 0 35 0 0 100 c0 99 0 100 24 100 37 0 45 -19
48 -110 l3 -85 35 -3 35 -3 -5 100 c-4 80 -9 103 -25 121 -12 14 -15 24 -9 28
22 14 34 52 34 106 0 44 -5 61 -24 83 -22 25 -31 28 -105 31 l-81 4 0 -236z
m138 120 c5 -65 -4 -86 -38 -86 l-30 0 0 66 0 65 33 -3 c29 -3 32 -6 35 -42z"/>
          <path d="M3300 535 c0 -33 2 -35 35 -35 l35 0 0 -200 0 -200 35 0 35 0 0 199
0 200 38 3 c34 3 37 6 40 36 l3 32 -110 0 -111 0 0 -35z"/>
          <path d="M3560 335 l0 -235 95 0 95 0 0 35 0 35 -60 0 -60 0 0 70 0 70 50 0
50 0 0 35 0 35 -50 0 -50 0 0 60 0 60 60 0 60 0 0 35 0 35 -95 0 -95 0 0 -235z"/>
          <path d="M3790 335 l0 -235 30 0 30 0 2 168 3 167 23 -90 c66 -260 60 -245 98
-245 l34 0 0 235 0 235 -30 0 -29 0 -3 -137 -3 -137 -35 137 -35 137 -42 0
-43 0 0 -235z"/>
          <path d="M4135 558 c-2 -7 -18 -103 -35 -213 -16 -110 -32 -210 -35 -222 -5
-21 -2 -23 30 -23 30 0 35 3 35 23 0 57 10 68 54 65 40 -3 41 -4 46 -43 5 -38
7 -40 41 -43 l35 -3 -37 233 c-21 128 -38 234 -39 236 -1 9 -91 0 -95 -10z
m74 -275 c1 -30 -2 -33 -29 -33 -18 0 -30 5 -30 13 0 27 21 182 27 197 6 16
31 -120 32 -177z"/>
          <path d="M4340 370 c0 -223 5 -247 59 -270 41 -17 92 -6 122 26 24 25 24 29
27 235 l3 209 -35 0 -36 0 -2 -202 -3 -203 -30 0 -30 0 -3 203 -2 202 -35 0
-35 0 0 -200z"/>
          <path d="M4580 535 c0 -33 2 -35 35 -35 l35 0 0 -200 0 -200 35 0 35 0 0 199
0 200 38 3 c34 3 37 6 40 36 l3 32 -110 0 -111 0 0 -35z"/>
          <path d="M5100 335 l0 -235 30 0 30 0 1 173 1 172 27 -170 26 -170 30 0 30 0
26 170 27 170 1 -172 1 -173 35 0 35 0 0 236 0 235 -52 -3 -53 -3 -21 -158
c-12 -87 -24 -160 -26 -162 -2 -3 -12 55 -22 128 -28 217 -20 197 -77 197
l-49 0 0 -235z"/>
          <path d="M5515 558 c-2 -7 -18 -103 -35 -213 -16 -110 -32 -210 -35 -222 -5
-21 -2 -23 30 -23 30 0 35 3 35 23 0 57 10 68 54 65 40 -3 41 -4 46 -43 5 -38
7 -40 41 -43 l35 -3 -37 233 c-21 128 -38 234 -39 236 -1 9 -91 0 -95 -10z
m74 -275 c1 -30 -2 -33 -29 -33 -18 0 -30 5 -30 13 0 27 21 182 27 197 6 16
31 -120 32 -177z"/>
          <path d="M5670 535 c0 -33 2 -35 35 -35 l35 0 0 -200 0 -200 35 0 35 0 0 199
0 200 38 3 c34 3 37 6 40 36 l3 32 -110 0 -111 0 0 -35z"/>
          <path d="M5930 335 l0 -235 35 0 35 0 0 235 0 235 -35 0 -35 0 0 -235z"/>
          <path d="M6320 335 l0 -235 30 0 30 0 2 168 3 167 23 -90 c66 -260 60 -245 98
-245 l34 0 0 235 0 235 -30 0 -29 0 -3 -137 -3 -137 -35 137 -35 137 -42 0
-43 0 0 -235z"/>
          <path d="M7040 370 c0 -223 5 -247 59 -270 41 -17 92 -6 122 26 24 25 24 29
27 235 l3 209 -35 0 -36 0 -2 -202 -3 -203 -30 0 -30 0 -3 203 -2 202 -35 0
-35 0 0 -200z"/>
          <path d="M7280 535 c0 -33 2 -35 35 -35 l35 0 0 -200 0 -200 35 0 35 0 0 199
0 200 38 3 c34 3 37 6 40 36 l3 32 -110 0 -111 0 0 -35z"/>
          <path d="M7540 335 l0 -235 65 0 c76 0 111 14 131 51 18 36 20 325 2 364 -17
38 -58 55 -133 55 l-65 0 0 -235z m135 0 l0 -160 -32 -3 -33 -3 0 166 0 166
33 -3 32 -3 0 -160z"/>
          <path d="M8340 336 l0 -236 35 0 35 0 0 100 c0 99 0 100 24 100 37 0 45 -19
48 -110 l3 -85 35 -3 35 -3 -5 100 c-4 80 -9 103 -25 121 -12 14 -15 24 -9 28
22 14 34 52 34 106 0 44 -5 61 -24 83 -22 25 -31 28 -105 31 l-81 4 0 -236z
m138 120 c5 -65 -4 -86 -38 -86 l-30 0 0 66 0 65 33 -3 c29 -3 32 -6 35 -42z"/>
          <path d="M8580 535 c0 -33 2 -35 35 -35 l35 0 0 -200 0 -200 35 0 35 0 0 199
0 200 38 3 c34 3 37 6 40 36 l3 32 -110 0 -111 0 0 -35z"/>
          <path d="M8840 335 l0 -235 95 0 95 0 0 35 0 35 -60 0 -60 0 0 70 0 70 50 0
50 0 0 35 0 35 -50 0 -50 0 0 60 0 60 60 0 60 0 0 35 0 35 -95 0 -95 0 0 -235z"/>
          <path d="M9330 335 l0 -235 35 0 35 0 0 105 0 105 40 0 40 0 0 -105 0 -105 35
0 35 0 0 235 0 235 -35 0 -35 0 0 -95 0 -95 -40 0 -40 0 0 95 0 95 -35 0 -35
0 0 -235z"/>
          <path d="M9610 335 l0 -235 30 0 30 0 2 168 3 167 23 -90 c66 -260 60 -245 98
-245 l34 0 0 235 0 235 -30 0 -29 0 -3 -137 -3 -137 -35 137 -35 137 -42 0
-43 0 0 -235z"/>
          <path d="M9890 335 l0 -235 35 0 35 0 0 235 0 235 -35 0 -35 0 0 -235z"/>
          <path d="M10010 335 l0 -235 35 0 35 0 0 74 c0 41 6 89 14 107 l14 33 27 -89
c14 -50 29 -98 31 -107 4 -13 16 -18 40 -18 19 0 34 3 34 6 0 4 -19 70 -41
147 l-41 140 40 89 40 88 -32 0 c-32 0 -35 -3 -79 -97 l-46 -98 0 98 -1 97
-35 0 -35 0 0 -235z"/>
          <path d="M2340 335 l0 -35 70 0 70 0 0 35 0 35 -70 0 -70 0 0 -35z"/>
          <path d="M6590 335 l0 -35 70 0 70 0 0 35 0 35 -70 0 -70 0 0 -35z"/>
        </g>
      </g>
    </g>
);
