import { findFittingByTubeType } from "../common-utils"

function raisedBedKits(settings, plan) {
  const { bomList, elements } = settings
  const { raisedBed, raisedBedTube, driplineConnectionPoints } = elements

  let bomItems = []
  if (plan.raisedBeds.length === 0) return bomItems

  const quantity = plan.raisedBeds.length

  bomItems.push({
    bomId: raisedBed.bomId,
    qualityList: bomList[raisedBed.bomId],
    quantity
  })

  bomItems.push({
    bomId: raisedBedTube.bomId,
    qualityList: bomList[raisedBedTube.bomId],
    quantity
  })

  const connectionPoint = findFittingByTubeType(
      plan.irrigationTubeType,
      driplineConnectionPoints
  )
  bomItems.push({
    bomId: connectionPoint.bomId,
    qualityList: bomList[connectionPoint.bomId],
    quantity
  })

  return bomItems
}

export { raisedBedKits }
