import React, {useEffect, useState} from 'react';
import {Layout} from "../../components/Layout";
import {observer} from "mobx-react";
import {Button, Container, Modal, Pagination, Spinner, Table} from "react-bootstrap";
import {FormattedMessage, useIntl} from "react-intl";

import axios from "axios";
import {toast} from "react-toastify";
import {config} from "../../config";

const Users = observer(() => {
    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);
    const [page,setPage] = useState(1);
    const intl = useIntl();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loading, setLoading] = useState(false)
    const [ form, setForm] = useState({
        id: '',
        first_name: '',
        last_name: '',
        password:'',
        email: '',
        root: '0',
        status: '0'
    })

    const [addShow, setAddShow] = useState(false);
    const [editShow, setEditShow] = useState(false);

    const handleClose = () => {
        setForm({
            id: '',
            first_name: '',
            last_name: '',
            password: '',
            email: '',
            root: '0',
            status: '0'
        })
        setAddShow(false)
        setEditShow(false);
    };

    const addHandleShow = () => {
        setAddShow(true)
    };

    useEffect(() => {
        getList();
    }, [page]);

    const getList = async () => {
        try{
            const { data:{ total, list: lists } } = await axios.get(config.apiUrl+'/user/list/'+page,{
                headers:{
                    'x-access-token': localStorage.getItem('authKey'),
                    'x-access-path':'list-user'
                }
            });
            setTotal(total);
            setList(lists)
        }catch (e) {
            let message = 'global.error';
            if(e){
                if(e.response){
                    if(e.response.status === 400){
                        if(e.response.data){
                            message = e.response.data.message[0].msg
                        }
                    }
                }
            }
            toast.error(intl.formatMessage({
                id:message,
                defaultMessage:'An error occurred please try again'
            }))
        }
    }

    const addAction = async (e) => {
        e.preventDefault();
        setLoading(true)
        try{
            await axios.post(config.apiUrl+'/user/add', form,{
                headers:{
                    'x-access-token': localStorage.getItem('authKey'),
                    'x-access-path':'add-user'
                }
            });

            setAddShow(false)
            setForm({
                first_name: null,
                last_name: null,
                password: null,
                email: null,
                root: '0',
                status: '0'
            })
            toast.success(intl.formatMessage({
                id: 'user.add_success',
                defaultMessage:'User added successfully'
            }))
            getList();

        }catch (e) {
            let message = 'global.error';
            if(e){
                if(e.response){
                    if(e.response.status === 400){
                        if(e.response.data){
                            message = e.response.data.message[0].msg
                        }
                    }
                }
            }
            toast.error(intl.formatMessage({
                id:message,
                defaultMessage:'An error occurred please try again'
            }))
        }

        setLoading(false)
    }

    const editAction = async (e) => {
        e.preventDefault();
        setLoading(true)
        try{
            await axios.post(config.apiUrl+'/user/update/'+form.id, form,{
                headers:{
                    'x-access-token': localStorage.getItem('authKey'),
                    'x-access-path':'edit-user'
                }
            });

            setEditShow(false)
            setForm({
                first_name: null,
                last_name: null,
                password: null,
                email: null,
                root: '0',
                status: '0'
            })
            toast.success(intl.formatMessage({
                id: 'user.update_success',
                defaultMessage:'User information updated successfully'
            }))
          getList()
        }catch (e) {
            let message = 'global.error';
            if(e){
                if(e.response){
                    if(e.response.status === 400){
                        if(e.response.data){
                            message = e.response.data.message[0].msg
                        }
                    }
                }
            }
            toast.error(intl.formatMessage({
                id:message,
                defaultMessage:'An error occurred please try again'
            }))
        }

        setLoading(false)
    }

    const deleteAction = async (id) => {

        setLoading(true)
        try{
            await axios.post(config.apiUrl+'/user/delete/', {
                id
            },{
                headers:{
                    'x-access-token': localStorage.getItem('authKey'),
                    'x-access-path':'delete-user'
                }
            });

            toast.success(intl.formatMessage({
                id: 'user.delete_success',
                defaultMessage:'User deleted successfully'
            }))
            getList();
        }catch (e) {
            let message = 'global.error';
            if(e){
                if(e.response){
                    if(e.response.status === 400){
                        if(e.response.data){
                            message = e.response.data.message[0].msg
                        }
                    }
                }
            }
            toast.error(intl.formatMessage({
                id:message,
                defaultMessage:'An error occurred please try again'
            }))
        }

        setLoading(false)
    }

    const editHandleShow = async (id) => {
        try{
           const { data: user } = await axios.get(config.apiUrl+'/user/get/'+id,{
                headers:{
                    'x-access-token': localStorage.getItem('authKey'),
                    'x-access-path':'get-user'
                }
            });

            setForm({
                id: user.ID,
                first_name: user.FirstName,
                last_name:  user.LastName,
                password: null,
                email: user.Mail,
                root: user.Root,
                status:  user.Status,
            })
            setEditShow(true);
        }catch (e) {
            let message = 'global.error';
            if(e){
                if(e.response){
                    if(e.response.status === 400){
                        if(e.response.data){
                            message = e.response.data.message[0].msg
                        }
                    }
                }
            }
            toast.error(intl.formatMessage({
                id:message,
                defaultMessage:'An error occurred please try again'
            }))
        }
    }

    const renderPageItem = (number) => (
        <Pagination.Item key={number} active={number === page} onClick={() => setPage(number)}>
            {number}
        </Pagination.Item>
    );

    const Pages = () => {
        let items = [];
        let totalPages = Math.round(total / 10);

        if (totalPages <= 5) {
            for (let number = 1; number <= totalPages; number++) {
                items.push(renderPageItem(number));
            }
        } else {
            if (page > 3) {
                items.push(renderPageItem(1));
                items.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }

            const startPage = Math.max(1, page - 2);
            const endPage = Math.min(totalPages, page + 2);

            for (let number = startPage; number <= endPage; number++) {
                items.push(renderPageItem(number));
            }

            if (page < totalPages - 2) {
                items.push(<Pagination.Ellipsis key="end-ellipsis" />);
                items.push(renderPageItem(totalPages));
            }
        }

        return <Pagination>{items}</Pagination>;
    };

    return (
        <Layout title={'Users'}>
            <Modal show={addShow} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className={'text-sm'}><FormattedMessage id="user.add_title" defaultMessage="Add User"/></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={addAction} autoComplete="off">
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="first_name">
                                <FormattedMessage id="user.first_name" defaultMessage="First Name"/>
                            </label>
                            <input id="first_name" value={form.first_name} onChange={(e) => {
                                setForm({
                                    ...form,
                                    first_name: e.target.value
                                })
                            }} placeholder={intl.formatMessage({
                                id: 'user.first_name_placeholder',
                                defaultMessage: 'Please enter first name'
                            })} type="text" className="form-control" autoFocus/>


                        </div>

                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="last_name">
                                <FormattedMessage id="user.last_name" defaultMessage="Last Name"/>
                            </label>
                            <input id="last_name" value={form.last_name} onChange={(e) => {
                                setForm({
                                    ...form,
                                    last_name: e.target.value
                                })
                            }} placeholder={intl.formatMessage({
                                id: 'user.last_name_placeholder',
                                defaultMessage: 'Please enter last name'
                            })} type="text" className="form-control"/>


                        </div>

                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="email">
                                <FormattedMessage id="user.email" defaultMessage="E-Mail Address"/>
                            </label>
                            <input id="email" value={form.email} onChange={(e) => {
                                setForm({
                                    ...form,
                                    email: e.target.value
                                })
                            }} placeholder={intl.formatMessage({
                                id: 'user.email_placeholder',
                                defaultMessage: 'Please enter an email'
                            })} type="email" className="form-control" name="email"/>

                        </div>

                        <div className="mb-3">
                            <div className="mb-2 w-100">
                                <label className="text-muted" htmlFor="password">
                                    <FormattedMessage id="user.password" defaultMessage="Password"/>
                                </label>
                            </div>
                            <input id="password" onChange={(e) => {
                                setForm({
                                    ...form,
                                    password: e.target.value
                                })
                            }} type="password" placeholder={intl.formatMessage({
                                id: 'user.password_placeholder',
                                defaultMessage: 'Please enter a password'
                            })} className="form-control"/>
                        </div>

                        <div className="mb-3">
                            <div className="mb-2 w-100">
                                <label className="text-muted" htmlFor="status">
                                    <FormattedMessage id="user.status" defaultMessage="Status"/>
                                </label>
                            </div>

                            <select value={form.status} onChange={(e) => {
                                setForm({
                                    ...form,
                                    status: e.target.value
                                })
                            }} className={'form-select'} name="status" id="status">
                                <option value="0">{intl.formatMessage({
                                    id: 'user.disable',
                                    defaultMessage: 'Disable'
                                })}</option>
                                <option value="1">{intl.formatMessage({
                                    id: 'user.enable',
                                    defaultMessage: 'Enable'
                                })}</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <div className="mb-2 w-100">
                                <label className="text-muted" htmlFor="root">
                                    <FormattedMessage id="user.root" defaultMessage="Root Access"/>
                                </label>
                            </div>

                            <select value={form.root} onChange={(e) => {
                                setForm({
                                    ...form,
                                    root: e.target.value
                                })
                            }} className={'form-select'} name="root" id="root">
                                <option value="0">{intl.formatMessage({
                                    id: 'user.disable',
                                    defaultMessage: 'Disable'
                                })}</option>
                                <option value="1">{intl.formatMessage({
                                    id: 'user.enable',
                                    defaultMessage: 'Enable'
                                })}</option>
                            </select>
                        </div>

                        <div className="d-flex align-items-center">

                            <Button variant="secondary" onClick={handleClose}>
                                <FormattedMessage id="navbar.edit_profile_close" defaultMessage="Close"/>
                            </Button>


                            <button type="submit" className="btn btn-primary ms-auto" disabled={loading}>
                                {
                                    loading ? (
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span> <FormattedMessage id="system.loading"
                                                                     defaultMessage="Loading..."/></span>
                                        </>
                                    ) : (
                                        <FormattedMessage id="user.add_button" defaultMessage="Add"/>
                                    )
                                }
                            </button>
                        </div>

                    </form>
                </Modal.Body>

            </Modal>
            <Modal show={editShow} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className={'text-sm'}><FormattedMessage id="user.edit_title"
                                                                         defaultMessage="Edit User"/></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={editAction} autoComplete="off">
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="first_name">
                                <FormattedMessage id="user.first_name" defaultMessage="First Name"/>
                            </label>
                            <input id="first_name" value={form.first_name} onChange={(e) => {
                                setForm({
                                    ...form,
                                    first_name: e.target.value
                                })
                            }} placeholder={intl.formatMessage({
                                id: 'user.first_name_placeholder',
                                defaultMessage: 'Please enter first name'
                            })} type="text" className="form-control" autoFocus/>


                        </div>

                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="last_name">
                                <FormattedMessage id="user.last_name" defaultMessage="Last Name"/>
                            </label>
                            <input id="last_name" value={form.last_name} onChange={(e) => {
                                setForm({
                                    ...form,
                                    last_name: e.target.value
                                })
                            }} placeholder={intl.formatMessage({
                                id: 'user.last_name_placeholder',
                                defaultMessage: 'Please enter last name'
                            })} type="text" className="form-control"/>


                        </div>

                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="email">
                                <FormattedMessage id="user.email" defaultMessage="E-Mail Address"/>
                            </label>
                            <input id="email" value={form.email} onChange={(e) => {
                                setForm({
                                    ...form,
                                    email: e.target.value
                                })
                            }} placeholder={intl.formatMessage({
                                id: 'user.email_placeholder',
                                defaultMessage: 'Please enter an email'
                            })} type="email" className="form-control" name="email"/>

                        </div>

                        <div className="mb-3">
                            <div className="mb-2 w-100">
                                <label className="text-muted" htmlFor="password">
                                    <FormattedMessage id="user.password" defaultMessage="Password"/>
                                </label>
                            </div>
                            <input id="password" onChange={(e) => {
                                setForm({
                                    ...form,
                                    password: e.target.value
                                })
                            }} type="password" placeholder={intl.formatMessage({
                                id: 'user.password_placeholder',
                                defaultMessage: 'Please enter a password'
                            })} className="form-control"/>
                        </div>

                        <div className="mb-3">
                            <div className="mb-2 w-100">
                                <label className="text-muted" htmlFor="status">
                                    <FormattedMessage id="user.status" defaultMessage="Status"/>
                                </label>
                            </div>

                            <select value={form.status} onChange={(e) => {
                                setForm({
                                    ...form,
                                    status: e.target.value
                                })
                            }} className={'form-select'} name="status" id="status">
                                <option value="0">{intl.formatMessage({
                                    id: 'user.disable',
                                    defaultMessage: 'Disable'
                                })}</option>
                                <option value="1">{intl.formatMessage({
                                    id: 'user.enable',
                                    defaultMessage: 'Enable'
                                })}</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <div className="mb-2 w-100">
                                <label className="text-muted" htmlFor="root">
                                    <FormattedMessage id="user.root" defaultMessage="Root Access"/>
                                </label>
                            </div>

                            <select value={form.root} onChange={(e) => {
                                setForm({
                                    ...form,
                                    root: e.target.value
                                })
                            }} className={'form-select'} name="root" id="root">
                                <option value="0">{intl.formatMessage({
                                    id: 'user.disable',
                                    defaultMessage: 'Disable'
                                })}</option>
                                <option value="1">{intl.formatMessage({
                                    id: 'user.enable',
                                    defaultMessage: 'Enable'
                                })}</option>
                            </select>
                        </div>

                        <div className="d-flex align-items-center">

                            <Button variant="secondary" onClick={handleClose}>
                                <FormattedMessage id="navbar.edit_profile_close" defaultMessage="Close"/>
                            </Button>


                            <button type="submit" className="btn btn-primary ms-auto" disabled={loading}>
                                {
                                    loading ? (
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span> <FormattedMessage id="system.loading"
                                                                     defaultMessage="Loading..."/></span>
                                        </>
                                    ) : (
                                        <FormattedMessage id="user.edit_button" defaultMessage="Update"/>
                                    )
                                }
                            </button>
                        </div>

                    </form>
                </Modal.Body>

            </Modal>

            <div className="geex-content__section geex-content__transaction">
                <div className="geex-content__section__header">
                    <div className="geex-content__section__header__title-part" >
                        <h4 className="geex-content__section__header__title">Users</h4>
                    </div>

                    <div className="geex-content__section__header__content-part">
                        <div className="geex-content__faq__accordion__item primary-bg">
                            <button
                                id="addUserBtn"
                                className="geex-btn geex-btn--transparent text-center"
                                style={{backgroundColor: 'none', color: 'white'}}
                                onClick={addHandleShow}
                            >
                                Add User <i className="uil-plus" style={{fontSize: 'medium', color: 'white'}}> </i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="geex-content__section mt-4">
                <div className="geex-content__section__content">
                    <Table responsive className={'user-table'}>
                        <thead>
                        <tr>
                        <th>#</th>
                            <th><FormattedMessage id="user.first_name" defaultMessage="First Name"/></th>
                            <th><FormattedMessage id="user.last_name" defaultMessage="Last Name"/></th>
                            <th><FormattedMessage id="user.email" defaultMessage="E-mail"/></th>
                            <th><FormattedMessage id="user.status" defaultMessage="Status"/></th>
                            <th><FormattedMessage id="user.root" defaultMessage="Root"/></th>
                            <th><FormattedMessage id="user.action" defaultMessage="Action"/></th>
                        </tr>
                        </thead>

                        <tbody>
                        {
                            list.map((item) => (
                                <tr key={item.ID}>
                                    <td>{item.ID}</td>
                                    <td>{item.FirstName}</td>
                                    <td>{item.LastName}</td>
                                    <td>{item.Mail}</td>
                                    <td>{item.Status === '1' ? intl.formatMessage({
                                        id: 'user.enable',
                                        defaultMessage: 'Enable'
                                    }) : intl.formatMessage({
                                        id: 'user.disable',
                                        defaultMessage: 'Disable'
                                    })}</td>
                                    <td>{item.Root === '1' ? intl.formatMessage({
                                        id: 'user.enable',
                                        defaultMessage: 'Enable'
                                    }) : intl.formatMessage({
                                        id: 'user.disable',
                                        defaultMessage: 'Disable'
                                    })}</td>
                                    <td>
                                        <Button className={'btn btn-sm m-1'}
                                                onClick={() => editHandleShow(item.ID)}>Edit</Button>
                                        <Button className={'btn btn-sm btn-danger m-1'}
                                                onClick={() => deleteAction(item.ID)}>Delete</Button>
                                    </td>
                                </tr>
                            ))
                        }

                        </tbody>
                    </Table>

                    <Pages></Pages>
                </div>
            </div>

        </Layout>
    );
});

export default Users;