export let IRRIGATION_TUBE_TYPE

;(function(IRRIGATION_TUBE_TYPE) {
  IRRIGATION_TUBE_TYPE["BluLock24mm"] = "bluLock_24mm"
  IRRIGATION_TUBE_TYPE["PE_25mm"] = "pe_25mm"
  IRRIGATION_TUBE_TYPE["PE_32mm"] = "pe_32mm"
})(IRRIGATION_TUBE_TYPE || (IRRIGATION_TUBE_TYPE = {}))

export let PRESSURE_TUBE_TYPE

;(function(PRESSURE_TUBE_TYPE) {
  PRESSURE_TUBE_TYPE["PE_25mm"] = "pe_25mm"
  PRESSURE_TUBE_TYPE["PE_32mm"] = "pe_32mm"
})(PRESSURE_TUBE_TYPE || (PRESSURE_TUBE_TYPE = {}))

export let QUALITY_TYPES

;(function(QUALITY_TYPES) {
  QUALITY_TYPES["Eco"] = "eco"
  QUALITY_TYPES["EcoHT"] = "eco HT"
  QUALITY_TYPES["EcoRB"] = "eco RB"
  QUALITY_TYPES["ExpHT"] = "exp HT"
  QUALITY_TYPES["ExpRB"] = "exp RB"
  QUALITY_TYPES["Expert"] = "expert"
  QUALITY_TYPES["PremHT"] = "prem HT"
  QUALITY_TYPES["PremRB"] = "prem RB"
  QUALITY_TYPES["Premium"] = "premium"
})(QUALITY_TYPES || (QUALITY_TYPES = {}))

export let NOZZLE_TYPES

;(function(NOZZLE_TYPES) {
  NOZZLE_TYPES["Prs30"] = "PRS30"
  NOZZLE_TYPES["Prs40"] = "PRS40"
  NOZZLE_TYPES["ZsGr24"] = "ZS-GR24"
})(NOZZLE_TYPES || (NOZZLE_TYPES = {}))
