import React from "react";
import { observer } from "mobx-react";
import { attach, detach } from "../../utils/HtmlHelpers";

import {
  pixelSizeByZoom,
  svgTextCondensed,
} from "../../core/planUtils";

const Trench = ({
  zoomDelta,
  x,
  y,
  id,
  selected,
  path,
  pipes,
  center,
  lineVisible,
  countVisible,
  onSelect,
  trenchLine = false,
  trenchBorder = false,
}) => {
  const ref = React.useRef();

  //do not copy-paste me without @simdim notification
  React.useEffect(() => {
    const node = ref.current;
    const listener = (e) => {
      onSelect();
      e.stopPropagation();
    };
    if (node != null) {
      attach("dragStart", listener, node, {
        passive: false,
        capture: false,
      });
    }
    return () => {
      if (node != null) detach("dragStart", listener, node, { capture: false });
    };
  });

  const rectSize = pixelSizeByZoom(20, zoomDelta);
  return (
    <g
      key={`trench-line-${id}`}
      id={id}
      className="element trench"
      transform={`translate(${x}, ${y})`}
      fill="#fff"
      stroke="#3e4346"
      strokeWidth={pixelSizeByZoom(1, zoomDelta)}
    >
      {lineVisible && (
        <path
          d={path}
          stroke="#000"
          strokeWidth={pixelSizeByZoom(5, zoomDelta)}
          fill="none"
          className="active"
        />
      )}
      {trenchLine && (
        <path
          d={path}
          stroke="#eeeeee"
          strokeWidth={pixelSizeByZoom(8, zoomDelta)}
          fill="none"
          className="active"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      {trenchBorder && (
        <path
          d={path}
          stroke="#3e4245"
          strokeWidth={pixelSizeByZoom(10, zoomDelta)}
          fill="none"
          className="active"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      {countVisible && (
        <g
          ref={ref}
          fill={selected ? "#c5d75d" : "#fff"}
          transform={`translate(${center.x}, ${center.y})`}
          className="trench-info"
          style={{
            fontSize: pixelSizeByZoom(12, zoomDelta),
          }}
        >
          <rect
            stroke="#151517"
            x={-rectSize / 2}
            y={-rectSize / 2}
            width={rectSize}
            height={rectSize}
          ></rect>
          <text
            stroke="#151517"
            fill="#151517"
            x={
              -pixelSizeByZoom(
                (pipes ? pipes.length + "" : "0").length * 4,
                zoomDelta
              )
            }
            y={pixelSizeByZoom(3.5, zoomDelta)}
            className={`trench-info`}
            // dominantBaseline="middle"
            textAnchor="start"
            {...svgTextCondensed}
          >
            {pipes ? pipes.length : 0}
          </text>
        </g>
      )}
    </g>
  );
};

export default observer(
  ({
    element,
    lineVisible = true,
    countVisible = true,
    borderVisible = false,
    selectedTrenchId,
    setSelectedTrenchId = () => {},
    ...props
  }) => {
    return (
      <Trench
        x={0}
        y={0}
        id={element.id}
        selected={element.id === selectedTrenchId}
        lineVisible={lineVisible}
        countVisible={countVisible}
        borderVisible={borderVisible}
        path={element.path}
        pipes={element.pipes}
        center={element.centerPoint}
        onSelect={() => {
          setSelectedTrenchId(
            element.id === selectedTrenchId ? null : element.id
          );
        }}
        {...props}
      />
    );
  }
);
