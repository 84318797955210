import React from "react";
import { useIntl } from "react-intl";

import { priceFormat } from "../../core/formatter";
import { observer } from "mobx-react";
import QuantityInput from "./QuantityInput";
import QualityList from "./QualityList";
import ProductLink from "./ProductLink";

let BomItem = ({
  productLink,
  quantity,
  quality,
  qualityList,
  name,
  description,
  price,
  pricePerUnit,
  disabled = false,
  changeQuantity,
  changeQuality,
  defaultQuantity,
  articleNO,
  index,
}) => {
  const intl = useIntl();

  return (
    <div
      className={["row", +quantity === 0 ? "none" : undefined]
        .filter((e) => e)
        .join(" ")}
    >
      <ProductLink className="column position-column">{index + 1}</ProductLink>

      <ProductLink productLink={productLink} className="column title-column">
        <div className="name">{name}</div>
        <div className="description">{description}</div>
      </ProductLink>

      <div className="column column-with-dropdown">
        <QualityList
          value={quality}
          options={qualityList}
          onChange={changeQuality}
          disabled={disabled}
        />
      </div>

      <ProductLink productLink={productLink} className="column article-column">
        {articleNO}
      </ProductLink>

      <QuantityInput
        quantity={quantity}
        defaultQuantity={defaultQuantity}
        changeQuantity={changeQuantity}
        disabled={disabled}
        className="column quantity-column"
      />

      <ProductLink className="column price-per-unit-column">
        {priceFormat(intl, pricePerUnit)}
      </ProductLink>

      <ProductLink className="column price-column">
        {priceFormat(intl, price)}
      </ProductLink>
    </div>
  );
};

BomItem = observer(BomItem);
export default BomItem;
