import { convertModelToFormData } from "../core/apiUtils"
import axios from "axios";
import {config} from "../config.js";
const checkResponse = res => {
  if (!res.ok) {
    if ([401, 403].indexOf(res.status) >= 0) {
      throw new Error("login")
    } else {
      console.error("Response failed: " + res.status)
      throw new Error(`${res.status}: ${res.statusText}`)
    }
  }
}

const fetchToShop = async (url, method, params, config) => {
  let res
  if (method === "POST") {
    res = await postFormData(url, params, config)
  } else {
    res = await get(url, params, config)
  }

  checkResponse(res)

  return res
}

/**
 * Wrapper for sending one line GET requests.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} body
 * @param {*} defaultConfig
 * @throws throws error with statusText if response.ok returned false
 */
const get = async (url, params, config = {}) => {
  const separator = url.search(/\?/) ? "&" : "?"

  const response = await fetch(
      url +
      (params && Object.keys(params).length > 0
          ? separator + new window.URLSearchParams(params)
          : ""),
      {
        ...config,
        credentials: config.credentials || "same-origin"
      }
  )

  checkResponse(response)

  return response
}

/**
 * Wrapper for sending one line POST requests.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} body
 * @param {*} defaultConfig
 * @throws throws error with statusText if response.ok returned false
 */
const post = async (url, body, defaultConfig = {}) => {
  const config = {
    ...defaultConfig,
    credentials: defaultConfig.credentials || "same-origin",
    method: defaultConfig.method || "POST",
    headers: {
      ...(defaultConfig.headers || {})
    },
    body: body != null ? body : undefined
  }

  const response = await fetch(url + "", config)

  checkResponse(response)

  return response
}

/**
 * Wrapper for sending one line POST requests with JSON.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} body
 * @param {*} defaultConfig
 * @throws throws error with statusText if response.ok returned false
 */
const postJSON = (url, body, defaultConfig = {}) => {
  const config = {
    ...defaultConfig,
    headers: {
      ...(defaultConfig.headers || {}),
      "Content-Type": "application/json"
    }
  }
  return post(url, JSON.stringify(body), config)
}

/**
 * Wrapper for sending one line POST requests with FormData.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} body
 * @param {*} defaultConfig
 * @throws throws error with statusText if response.ok returned false
 */
const postFormData = async (url, body, defaultConfig = {}) => {
  const config = {
    ...defaultConfig,
    headers: {
      ...(defaultConfig.headers || {})
    }
  }

  return post(url, convertModelToFormData(body), config)
}

/**
 * Wrapper for sending one line DELETE requests.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} params
 * @param {*} config
 * @throws throws error with statusText if response.ok returned false
 */
const deleteMethod = async (url, params, config = {}) => {
  const separator = url.search(/\?/) ? "&" : "?"

  const response = await fetch(
      url +
      (params && Object.keys(params).length > 0
          ? separator + new window.URLSearchParams(params)
          : ""),
      {
        ...config,
        method: "DELETE",
        credentials: config.credentials || "same-origin"
      }
  )

  checkResponse(response)

  return response
}

const CORS_CONFIG = {
  credentials: "omit",
  mode: "cors"
}



export function apiFactory({
                             userinfoURL,
                             planByIdURL,
                             savePlanURL,
                             removePlanURL,
                             pricesURL,
                             cartGeneratorURL,
                             createPlanURL,
                             planCheckedURL,
                             planToCheckURL,
                             wishListGeneratorURL,
                             requestPlanDuplicationURL
                           }) {
  let csrf_token
  return {
    getUserInfo: async () => {
      try {
        const token = localStorage.getItem('authKey');
        const request = await axios.get(userinfoURL, {
          headers: {
            'x-access-token': token,
          }
        });
        return request.data
      } catch (e) {

        throw e
      }
    },
    createPlan: async (name, plan) => {
      try{
        const token = localStorage.getItem('authKey');
        const imageBase64 =
            plan.background && plan.background.src ? plan.background.src : null

        const imageType = imageBase64?.match(/^data:([/\w]+);/)

        const request = await axios.post(createPlanURL,{
          name,
          image: imageBase64,
          image_type: imageType != null ? imageType[1] : null,
          csrf_token
        }, {
          headers: {
            'x-access-token': token,
            'x-access-path': 'add-plan'
          }
        });

        return request.data;
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    getPlanById: async id => {
      if (id == null) {
        throw new Error("Invalid parameter value")
      }

      try {
        const token = localStorage.getItem('authKey');
        const request = await axios.get(planByIdURL+id, {
          headers: {
            'x-access-token': token,
            'x-access-path': 'get-plan'
          }
        });



        const {
          Json: dataStr,
          Image:image,
          ImageType:image_type,
          ID:ident,
          Name:name,
          Status: planStatus
        } = request.data

        if(!dataStr){
          return;
        }
        const data = JSON.parse(dataStr)
        if (planStatus != null) data.status = planStatus

        return {
          name,
          data,
          image,
          image_type,
          ident
        }


      } catch (e) {

        throw e
      }
    },
    removePlanById: async id => {
      if (id == null) {
        throw new Error("Invalid parameter value")
      }

      const res = await fetchToShop(removePlanURL, "POST", {
        csrf_token: csrf_token,
        id: id
      })
      const { status, error_msg, response } = await res.json()
      if (status === "ok" && response.status) {
        return true
      } else {
        throw new Error(error_msg || "Error by remove plan")
      }
    },
    savePlan: async (id, name, planJSON, saveStatus) => {
      if (planJSON == null) {
        throw new Error("Invalid parameter value")
      }

      try{
        const token = localStorage.getItem('authKey');
        const request = await axios.post(config.apiUrl+'/plan/update-plan',{
          ID: id,
          Name: name,
          Status: saveStatus,
          PlanningTime: planJSON.planningTime,
          Data: JSON.stringify(planJSON),
        }, {
          headers: {
            'x-access-token': token,
            'x-access-path': 'update-plan'
          }
        });
        return request.data;

      } catch (e) {
        console.error(e)
        throw e
      }
    },
    planChecked: async id => {
      const res = await fetchToShop(planCheckedURL, "POST", {
        ident: id,
        csrf_token: csrf_token
      })

      const { status, error_msg, response } = await res.json()
      if (status === "ok" && response.status) {
        return true
      } else {
        if (error_msg) {
          throw new Error(error_msg)
        }

        return false
      }
    },
    planToCheck: async id => {
      const res = await fetchToShop(planToCheckURL, "POST", {
        ident: id,
        csrf_token: csrf_token
      })
      const { status, error_msg, response } = await res.json()
      if (status === "ok" && response.status) {
        return true
      } else {
        if (error_msg) {
          throw new Error(error_msg)
        }

        return false
      }
    },
    getWishListUrl: async (id, articlesWithQuantity) => {
      const res = await fetchToShop(wishListGeneratorURL, "POST", {
        ident: id,
        articles: articlesWithQuantity,
        csrf_token: csrf_token
      })
      const { status, error_msg, response } = await res.json()

      if (status !== "ok") {
        throw new Error(error_msg ? error_msg : "An error")
      }
      return await response.redirect
    },
    getPricesByArticleNumbers: async articleNumbers => {
      if (articleNumbers != null && articleNumbers.length > 0) {
        const res = await fetchToShop(pricesURL, "POST", {
          articles: articleNumbers,
          csrf_token: csrf_token
        })
        const { status, error_msg, response } = await res.json()

        if (status !== "ok") {
          throw new Error(
              error_msg ? error_msg : "An error occurred while getting prices"
          )
        }

        return response.articles
      }

      return []
    },
    getCartUrl: async (id, articlesWithQuantity) => {
      const res = await fetchToShop(cartGeneratorURL, "POST", {
        ident: id,
        articles: articlesWithQuantity,
        csrf_token: csrf_token
      })
      const { status, error_msg, response } = await res.json()

      if (status !== "ok") {
        throw new Error(
            error_msg ? error_msg : "An error occurred while getting prices"
        )
      }
      return await response.redirect
    },
    duplicatePlan: async planId => {
      const res = await postJSON(
          requestPlanDuplicationURL,
          {
            plan_id: planId,
            csrf_token: csrf_token
          },
          CORS_CONFIG
      )

      const result = await res.json()

      if (result.error) {
        throw new Error(
            result.error || "An error occured during plan duplication"
        )
      }

      return result
    }
  }
}

const calcApiFactory = ({
                          sprinklerCalcURL,
                          pipelineCalcURL,
                          settingsFileUrl,
                          generateImageURL,
                          generatePdfURL,
                          precipitationImageURL,
                          updateSettingsURL,
                          trenchingURL,
                          savePlanURL,
                          feedbackURL,
                          requestRainBirdProductsURL,
                          requestInstallerURL,
                          saveStatsURL,
                          assessmentURL
                        }) => {
  return {
    calculateSprinklers: async (plan, recalcSprinklers) => {
      if (plan == null) {
        throw new Error("Invalid parameter value");
      }

      try {
        console.debug("Calculate sprinklers");
        const request = await postJSON(
            sprinklerCalcURL,
            { plan, recalcSprinklers },
            CORS_CONFIG
        );
        return request.json();
      } catch (err) {
        console.error("Error calculating sprinklers");
        console.error(err);
      }

      return undefined;
    },
    calculatePrecipitation: async params => {
      if (params == null) {
        throw new Error("Invalid parameter value")
      }

      try {
        console.debug("Calculate precipitation")
        const res = await postJSON(precipitationImageURL, params, CORS_CONFIG)

        const { status, error_msg, response } = await res.json()
        if (status === "ok") {
          return response
        } else {
          if (error_msg) {
            throw new Error(error_msg)
          }

          return null
        }
      } catch (err) {
        console.error("Error calculating sprinklers")
        console.error(err)
      }

      return undefined
    },
    updateSettings: async params => {
      if (params == null) {
        throw new Error("Invalid parameter value")
      }

      try {
        console.debug("Update settings")
        const res = await postJSON(updateSettingsURL, params, CORS_CONFIG)

        const { status, error_msg, response } = await res.json()
        if (status === "ok") {
          return response
        } else {
          if (error_msg) {
            throw new Error(error_msg)
          }

          return null
        }
      } catch (err) {
        console.error("Error calculating sprinklers")
        console.error(err)
      }

      return undefined
    },
    calculatePipeline: async params => {
      if (params == null) {
        throw new Error("Invalid parameter value")
      }

      try {
        console.debug("Calculate pipeline")
        const request = await postJSON(pipelineCalcURL, params, CORS_CONFIG)
        return request.json()
      } catch (err) {
        console.error("Error calculating pipeline")
        console.error(err)
      }

      return undefined
    },
    fetchSettingsFile: async () => {
      const response = await fetch(settingsFileUrl)
      if (!response.ok) throw new Error("Failed to load settings")
      return response.json()
    },
    generateImage: async (planId, plan, layouts, textsVisibility) => {
      console.debug("Generate image")
      if (plan == null) {
        throw new Error("Invalid parameter value")
      }

      const res = await postJSON(
          generateImageURL,
          {
            ident: planId,
            plan,
            layouts,
            textsVisibility
          },
          CORS_CONFIG
      )

      const { status, error_msg, response } = await res.json()
      if (status === "ok") {
        return response
      } else {
        if (error_msg) {
          throw new Error(error_msg)
        }

        return null
      }
    },
    generatePdf: async (planId, plan, locale, action) => {
      console.debug("Generate pdf")
      if (plan == null) {
        throw new Error("Invalid parameter value")
      }

      const res = await postJSON(
          generatePdfURL,
          {
            ident: planId,
            plan,
            url: window.location.href,
            locale,
            action
          },
          CORS_CONFIG
      )

      const { status, error_msg, response } = await res.json()
      if (status === "ok") {
        return response
      } else {
        if (error_msg) {
          throw new Error(error_msg)
        }

        return null
      }
    },
    trenchingWays: async (plan, ways, trenchedPaths) => {
      console.debug("trenching ways..")

      if (plan == null || ways == null) {
        throw new Error("Invalid parameter value")
      }

      try {
        const request = await postJSON(
            trenchingURL,
            { plan, ways, trenchedPaths },
            CORS_CONFIG
        )
        return request.json()
      } catch (err) {
        console.error("Error calculating pipeline")
        console.error(err)
      }
      return undefined
    },
    saveFeedback: async (ident, email, rate, category, description) => {
      const res = await postJSON(
          feedbackURL,
          {
            ident,
            email,
            rate,
            category,
            description
          },
          CORS_CONFIG
      )

      const { status, error_msg, response } = await res.json()
      if (status === "ok") {
        return response
      } else {
        if (error_msg) {
          throw new Error(error_msg)
        }

        return null
      }
    },
    requestRainBirdProducts: async (ident, email) => {
      const res = await postJSON(
          requestRainBirdProductsURL,
          {
            ident,
            email
          },
          CORS_CONFIG
      )

      const { status, error_msg, response } = await res.json()
      if (status === "ok") {
        return response
      } else {
        if (error_msg) {
          throw new Error(error_msg)
        }

        return null
      }
    },
    requestInstaller: async (ident, email, zip, city, country) => {
      const res = await postJSON(
          requestInstallerURL,
          {
            ident,
            email,
            zip,
            city,
            country
          },
          CORS_CONFIG
      )

      const { status, error_msg, response } = await res.json()
      if (status === "ok") {
        return response
      } else {
        if (error_msg) {
          throw new Error(error_msg)
        }

        return null
      }
    },
    savePlan: async (id, name, planJSON, saveStatus, imageFile) => {
      if (planJSON == null) {
        throw new Error("Invalid parameter value")
      }

      const form = new FormData()
      form.append("ident", id)
      form.append("name", name)
      form.append("status", saveStatus)
      form.append("planningTime", planJSON.planningTime)
      form.append("data", JSON.stringify(planJSON))
      if (imageFile != null) {
        form.append("image", imageFile)
      }

      try {
        const request = await post(savePlanURL, form, CORS_CONFIG)

        return request.json()
      } catch (err) {
        console.error("Error save plan")
        console.error(err)
      }
      return undefined
    },
    saveStats: async (id, statsJSON) => {
      if (statsJSON == null) {
        throw new Error("Invalid parameter value")
      }

      try {
        await postJSON(
            saveStatsURL,
            {
              ident: id,
              data: statsJSON
            },
            CORS_CONFIG
        )
      } catch (err) {
        console.error("Error save stats")
        console.error(err)
      }
      return undefined
    },
    getAssessments: async planId => {
      if (planId == null) return

      const url = `${assessmentURL}/${planId}`
      const res = await get(url, null, CORS_CONFIG)
      const response = await res.json()

      const { assessment, annotations } = response
      if (assessment == null && annotations == null) return null

      return { assessment, annotations }
    },
    saveAssessments: async (planId, assessmentPayload) => {
      const url = `${assessmentURL}/${planId}`

      const res = await postJSON(url, assessmentPayload, CORS_CONFIG)
      const result = await res.json()

      return result
    },
    removeAssessments: async planId => {
      const url = `${assessmentURL}/${planId}`

      await deleteMethod(url, null, CORS_CONFIG)

      return true
    }
  }
}

export const calcApi = calcApiFactory({
  sprinklerCalcURL: "https://ipat-prod.dvs-beregnung.de/api/sprinklers",
  pipelineCalcURL: "https://ipat-prod.dvs-beregnung.de/api/pipeline",
  settingsFileUrl: config.apiUrl+"/settings.json",
  generateImageURL: config.apiUrl+"/generatePNG",
  generatePdfURL: "https://ipat-prod.dvs-beregnung.de/api/generatePDF",
  precipitationImageURL: config.apiUrl+"/precipitation",
  updateSettingsURL: config.apiUrl+"/update_settings",
  trenchingURL: "https://ipat-prod.dvs-beregnung.de/api/trench",
  savePlanURL: config.apiUrl+"/plan/update-plan",
  feedbackURL: config.apiUrl+"/feedback",
  requestRainBirdProductsURL:  config.apiUrl+"/rain-bird-products",
  requestInstallerURL: config.apiUrl+"/installer",
  saveStatsURL: config.apiUrl+"/stats",
  assessmentURL: config.apiUrl+"/admin/assessment"
})
