import * as React from "react";
import { v4 as uuid } from "uuid";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "./../../elements/Button";
import Checkbox from "../../elements/Checkbox";
import { observer } from "mobx-react";
import { convertPlanJSON } from "../../../core/planUtils";

import OderComponent from "./OderComponent";
import Link from "../../elements/Link";

import { planVersion } from "../../../store/planFactory/planVersion";

let PlanBackgroundForm = ({ labels, createPlan }) => {
  const { formatMessage } = useIntl();

  const [terms, changeTerms] = React.useState(false);
  const [isNotify, changeNotify] = React.useState(true);

  return (
    <>
      <div className="plan-type row">
        <div className="plan-load-type">
          <div className="title">
            {labels
              ? formatMessage({ id: labels.uploadPlan.buttonTitle })
              : null}
          </div>
          <Button
            title={
              labels
                ? formatMessage({ id: labels.uploadPlan.button })
                : "upload"
            }
            type="image"
            disabled={!terms}
            onClick={async (data) => {
              createPlan({ background: data, isNotify }, "scale");
            }}
            fileOptions={{
              maxSize: labels.uploadPlan.maxSize,
              maxSizeError: formatMessage({
                id: labels.uploadPlan.maxSizeError,
              }),
              uploadError: formatMessage({
                id: labels.uploadPlan.uploadError,
              }),
            }}
            hint={formatMessage({ id: labels.uploadPlan.fileInfo })}
          />

        </div>

        <OderComponent text={formatMessage({ id: labels.uploadPlan.or })} />

        <div className="plan-load-type">
          <div className="title">
            {labels ? formatMessage({ id: labels.drawFree.buttonTitle }) : null}
          </div>
          <Button
            title={
              labels
                ? formatMessage({ id: labels.drawFree.button })
                : "draw free"
            }
            onClick={() => {
              createPlan({
                background: null,
                opacity: 1,
                scale: window.innerWidth / 100,
                isNotify,
              });
            }}
            disabled={!terms}
          />

        </div>
      </div>
      <div className="plan-terms row">
        <Checkbox
          value={isNotify}
          onChange={changeNotify}
          label={
            labels ? formatMessage({ id: labels.notification }) : "accept text"
          }
        />
      </div>
      <div className="plan-terms row">
        <Checkbox
          value={terms}
          onChange={changeTerms}
          label={
            <FormattedMessage
              id={labels.accept}
              values={{
                link: (
                  <Link
                    href={formatMessage({
                        id: "texts.steps.upload.popup.accept.link.url",
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatMessage({
                      id: "texts.steps.upload.popup.accept.link.title",
                    })}
                  </Link>
                ),
              }}
            />
          }
        />
      </div>
    </>
  );
};

PlanBackgroundForm = observer(PlanBackgroundForm);

export default PlanBackgroundForm;
