import { BaseCsvExport } from "./base-csv-export"

class UserCsvExport extends BaseCsvExport {
  constructor(intl, products) {
    super(
        intl,
        products.map(product => {
          return {
            name: product.name,
            articleNO: product.articleNO,
            quantity: product.quantity,
            minInstallationTime: product.minInstallationTime,
            maxInstallationTime: product.maxInstallationTime,
            pricePerUnit: UserCsvExport._priceRounding(product.pricePerUnit),
            price: UserCsvExport._priceRounding(product.price)
          }
        })
    )
  }

  _addHeaders() {
    const { formatMessage } = this._intl

    this._lines.push([
      this._formatField(
          formatMessage({ id: "texts.steps.bom.popup.csvFile.articleNO" })
      ),
      this._formatField(
          formatMessage({ id: "texts.steps.bom.popup.csvFile.quantity" })
      ),
      this._formatField(
          formatMessage({ id: "texts.steps.bom.popup.csvFile.name" })
      ),
      this._formatField(
          formatMessage({ id: "texts.steps.bom.popup.csvFile.pricePerUnit" })
      ),
      this._formatField(
          formatMessage({ id: "texts.steps.bom.popup.csvFile.priceTotal" })
      )
    ])
  }

  _addProducts() {
    const { formatMessage } = this._intl

    for (const product of this._products) {
      this._lines.push([
        this._formatField(product.articleNO),
        this._formatField(product.quantity),
        this._formatField(formatMessage({ id: product.name })),
        this._formatField(this._formatPrice(product.pricePerUnit)),
        this._formatField(this._formatPrice(product.price))
      ])
    }
  }

  generate() {
    this._addHeaders()
    this._addProducts()
  }
}

export { UserCsvExport }
