import React, {useEffect, useState} from 'react';
import {Layout} from "../../components/Layout";
import {observer} from "mobx-react";

import useStores from "../../hooks/useStores";
import {SettingModal} from "../../components/SettingModal";



const Settings = observer(() => {

    const products =  [{ Name:'Select Product', Code:'' },{ Name:'Product1', Code:'Name' }]
    const bomList = [
        { title: 'Pipe Cutting Tool', value: 'pipeCuttingTool',type:0 },
        { title: 'Raised Bed Tube', value: 'raisedBedTube',  type:0 },
        { title: 'Blulock Pipe 15 10m', value: 'blulockPipe15_10m',  type:0},
        { title: 'Flags', value: 'flags', type:0},
        { title: 'MP Rotator Tool', value: 'mpRotatorTool'},
        { title: 'GD Rotator Tool', value: 'gdRotatorTool' },
        { title: 'Valve Box Connector', value: 'valveBoxConnector'},
        { title: 'Pressure Sensor', value: 'pressureSensor',  type:0 },
        { title: 'Water Separation', value: "waterSeparation",  type:1 },
        { title: 'Rain Water Pump', value: 'rainWaterPump', type:1 },
        { title: 'Well Pump', value: 'wellPump', type:1  },
        { title: 'Connection Kit', value: 'connectionKit', type: 2, subtypes: [ { title: 'PE 25mm', value: 'pe_25mm' }, { title: 'PE 32mm', value: 'pe_32mm', } ] },
        { title: "Wallmount One Zone", value: "wallmountOneZone", type: 2, subtypes: [ { title: "PE 25mm", value: "pe_25mm" }, { title: "PE 32mm", value: "pe_32mm" }, { title: "BluLock 24mm", value: "bluLock_24mm" } ] },
        { title: "Straight Connector", value: "straightConnector", type: 2, subtypes: [ { title: "PE 25mm", value: "pe_25mm" }, { title: "PE 32mm", value: "pe_32mm" } ] },
        { title: "Pressure Tubing Kit With Water Tap", value: "pressureTubingKitWithWaterTap", type: 2, subtypes: [ { title: "PE 25mm", value: "pe_25mm" }, { title: "PE 32mm", value: "pe_32mm" } ] },
        { title: "Pressure Tubing Kit With Blanking", value: "pressureTubingKitWithBlanking", type: 2, subtypes: [ { title: "PE 25mm", value: "pe_25mm" }, { title: "PE 32mm", value: "pe_32mm" } ] },
        { title: "Pressure Tubing L Point", value: "pressureTubingLPoint", type: 2, subtypes: [ { title: "PE 25mm L", value: "pe_25mm" }, { title: "PE 32mm", value: "pe_32mm" } ] },
        { title: "Elbow Fitting", value: "elbowFitting", type: 2, subtypes: [ { title: "BluLock 24mm", value: "bluLock_24mm" }, { title: "PE 25mm", value: "pe_25mm" }, { title: "PE 32mm", value: "pe_32mm" } ] },
        { title: "Reduced Connector", value: "reducedConnector", type: 2, subtypes: [ { title: "BluLock 24mm", value: "bluLock_24mm" }, { title: "PE 25mm", value: "pe_25mm" }, { title: "PE 32mm", value: "pe_32mm" } ] },
        { title: "Connector 180", value: "connector180", type: 2, subtypes: [ { title: "BluLock 24mm", value: "bluLock_24mm" }, { title: "PE 25mm", value: "pe_25mm" }, { title: "PE 32mm", value: "pe_32mm" } ] },
        { title: "End Piece", value: "endPiece", type: 2, subtypes: [ { title: "BluLock 24mm", value: "bluLock_24mm" }, { title: "PE 25mm", value: "pe_25mm" }, { title: "PE 32mm", value: "pe_32mm" } ] },
        { title: "T Fitting", value: "tFitting", type: 2, subtypes: [ { title: "BluLock 24mm", value: "bluLock_24mm" }, { title: "PE 25mm", value: "pe_25mm" }, { title: "PE 32mm", value: "pe_32mm" } ] },
        { title: "Fitting Remove Tool", value: "fittingRemoveTool", type: 2, subtypes: [ { title: "BluLock 24mm", value: "bluLock_24mm" }, { title: "PE 25mm", value: "pe_25mm" }, { title: "PE 32mm", value: "pe_32mm" } ] },
        { title: "PRS40", value: "PRS40", type: 2, subtypes: [ { title: "BluLock 24mm", value: "bluLock_24mm" }, { title: "PE 25mm", value: "pe_25mm" }, { title: "PE 32mm", value: "pe_32mm" } ] },
        { title: "PRS30", value: "PRS30", type: 2, subtypes: [ { title: "BluLock 24mm", value: "bluLock_24mm" }, { title: "PE 25mm", value: "pe_25mm" }, { title: "PE 32mm", value: "pe_32mm" } ] },
        { title: "ZS-GR24", value: "ZS-GR24", type: 2, subtypes: [ { title: "BluLock 24mm", value: "bluLock_24mm" }, { title: "PE 25mm", value: "pe_25mm" }, { title: "PE 32mm", value: "pe_32mm" } ] },
        { title: "Dripline Connection Points", value: "driplineConnectionPoints", "type": 2, "subtypes": [ { "title": "BluLock 24mm", "value": "bluLock_24mm" }, { "title": "PE 25mm", "value": "pe_25mm" }, { "title": "PE 32mm", "value": "pe_32mm" } ] },
        { title:"PE Main Line",value:"peMainLine","type":3,"subtypes":[{"value":"bluLock_24mm","title":"BluLock 24mm"},{"value":"pe_25mm","title":"PE 25mm"},{"value":"pe_32mm","title":"PE 32mm"}]},
        { title:"Pressure Tubing",value:"pressureTubing","type":3,"subtypes":[{"value":"pe_25mm","title":"PE 25mm"},{"value":"pe_32mm","title":"PE 32mm"}]},
        { title:"Water Meter Cable","value":"waterMeterCable","type":4,"subtypes":[]}
    ];



    const SingleItem = ({ items, label, col = true, def = false, }) => {

        return items.map((item, index) => {

            if (item.type === 0) {

                return (
                    <div
                        className={col ? "col-md-6 mb-4" : "mb-4"}
                        style={{
                            border: '1px solid #000',
                            borderRadius: '10px',
                            padding: '25px',
                        }}
                        key={index + item.value}
                    >
                        <label htmlFor={item.value}>
                            {item.label}
                        </label>
                        {Object.keys(item.field).map((fields, index) => {
                            if (item.field[fields] === 'select') {
                                return (
                                    <select
                                        key={index + item.value}
                                        className={'form-select'}
                                        name="status"
                                        id={item.value}
                                    >
                                        {products.map((p, i) => {
                                            return (
                                                <option key={i + index + item.value} value={p.Code}>
                                                    {p.Name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                );
                            } else if (item.field[fields] === 'checked') {
                                return (
                                    <div className="form-check mt-2" key={index + item.value}>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={item.value}
                                            defaultChecked={item.default} // Varsayılan olarak işaretlenmiş öğe
                                        />
                                        <label className="form-check-label" htmlFor={item.value}>
                                            {`Check ${item.label}`}
                                        </label>
                                    </div>
                                );
                            } else if (item.field[fields] === 'number') {
                                return (
                                    <div className="mt-2" key={index + item.value}>
                                        <label htmlFor={`${fields}-${item.value}`}>
                                            {fields === 'minLength' ? 'Min Length' : null}
                                            {fields === 'maxLength' ? 'Max Length' : null}
                                            {fields === 'minValves' ? 'Min Valves' : null}
                                            {fields === 'maxValves' ? 'Max Valves' : null}
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id={`${fields}-${item.value}`}
                                            placeholder={`Enter ${fields === 'minLength' ? 'Min' : 'Max'} Length`}
                                            value={item.field[fields]}
                                        />
                                    </div>
                                );
                            }
                        })}

                        {


                            def || item?.default ? (<div className="form-check mt-2" key={index + item.value}>
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    id={item.value}
                                    defaultChecked={item.default} // Varsayılan olarak işaretlenmiş öğe
                                />
                                <label className="form-check-label" htmlFor={item.value}>
                                    {`Default ${item.label}`}
                                </label>
                            </div>) : null
                        }
                    </div>
                );

            } else if (item.type === 1) {
                return (
                    <div key={index + item.value} className="category mb-2">
                        <fieldset style={{
                            border: '1px solid #000',
                            borderRadius: '10px',
                            padding: '25px'
                        }}>
                            <legend>{label ? label + ' ' + item.label : item.label}</legend>
                            <SingleItem col={false} items={item.subtypes} label={item.label} def={item?.default}/>

                        </fieldset>
                    </div>
                );
            }
        });
    };



    return (
        <Layout title={'Settings'}>

            <div className="geex-content__section mt-4">
                <div className="geex-content__section__content">
                    <div className="row rmColumns">

                        {
                            bomList.map((item, index) => {
                                return <SettingModal {...item} key={index} />
                            })
                        }
                    </div>
                </div>
            </div>

        </Layout>
    );
});

export default Settings;