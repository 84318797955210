import React from "react";
import { observer } from "mobx-react";

import WindowPopup from "./WindowPopup";
import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";

let WaterSupplyVideoPopupWithState = () => {
  const { uiState } = useStores();

  const { formatMessage } = useIntl();

  const { plan, settingsState } = uiState;
  if (plan == null || settingsState == null) return null;
  if (plan.showWaterSupplyVideo !== true) return null;
  return null;
  return (
    <WindowPopup
      close={() => plan.setShowWaterSupplyVideo(false)}
      className="about-water-supply-popup"
    >
      <div>
        <div className="about-water-supply-popup">
          <iframe
            title="water-supply-video"
            width="772"
            height="433"
            src={formatMessage({
              id: settingsState.texts.steps.systemElements.waterSupplyDialog
                .videoLink,
            })}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </WindowPopup>
  );
};

WaterSupplyVideoPopupWithState = observer(WaterSupplyVideoPopupWithState);
export default WaterSupplyVideoPopupWithState;
