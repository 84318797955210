import * as React from "react";
import { pixelSizeByZoom } from "../../core/planUtils";
import {
  WaterSupplySVG,
  ValveBoxSVG,
  WaterTapSVG,
  FertilizerSVG,
  AirCompressorSVG,
  WaterFilterSVG,
  WaterMeterSVG,
  CombiBoxSVG,
} from "../SystemElement";
import { RzwsSVG, RaisedBedSVG } from "../sprinklers/SpecificSprinklerElement";
import Sprinkler from "../sprinklers/Sprinkler";
import { DriplinePointSVG } from "./PipePointIcons";
import { remToPx } from "../../utils/uiUtils";

const TriangleInvalidSVG = () => {
  return (
    <g>
      <path
        fill="none"
        d="M -12 9 L 0,-12 L 12,9 Z"
        stroke="red"
        strokeWidth={2}
      />
      <g transform="translate(1 -1)">
        <circle fill="white" cx="8" cy="-10" r="7.5" stroke="none" />
        <circle fill="red" cx="8" cy="-10" r="6" stroke="none" />

        <g transform="scale(0.8) translate(-9 -22)">
          <path
            fill="#f5f5f5"
            d="M18.18,11l-.61-4.28V4H20V6.76L19.45,11Zm-.57,3.47V12.09H20V14.5Z"
            stroke="none"
          />
        </g>
      </g>
    </g>
  );
};

const RectangleInvalidSVG = () => {
  return (
    <g>
      <path
        fill="none"
        d="M-10,-10H10V10H-10V-10"
        stroke="red"
        strokeWidth={2}
      />
      <g>
        <circle fill="white" cx="10" cy="-10" r="7.5" stroke="none" />
        <circle fill="red" cx="10" cy="-10" r="6" stroke="none" />

        <g transform="scale(0.8) translate(-6 -22)">
          <path
            fill="#f5f5f5"
            d="M18.18,11l-.61-4.28V4H20V6.76L19.45,11Zm-.57,3.47V12.09H20V14.5Z"
            stroke="none"
          />
        </g>
      </g>
    </g>
  );
};

const InvalidSVG = ({ r = 12 }) => {
  return (
    <g>
      <circle
        fill="none"
        cx={12 - r}
        cy={12 - r}
        r={r}
        stroke="red"
        strokeWidth={1.5}
      />
      <g transform="translate(1 -1)">
        <circle fill="#fff" cx="6" cy="-10" r={`${r - 5.5}`} stroke="none" />
        <circle fill="red" cx="5.97" cy="-10" r={`${r - 6.5}`} stroke="none" />
        <g transform="scale(0.6) translate(-9 -26)">
          <path
            fill="#f5f5f5"
            d="M18.18,11l-.61-4.28V4H20V6.76L19.45,11Zm-.57,3.47V12.09H20V14.5Z"
            stroke="none"
          />
        </g>
      </g>
    </g>
  );
};

const SmallInvalidSVG = ({ r = 9 }) => {
  return (
    <g>
      <circle
        fill="none"
        cx={-3}
        cy={-3}
        r={r - 1}
        stroke="red"
        strokeWidth={2}
      />
      <g transform="translate(1 -1)">
        <circle fill="#fff" cx="6" cy="-10" r={`${r - 2}`} stroke="none" />
        <circle fill="red" cx="5.97" cy="-10" r={`${r - 3.5}`} stroke="none" />
        <g transform="scale(0.65) translate(-9.5 -26)">
          <path
            fill="#f5f5f5"
            d="M18.18,11l-.61-4.28V4H20V6.76L19.45,11Zm-.57,3.47V12.09H20V14.5Z"
            stroke="none"
          />
        </g>
      </g>
    </g>
  );
};

export const SystemElementPoint = React.forwardRef(
  (
    {
      zoomDelta,
      active,
      useDefaultStyles,
      color,
      pointType,
      isNotConnectedToValve,
    },
    ref
  ) => {
    let SystemTypeIcon = null;
    let InvalidIcon = InvalidSVG;
    let pxSize = 12,
      cx = 12,
      cy = 12,
      r = 12;
    switch (pointType) {
      case "start-point":
      case "valve-box":
        SystemTypeIcon = ValveBoxSVG;
        break;
      case "water-supply":
        SystemTypeIcon = WaterSupplySVG;
        break;
      case "water-tap-point":
        SystemTypeIcon = WaterTapSVG;
        InvalidIcon = SmallInvalidSVG;
        pxSize = 9;
        cx = 9;
        cy = 9;
        r = 9;
        break;
      case "fertilizer":
        SystemTypeIcon = FertilizerSVG;
        break;
      case "air-compressor":
        SystemTypeIcon = AirCompressorSVG;
        break;
      case "water-filter":
        SystemTypeIcon = WaterFilterSVG;
        break;
      case "combi-box":
        SystemTypeIcon = CombiBoxSVG;
        break;
      case "water-meter":
        SystemTypeIcon = WaterMeterSVG;
        break;
      default:
        break;
    }

    return (
      <g
        ref={ref}
        transform={`translate(${-pixelSizeByZoom(
          pxSize,
          zoomDelta
        )},${-pixelSizeByZoom(pxSize, zoomDelta)}) scale(${pixelSizeByZoom(
          1,
          zoomDelta
        )})`}
        stroke="none"
        fill={color}
        strokeWidth={active ? pixelSizeByZoom(1, zoomDelta) : 0}
        className="pipe-startpoint-elem"
      >
        {!useDefaultStyles ? (
          <circle
            className="stroke-point"
            stroke={"#000"}
            strokeWidth={2}
            cx={cx}
            cy={cy}
            r={r}
          />
        ) : null}
        <SystemTypeIcon />
        {isNotConnectedToValve ? (
          <g transform={`translate(12,12) scale(${(12 * 1.08) / 12})`}>
            <InvalidIcon />
          </g>
        ) : null}
      </g>
    );
  }
);

export const NotConnectedToValvePoint = React.forwardRef(
  ({ color, useDefaultStyles, zoomDelta }, ref) => {
    return (
      <Sprinkler
        ref={ref}
        x={0}
        y={0}
        zoomDelta={zoomDelta}
        color={color}
        hasInvalid={true}
        useDefaultStyles={useDefaultStyles}
      />
    );
  }
);

export const SpecificSprinklerElementPoint = React.forwardRef(
  (
    {
      zoomDelta,
      active,
      color,
      pointType,
      isHoveredElement,
      isNotConnectedToValve,
    },
    ref
  ) => {
    let ElementIcon = null;
    let InvalidIconSVG;
    let pxSize = 12;
    switch (pointType) {
      case "rzws-point":
        ElementIcon = RzwsSVG;
        InvalidIconSVG = TriangleInvalidSVG;
        break;
      case "raised-bed-point":
        ElementIcon = RaisedBedSVG;
        InvalidIconSVG = RectangleInvalidSVG;
        break;
      default:
        break;
    }

    return (
      <g
        ref={ref}
        transform={`translate(${-pixelSizeByZoom(
          pxSize,
          zoomDelta
        )},${-pixelSizeByZoom(pxSize, zoomDelta)}) scale(${pixelSizeByZoom(
          1,
          zoomDelta
        )})`}
        stroke="none"
        fill={color}
        strokeWidth={active ? pixelSizeByZoom(1, zoomDelta) : 0}
        className={`pipe-specific-sprinkler-elem ${
          active || isHoveredElement ? "sprinkler active" : ""
        }`}
      >
        <ElementIcon />
        {isNotConnectedToValve ? (
          <g transform={`translate(12,12) scale(${(12 * 1.08) / 12})`}>
            <InvalidIconSVG />
          </g>
        ) : null}
      </g>
    );
  }
);

export const DriplinePoint = React.forwardRef(
  (
    { zoomDelta, active, useDefaultStyles, color, hidden, driplineValvesCount },
    ref
  ) => {
    return (
      <g
        ref={ref}
        transform={`translate(${-pixelSizeByZoom(
          8,
          zoomDelta
        )},${-pixelSizeByZoom(8, zoomDelta)}) scale(${pixelSizeByZoom(
          1,
          zoomDelta
        )})`}
        fill={color}
        strokeWidth={active ? pixelSizeByZoom(1, zoomDelta) : 0}
        className={`pipe-drippoint-elem ${hidden ? "element-hidden" : ""}`}
      >
        {!useDefaultStyles ? (
          <circle
            className="stroke-point"
            stroke={"#000"}
            strokeWidth={2}
            cx={8.5}
            cy={8.5}
            r={9}
          />
        ) : null}
        <g stroke="none">
          <DriplinePointSVG />
        </g>
        {driplineValvesCount > 1 && (
          <g
            className="point-info"
            style={{
              fontSize: remToPx(0.5),
              letterSpacing: 0.6,
            }}
            transform={`translate(8,0)`}
            stroke="none"
          >
            <circle cx={5} cy={0} r={5.5} fill="#ff7600" />
            <text x={3} y={3} fill="#fff">
              <tspan>{driplineValvesCount}</tspan>
            </text>
          </g>
        )}
      </g>
    );
  }
);

export const DefaultPoint = React.forwardRef(
  ({ color, hidden, useDefaultStyles, zoomDelta, pointType }, ref) => {
    return (
      <g
        ref={ref}
        fill={color}
        className={`pipe-point-elem ${hidden ? "element-hidden" : ""}`}
        stroke="none"
        strokeWidth={0}
      >
        {!useDefaultStyles && (
          <circle
            className="pipe-point-bg-elem"
            cx={0}
            cy={0}
            r={pixelSizeByZoom(
              pointType === "sprinkler-point" ? 8 : 6.5,
              zoomDelta
            )}
            stroke="none"
            strokeWidth={0}
            fill="#000"
          />
        )}

        <circle
          cx={0}
          cy={0}
          r={pixelSizeByZoom(
            pointType === "sprinkler-point" ? 7 : 5.5,
            zoomDelta
          )}
          stroke="none"
          strokeWidth={0}
        />
        {pointType === "sprinkler-point" && (
          <circle
            cx={0}
            cy={0}
            r={pixelSizeByZoom(1, zoomDelta)}
            fill={"#fff"}
            stroke="none"
            strokeWidth={0}
          />
        )}
      </g>
    );
  }
);
