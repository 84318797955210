import { action, extendObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";

import { numberFormat } from "../../core/formatter";

/**
 * Represents a raised bed object
 * @param {*} param0 - raised bed json
 * @param {*} settingsState - state that contain a labels for UI
 * @param {*} param2 - additional UI params
 * @returns raised bed object
 */
const raisedBedFactory = (
  { id, x, y, disabled = false, waterdrop } = {},
  settingsState
) => {
  const state = observable({
    x,
    y,
    waterdrop,
  });

  //inject variables
  const raisedBed = observable({
    get type() {
      return "raised-bed";
    },
    id: id || "raised-bed-" + uuid(),
    disabled,
    get waterdropInBar() {
      return state.waterdrop != null
        ? numberFormat(state.waterdrop / 100000, 1) * 1
        : null;
    },
    get waterpressureInBar() {
      if (this.waterdropInBar == null) return null;

      if (this.waterdropInBar < 0) return null;

      return Math.ceil(Math.max(3.5 - this.waterdropInBar, 0) * 10) / 10;
    },
    get x() {
      return state.x;
    },
    set x(x) {
      state.x = x;
    },
    get y() {
      return state.y;
    },
    set y(y) {
      state.y = y;
    },
    get name() {
      return settingsState.elements.raisedBed.name;
    },
  });

  // getters
  extendObservable(raisedBed, {
    get title() {
      return raisedBed.name;
    },
    get hasWaterpressureWarn() {
      return (
        raisedBed.waterpressureInBar != null &&
        raisedBed.waterpressureInBar * 10 < 28
      );
    },
    get deleteConfirmText() {
      return settingsState.texts.tools.raisedBed.delete;
    },
  });

  // actions
  extendObservable(raisedBed, {
    drag: action((x, y) => {
      state.x = x;
      state.y = y;
    }),
    onDisable: action(() => {
      raisedBed.disabled = !raisedBed.disabled;
    }),
    changeWaterDrop: action((waterdrop) => {
      state.waterdrop = waterdrop;
    }),
    //calculate by water consumption and angle
    get waterflow() {
      const result = settingsState.elements.raisedBed.waterConsumption;
      return result ?? 0;
    },
    get extremePoints() {
      const { x, y } = raisedBed;

      return [{ x, y }];
    },
    get toJSON() {
      const { id, name, title, type, x, y, disabled, waterflow, waterdrop } =
        this;

      return {
        id,
        name,
        title,
        type,
        x,
        y,
        disabled,
        waterflow,
        waterdrop,
      };
    },
  });

  return raisedBed;
};

export default raisedBedFactory;
