import React from "react";

import {
  pixelSizeByZoom,
  sizeInPixelByMeters,
} from "../../core/planUtils";

//import { ReactComponent as InvalidSVG } from "./../../assets/stop-x24.svg";
//import { ReactComponent as NotCrossSVG } from "./../../assets/NOT_CROSS.svg";
export const getSprinklerRadius = (scale) =>
  sizeInPixelByMeters(0.5 / 2, scale);

const InvalidSVG = () => {
  return (
    <g>
      <g transform="translate(-13 -20)">
        <path
          fill="red"
          stroke="none"
          d="M25.71,17.38A13,13,0,0,0,13,7a12.71,12.71,0,0,0-3.68.54,13,13,0,1,0,16.42,9.86ZM13,30a10,10,0,1,1,10-10.8c0,.26,0,.53,0,.8A10,10,0,0,1,13,30Z"
        />
        <g transform="translate(1 -1)">
          <circle fill="red" cx="18.97" cy="10" r="8.5" stroke="none" />
          <path
            stroke="none"
            fill="#fff"
            d="M25.71,17.38A10,10,0,0,1,9,10.8c0-.26,0-.53,0-.8a9.87,9.87,0,0,1,.32-2.48A12.71,12.71,0,0,1,13,7,13,13,0,0,1,25.71,17.38Z"
          />
          <g>
            <circle fill="red" cx="18.97" cy="10" r="8.5" stroke="none" />
            <path
              fill="#fff"
              d="M19,20A10,10,0,1,1,29,10,10,10,0,0,1,19,20ZM19,1.5a8.5,8.5,0,1,0,8.5,8.5A8.51,8.51,0,0,0,19,1.5Z"
              stroke="none"
            />
          </g>
          <g>
            <path
              fill="#f5f5f5"
              d="M18.18,11l-.61-4.28V4H20V6.76L19.45,11Zm-.57,3.47V12.09H20V14.5Z"
              stroke="none"
            />
          </g>
        </g>
      </g>
    </g>
  );
};

const NotCrossSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M922.621 265.083l.014-.015c-.016-.118-.049-.23-.069-.347-.042-.244-.09-.485-.146-.724-.048-.209-.1-.415-.159-.62s-.127-.4-.2-.594c-.057-.163-.1-.332-.162-.491l-.013.013a12.075 12.075 0 0 0-.687-1.437l.012-.012c-.122-.216-.264-.418-.4-.625-.052-.081-.1-.165-.156-.244-.148-.215-.307-.421-.468-.626-.092-.118-.183-.237-.28-.351-.055-.066-.1-.137-.159-.2l-.012.012a12.1 12.1 0 0 0-1.062-1.062l.012-.012c-.064-.056-.136-.1-.2-.159-.114-.1-.233-.188-.351-.28-.2-.161-.41-.32-.626-.468-.08-.055-.163-.1-.244-.157-.207-.134-.409-.276-.625-.4l-.012.012a12.073 12.073 0 0 0-1.437-.687l.013-.013c-.159-.063-.328-.1-.491-.162-.2-.069-.393-.137-.594-.2s-.412-.111-.62-.159c-.239-.056-.48-.1-.724-.146-.117-.02-.229-.053-.347-.069l-.015.014a11.916 11.916 0 0 0-13.538 13.538l-.014.015c.016.118.049.23.069.347.042.244.09.485.146.724.048.208.1.415.159.619s.127.4.2.6c.056.162.1.331.161.49l.013-.013a12.075 12.075 0 0 0 .687 1.437l-.012.012c.122.216.264.418.4.625.052.081.1.164.156.244.148.215.306.421.467.626.093.118.184.237.281.352.055.065.1.136.159.2l.012-.012a12.1 12.1 0 0 0 1.062 1.062l-.012.012c.064.056.135.1.2.159.115.1.234.188.352.28.2.162.411.321.626.469.079.054.163.1.243.155.207.135.41.277.626.4l.012-.012a12.073 12.073 0 0 0 1.437.687l-.013.013c.159.063.328.1.49.161.2.069.394.138.6.2s.41.111.619.159c.239.056.481.1.725.146.117.02.229.053.346.069l.015-.014a11.916 11.916 0 0 0 13.538-13.538zm-22.371 1.667a10.512 10.512 0 0 1 10.5-10.5c.095 0 .187.012.282.014l-10.768 10.768c-.002-.094-.014-.187-.014-.282zm.53 3.275l13.245-13.245a10.545 10.545 0 0 1 1.5.628l-14.113 14.113a10.542 10.542 0 0 1-.632-1.496zm2.043 3.59l14.792-14.792a10.643 10.643 0 0 1 1.062 1.062l-14.792 14.792a10.643 10.643 0 0 1-1.062-1.062zm3.156 2.477l14.113-14.113a10.542 10.542 0 0 1 .628 1.5l-13.245 13.241a10.545 10.545 0 0 1-1.496-.628zm4.771 1.158c-.095 0-.188-.012-.282-.014l10.768-10.768c0 .094.014.187.014.282a10.512 10.512 0 0 1-10.5 10.5z"
      transform="translate(-898.75 -254.75)"
      style={{ fill: "#ff7600", opacity: ".75" }}
    />
  </svg>
);

const Sprinkler = React.forwardRef(
  (
    {
      x = 0,
      y = 0,
      color,
      nozzleType,
      hasInvalid,
      zoomDelta,
      inNonCrossedArea,
      useDefaultStyles = true,
      textsVisibility = {},
      scale,
      ...props
    },
    ref
  ) => {
    const radius = 8;
    return (
      <g
        {...props}
        transform={`translate(${x}, ${y}) scale(${pixelSizeByZoom(
          1,
          zoomDelta
        )})`}
      >
        <g>
          <circle
            ref={ref}
            r={radius}
            cx="0"
            cy="0"
            fill={nozzleType === "PRS30" ? color : "#fff"}
            stroke="#3e4346"
            strokeWidth={radius * 0.08}
          />
          <circle fill={color} r={radius * 0.85} cx="0" cy="0" stroke="none" />
          <circle r={radius * 0.17} cx="0" cy="0" fill="#fff" stroke="none" />
          {hasInvalid ? (
            <g transform={`scale(${(radius * 1.08) / 12})`}>
              <InvalidSVG />
            </g>
          ) : (
            inNonCrossedArea && (
              <g
                transform={`translate(-${radius * 1.08},-${
                  radius * 1.08
                }) scale(${(radius * 1.08) / 12})`}
              >
                <NotCrossSVG />
              </g>
            )
          )}
        </g>
      </g>
    );
  }
);

export default Sprinkler;
